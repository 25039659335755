<template>
	<div class="modal">
		<div class="modal-container">
			<div class="rm-form">
				<div class="modal-content tile">
					<div class="modal-header">
						<div class="left">
							<div class="modal-title">
								<h3>Map</h3>
							</div>
						</div>
						<div class="right">
							<div class="btn-icon muted" @click="close">
								<div class="text">Close</div>
								<fa icon="times" @click="close" />
							</div>
						</div>
					</div>
					<div class="row">
						<GoogleMap
							api-key="AIzaSyBpcUn2lEmU3ltidYU5KaG0R9dKDZsw9-o"
							style="width: 100%; height: 80vh"
							:center="center"
							:zoom="15"
						>
							<Marker :options="{ position: center }" />
						</GoogleMap>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";
import { GoogleMap, Marker } from "vue3-google-map";

export default defineComponent({
	name: "MapView",
	components: { GoogleMap, Marker },
	setup() {
		const router = useRouter();
		const route = useRoute();
		const center = { lat: route.query.lat, lng: route.query.lng };

		const close = () => {
			router.go(-1);
		};

		return { center, close };
	},
});
</script>

<style lang="scss" scoped>
.modal-container {
	margin: 0 auto;
	width: 80vw;
	height: 80vh;
}
</style>
