<template>
	<Alert :message="saveError" v-if="saveError" />
	<div class="spacer-20"></div>

	<form class="t-form" @submit.prevent="submit">
		<div class="t-form-question">
			<div class="icon-container">
				<fa class="question-icon" icon="book-reader" />
			</div>
		</div>

		<div class="text-editor">
			<QuillEditor
				ref="textEditor"
				theme="snow"
				:toolbar="toolbarOptions"
				:modules="[imageCompress, blotFormatter]"
				placeholder="Instructions text..."
				style="
					font-family: 'Poppins';
					font-size: 1em;
					max-height: 310px;
					overflow: auto;
				"
				contentType="html"
				v-model:content="textEditorContent"
			/>
		</div>

		<div class="lower-settings">
			<div
				class="settings-btn"
				v-bind:class="{ selected: actionsAllowed }"
				@click="handleIsActionAllowed"
			>
				Allow Actions
				<fa
					class="nav-icon"
					v-if="actionsAllowed"
					icon="check-circle"
				/>
			</div>
		</div>

		<div class="save-item">
			<vue-select
				v-if="showDropdown == true"
				v-model="pagename"
				:options="options"
				label-by="name"
				close-on-select
				@selected="getSectionsForPage"
				placeholder="Select page"
			></vue-select>
			<vue-select
				v-if="showDropdown == true"
				v-model="sectionName"
				:options="sectionOptions"
				label-by="name"
				close-on-select
				@selected="selectedOption"
				placeholder="Select section"
			></vue-select>
			<div class="t-btn">
				<button
					class="btn btn-primary"
					v-on:click.prevent="handleSubmit"
				>
					Save
				</button>
			</div>
		</div>
	</form>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { onMounted, onUnmounted, computed } from "@vue/runtime-core";
import { ref } from "vue";
import Alert from "../../Alert.vue";
import Quill from "quill";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import ImageCompress from "quill-image-compress";
Quill.register("modules/imageCompress", ImageCompress);

import BlotFormatter, {
	DeleteAction,
	ImageSpec,
	ResizeAction,
} from "quill-blot-formatter";

export default {
	components: { Alert, QuillEditor },
	props: ["pages", "buildertype", "selectedQuestion"],
	emits: ["textQuetion"],
	setup(props, context) {
		const textEditor = ref(null);
		const textEditorContent = ref("");
		const showDropdown = ref(true);
		const templateInstructionText = ref("");
		const preview = ref();
		const buildertype = ref("");
		buildertype.value = props.buildertype;
		preview.value = props.pages;
		const IsRequired = ref(false);
		const sectionName = ref();
		const pagename = ref({});
		const saveError = ref();

		const options = ref([]);
		const sectionOptions = ref([]);

		const actionsAllowed = ref(false);

		onMounted((async) => {
			getPagesOption(preview);
			if (props.selectedQuestion != undefined) {
				showDropdown.value = false;
				prepopulateData();
			}
		});

		const prepopulateData = () => {
			var filteredPage = options.value.filter((item) => {
				return item.id == props.selectedQuestion.pageId;
			});

			pagename.value = filteredPage[0];
			getSections();
			var filteredSection = sectionOptions.value.filter((item) => {
				return (item.id = props.selectedQuestion.sectionId);
			});
			sectionName.value = filteredSection[0];

			var page = preview.value.filter((item) => {
				return item.page.id == props.selectedQuestion.pageId;
			});
			var pagedata = page[0];

			var section = pagedata.page.sections.filter((item) => {
				return item.section.id == props.selectedQuestion.sectionId;
			});
			var sectionData = section[0];
			var question = sectionData.section.questions.filter((item) => {
				return item.questId == props.selectedQuestion.questionId;
			});

			var quesData = question[0];
			console.log(quesData);
			textEditorContent.value = quesData.question;
			// IsRequired.value = quesData.isRequired;
			actionsAllowed.value = quesData.actionsAllowed;
		};

		const handleIsRequiredSubmit = () => {
			IsRequired.value = !IsRequired.value;
		};

		const handleIsActionAllowed = () => {
			actionsAllowed.value = !actionsAllowed.value;
		};

		const handleSubmit = async () => {
			saveError.value = null;
			if (!pagename.value || !sectionName.value) {
				saveError.value = "Select a page & section";
				return;
			}

			var questionType = "Instruction";

			var id = uuidv4();
			var isNew = true;
			if (props.selectedQuestion != undefined) {
				id = props.selectedQuestion.questionId;
				isNew = false;
			}

			var questionobj = {
				pageid: pagename.value.id,
				sectionId: sectionName.value.id,
				isNew: isNew,
				question: {
					questId: id,
					type: questionType,
					question: textEditorContent.value,
					// isRequired: false,
					actionsAllowed: actionsAllowed.value,
					// isTagRequired: false,
					// isMultipleChoice: false,
					// isImageRequired: false,
					// isLocationRequired: false,
					// isSignatureRequired: false,
				},
			};

			context.emit("textQuetion", questionobj);
		};

		const getSectionsForPage = computed(() => {
			if (pagename.value) {
				getSections();
			}
		});

		const getSections = () => {
			sectionOptions.value = [];
			preview.value.map((item) => {
				if (item.page.id == pagename.value.id) {
					if (item.page.sections != undefined) {
						item.page.sections.map((section) => {
							sectionOptions.value.push({
								id: section.section.id,
								name: section.section.sectionTitle,
							});
						});
					}
				}
			});
		};

		const selectedOption = computed(() => {
			if (sectionName.value) {
				// console.log(sectionName.value);
			}
		});

		const getPagesOption = (previewData) => {
			previewData.value.map((item) => {
				options.value.push({
					id: item.page.id,
					name: item.page.pageTitle,
				});
			});
		};

		class CustomImageSpec extends ImageSpec {
			getActions() {
				return [DeleteAction, ResizeAction];
			}
		}

		const blotFormatter = {
			name: "blotFormatter",
			module: BlotFormatter,
			options: {
				specs: [CustomImageSpec],
			},
		};

		const imageCompress = {
			name: "imageCompress",
			module: ImageCompress,
			options: {
				quality: 0.5,
				maxWidth: 1080,
				maxHeight: 1080,
				imageType: "image/png",
				debug: false,
				suppressErrorLogging: false,
				insertIntoEditor: undefined,
			},
		};

		var toolbarOptions = [
			[{ header: 1 }, { header: 2 }],
			["bold", "italic", "underline", "link"], // toggled buttons strike removed
			//   ["blockquote", "code-block"],

			// custom button values
			[{ list: "ordered" }, { list: "bullet" }],
			//   [{ script: "sub" }, { script: "super" }], // superscript/subscript
			//   [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
			//   [{ direction: "rtl" }], // text direction

			//   [{ size: ["small", false, "large", "huge"] }], // custom dropdown
			//   [{ header: [1, 2, 3, 4, 5, 6, false] }],

			[{ color: [] }, { background: [] }], // dropdown with defaults from theme
			// [{ font: [] }],
			[{ align: [] }],

			// ["clean"], // remove formatting button
			// ["link", "image", "video"],
			["image"],
		];

		return {
			handleSubmit,
			IsRequired,
			sectionName,
			options,
			pagename,
			getSectionsForPage,
			sectionOptions,
			handleIsRequiredSubmit,
			selectedOption,
			templateInstructionText,
			showDropdown,
			textEditor,
			textEditorContent,
			toolbarOptions,
			saveError,
			imageCompress,
			blotFormatter,
			handleIsActionAllowed,
			actionsAllowed,
		};
	},
};
</script>

<style lang="scss" scoped>
.icon-container {
	width: 45px;
}
.text-editor {
	background-color: $rm-light-grey;
	// width: 90%;
	width: 80%;
	// margin-left: 4em;
	margin-left: 4em;
	margin-top: -44px;

	@include respond(medium) {
		width: 85%;
	}
	@include respond(large) {
		width: 90%;
	}
}
.save-item {
	float: right;
}

.lower-settings {
	display: flex;
	justify-content: flex-start;
	margin-top: 2em;
	margin-left: 0.5em;
}
.ql-editor {
	p {
		margin: 0 0 1em 0 !important;
	}
}

.selected {
	color: $rm-success;
}
</style>
