import SvcUtils from "@/misc/utils.js";
import Urls from "@/misc/urls.js";

export default function generatePdf(checklistId) {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const queryParams = {
    id: checklistId,
    tz: tz,
  };

  return SvcUtils.basicGet(Urls.GENERATE_PDF, queryParams, {
    responseType: "blob",
  });
}
