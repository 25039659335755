<template>
	<Alert :message="saveError" v-if="saveError" />
	<div class="spacer-20"></div>
	<form class="t-form multi-answer">
		<div class="t-form-question">
			<div class="icon-container">
				<fa class="question-icon" icon="tasks" />
			</div>
			<div class="t-input">
				<textarea
					rows="2"
					class="t-question"
					placeholder="Question text..."
					v-model="templateQuestion"
				/>
			</div>
		</div>

		<div class="t-settings">
			<div
				class="settings-btn"
				v-bind:class="{ selected: IsRequired }"
				@click="handleRequiredSubmit"
			>
				Required field
				<fa class="nav-icon" v-if="IsRequired" icon="check-circle" />
			</div>
			<div
				class="settings-btn"
				v-bind:class="{ selected: IsMultipleChoice }"
				@click="handleMultipleSubmit"
			>
				Select Multiple
				<fa
					class="nav-icon"
					v-if="IsMultipleChoice"
					icon="check-circle"
				/>
			</div>

			<div
				class="settings-btn"
				v-bind:class="{ selected: isTagRequired }"
				@click="handleIsTagRequiredSubmit"
			>
				Tag Response
				<fa class="nav-icon" v-if="isTagRequired" icon="check-circle" />
			</div>
		</div>

		<div class="option-scroll">
			<div v-for="item in choices" :key="item.id" class>
				<div class="option-container">
					<input
						type="text"
						class="t-option"
						placeholder="Option text..."
						v-model="item.text"
					/>
					<div class="option-colour-picker">
						<div class="radio-input">
							<div class="row">
								<div class="select-color-grid">
									<label class="radio-container">
										<input
											type="radio"
											value="green"
											v-model="item.color"
										/>
										<span
											class="checkmark"
											id="green"
										></span>
									</label>
									<label class="radio-container">
										<input
											type="radio"
											value="darkorange"
											v-model="item.color"
										/>
										<span
											class="checkmark"
											id="darkorange"
										></span>
									</label>
									<label class="radio-container">
										<input
											type="radio"
											value="red"
											v-model="item.color"
										/>
										<span class="checkmark" id="red"></span>
									</label>
									<label class="radio-container">
										<input
											type="radio"
											value="darkgrey"
											v-model="item.color"
										/>
										<span
											class="checkmark"
											id="darkgrey"
										></span>
									</label>

									<label class="radio-container">
										<input
											type="radio"
											value="cornflowerblue"
											v-model="item.color"
										/>
										<span
											class="checkmark"
											id="cornflowerblue"
										></span>
									</label>
									<label class="radio-container">
										<input
											type="radio"
											value="white"
											v-model="item.color"
										/>
										<span
											class="checkmark"
											id="white"
										></span>
									</label>
								</div>
							</div>
						</div>
					</div>

					<label @click="handleDelete(item.id)">
						<fa class="nav-icon delete-option" icon="trash" />
					</label>
				</div>
			</div>
		</div>

		<div class="form-options">
			<div class="btn-icon muted" @click="handleAdd">
				<div class="text">Add another</div>
				<fa icon="plus-circle" />
			</div>
		</div>

		<div class="save-item">
			<vue-select
				v-if="showDropdown"
				v-model="pagename"
				:options="options"
				label-by="name"
				close-on-select
				@selected="getSectionsForPage"
				placeholder="Select page"
			></vue-select>
			<vue-select
				v-if="showDropdown"
				v-model="sectionName"
				:options="sectionOptions"
				label-by="name"
				close-on-select
				@selected="selectedOption"
				placeholder="Select section"
			></vue-select>

			<div class="t-btn">
				<button class="btn btn-primary" @click.prevent="handleSubmit">
					Save
				</button>
			</div>
		</div>
	</form>
</template>

<script>
import { ref } from "vue";
import { v4 as uuidv4 } from "uuid";
import { onMounted, onUnmounted, computed } from "@vue/runtime-core";
import Alert from "../../Alert.vue";

export default {
	components: { Alert },
	props: ["pages", "selectedQuestion"],
	emits: ["multiChoice"],
	setup(props, context) {
		const preview = ref();
		preview.value = props.pages;
		const sectionName = ref();
		const pagename = ref({});
		const templateQuestion = ref("");
		const options = ref([]);
		const sectionOptions = ref([]);
		const showDropdown = ref(true);
		const color = ref("green");
		const saveError = ref();
		const isTagRequired = ref(false);
		const choices = ref([{ id: uuidv4(), text: "", color: color.value }]);

		const IsRequired = ref(false);
		const IsMultipleChoice = ref(false);

		const handleRequiredSubmit = () => {
			IsRequired.value = !IsRequired.value;
		};

		const handleIsTagRequiredSubmit = () => {
			isTagRequired.value = !isTagRequired.value;
		};

		const handleMultipleSubmit = () => {
			IsMultipleChoice.value = !IsMultipleChoice.value;
		};

		const handleDelete = (id) => {
			var index = choices.value.findIndex((item) => item.id == id);

			if (index != -1) {
				choices.value.splice(index, 1);
			}
		};

		const handleAdd = () => {
			choices.value.push({ id: uuidv4(), text: "", color: color.value });
		};

		onMounted((async) => {
			getPagesOption(preview);
			if (props.selectedQuestion != undefined) {
				showDropdown.value = false;
				prepopulateData();
			}
		});

		const handleSubmit = async () => {
			saveError.value = null;

			// Form validation
			if (templateQuestion.value == "") {
				saveError.value = "Question text is required";
				return;
			}
			if (choices.value.length == 1 && choices.value[0].text == "") {
				saveError.value = "One response option is required";

				return;
			}
			if (!pagename.value || !sectionName.value) {
				saveError.value = "Select a page & section";
				return;
			}

			// Handle new/selected question
			var id = uuidv4();
			var isNew = true;
			if (props.selectedQuestion != undefined) {
				id = props.selectedQuestion.questionId;
				isNew = false;
			}

			var questionobj = {
				isNew: isNew,
				pageid: pagename.value.id,
				sectionId: sectionName.value.id,
				question: {
					questId: id,
					type: "MultiChoice",
					question: templateQuestion.value,
					isRequired: IsRequired.value,
					isTagRequired: isTagRequired.value,
					choices: choices.value,
					isMultipleChoice: IsMultipleChoice.value,
				},
			};

			// if (props.selectedQuestion != undefined) {
			// 	questionobj.prevSectionId = props.selectedQuestion.sectionId;
			// }

			console.log("questionobj", questionobj);
			context.emit("multiChoice", questionobj);

			// if (
			// 	props.selectedQuestion == undefined ||
			// 	(props.selectedQuestion != undefined &&
			// 		props.selectedQuestion.isUpdate == false)
			// ) {

			// } else {
			// 	context.emit("multiChoice", questionobj);
			// }
		};

		const getSectionsForPage = computed(() => {
			if (pagename.value) {
				sectionOptions.value = [];
				preview.value.map((item) => {
					if (item.page.id == pagename.value.id) {
						if (item.page.sections != undefined) {
							item.page.sections.map((section) => {
								sectionOptions.value.push({
									id: section.section.id,
									name: section.section.sectionTitle,
								});
							});
						}
					}
				});
			}
		});

		const selectedOption = computed(() => {
			if (sectionName.value) {
			}
		});

		const getPagesOption = (previewData) => {
			previewData.value.map((item) => {
				options.value.push({
					id: item.page.id,
					name: item.page.pageTitle,
				});
			});
		};

		const prepopulateData = () => {
			var filteredPage = options.value.filter((item) => {
				return item.id == props.selectedQuestion.pageId;
			});

			pagename.value = filteredPage[0];
			getSections();
			var filteredSection = sectionOptions.value.filter((item) => {
				return (item.id = props.selectedQuestion.sectionId);
			});
			sectionName.value = filteredSection[0];

			var page = preview.value.filter((item) => {
				return item.page.id == props.selectedQuestion.pageId;
			});
			var pagedata = page[0];

			var section = pagedata.page.sections.filter((item) => {
				return item.section.id == props.selectedQuestion.sectionId;
			});
			var sectionData = section[0];
			var question = sectionData.section.questions.filter((item) => {
				return item.questId == props.selectedQuestion.questionId;
			});

			var quesData = question[0];

			templateQuestion.value = quesData.question;
			IsRequired.value = quesData.isRequired;
			isTagRequired.value =
				quesData.isTagRequired == undefined
					? false
					: quesData.isTagRequired;
			IsMultipleChoice.value = quesData.isMultipleChoice;
			choices.value = quesData.choices;
		};

		const getSections = () => {
			sectionOptions.value = [];
			preview.value.map((item) => {
				if (item.page.id == pagename.value.id) {
					if (item.page.sections != undefined) {
						item.page.sections.map((section) => {
							sectionOptions.value.push({
								id: section.section.id,
								name: section.section.sectionTitle,
							});
						});
					}
				}
			});
		};

		return {
			IsRequired,
			isTagRequired,
			IsMultipleChoice,
			handleRequiredSubmit,
			handleIsTagRequiredSubmit,
			handleMultipleSubmit,
			choices,
			handleDelete,
			handleAdd,
			sectionName,
			options,
			pagename,
			getPagesOption,
			getSectionsForPage,
			sectionOptions,
			handleSubmit,
			selectedOption,
			templateQuestion,
			color,
			showDropdown,
			prepopulateData,
			saveError,
		};
	},
};
</script>

<style lang="scss">
// .multi-answer {
//     ::-webkit-scrollbar {
//         -webkit-appearance: none;
//         width: 7px;
//     }

//     ::-webkit-scrollbar-thumb {
//         border-radius: 4px;
//         background-color: rgba(0, 0, 0, 0.5);
//         box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
//     }
// }

.option-scroll {
	margin-bottom: 0.5em;
	.option-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		margin-right: 0.5em;
		.option-colour-picker {
			position: absolute;
			top: 5px;
			right: 10%;
		}
	}
	.t-option {
		border: none;
		background-color: $rm-light-grey;
		border-radius: $border-radius;
		padding: 11px 48% 11px 15px;
		width: 93%;
		margin: 0.5em 0;
		font-size: 1em;
		font-family: "Poppins";
		&::placeholder {
			font-size: 1em !important;
		}
		&:focus {
			outline: none;
		}
	}
	.delete-option {
		color: $rm-grey;
		font-size: 1.2em;
		&:hover {
			cursor: pointer;
			color: $rm-pink;
		}
	}
}

.form-options {
	display: inline-flex;
	justify-content: flex-end;
	width: 90%;
}
</style>
