<template>
    <div class="modal">
        <div class="modal-container">
            <div class="modal-content tile">
                <form class="rm-form">
                    <div class="modal-header">
                        <div class="left">
                            <div class="modal-title">
                                <p>Copy section to another page:</p>
                            </div>
                        </div>
                        <div class="right">
                            <div class="btn-icon muted" @click="close">
                                <div class="text">Cancel</div>
                                <fa icon="times" />
                            </div>
                        </div>
                    </div>

                    <div class="modal-body">
                        <div class="form-input">
                            <vue-select
                                v-model="pageSelected"
                                :options="options"
                                label-by="name"
                                placeholder="Select Page"
                                close-on-select
                            />
                        </div>

                        <div class="form-submit">
                            <button
                                class="btn btn-primary"
                                :disabled="!pageSelected"
                                @click="selectPage"
                            >Copy to page</button>
                            <!-- <div class="btn-icon muted" >
                                <div class="text"></div>
                            </div>-->
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { onBeforeMount } from "@vue/runtime-core";
// import VueSelect from "vue-next-select";

import { ref } from "vue";
export default {
    props: ["pages", "pageToCopy", "sectionToCopy"],

    setup(props, context) {
        const pageId = ref("");
        const sectionId = ref("");
        const pages = ref();
        pages.value = props.pages;
        pageId.value = props.pageToCopy;
        sectionId.value = props.sectionToCopy;
        const pageSelected = ref(null);

        const options = pages.value;

        onBeforeMount(async () => {});

        const close = () => {
            context.emit("close");
        };

        const selectPage = () => {
            var data = {
                fromPageId: pageId.value,
                fromSectionId: sectionId.value,
                toPageId: pageSelected.value.id,
            };
            context.emit("copySectionCallback", data);
        };

        return {
            close,
            pageSelected,
            options,
            selectPage,
        };
    },
};
</script>

<style lang="scss" scoped>
.modal-content {
    width: 40%;
    margin: 0 auto;
}
</style>