<template>
    <div class="select-container">
        <Multiselect
            v-model="value"
            mode="multiple"
            label="name"
            trackBy="name"
            placeholder="Search & select locations..."
            :options="options"
            :searchable="true"
            @select="handleSubmit"
            @deselect="unselect"
        >
            <template v-slot:multiplelabel="{ values }">
                <div class="multiselect-multiple-label">{{ values.length }} locations added</div>
            </template>
        </Multiselect>
    </div>
</template>

<script>
import { computed, onMounted } from "@vue/runtime-core";
import locationCollection from "../../../composables/locationCollection";
import { ref } from "@vue/reactivity";
import Multiselect from "@vueform/multiselect";
import { useRouter } from "vue-router";
import templateService from "../../../composables/templateService";

export default {
    name: "LinkLocation",
    props: ["isTemplateBuilder"],
    components: {
        Multiselect,
    },
    emits: ["updateLocation"],
    setup(props, context) {
        const { getLocation, locations, error } = locationCollection();
        const router = useRouter();
        const value = ref([]);

        getLocation();

        const options = computed(() => {
            if (locations.value.value != null) {
                locations.value.value.map((location) => {
                    var name = location.locationName;
                    location.value = location.id;
                    location.name = name;
                });
            }

            return locations.value.value;
        });

        const handleSubmit = () => {
            console.log(props.isTemplateBuilder, "istemp");

            if (props.isTemplateBuilder) {
                setData();
            } else {
                context.emit("updateLocation", value);
            }
        };

        const setData = () => {
            console.log(value);

            var loactionsSelected = templateService.getLocations();

            if (loactionsSelected == null) {
                loactionsSelected = [];
            }

            value.value.forEach((id) => {
                if (
                    templateService.getLocations() != undefined &&
                    templateService.getLocations().length > 0
                ) {
                    if (
                        templateService
                            .getLocations()
                            .findIndex((x) => x == id) == -1
                    ) {
                        loactionsSelected.push(id);
                    }
                } else {
                    loactionsSelected.push(id);
                }
            });

            templateService.setLocations(loactionsSelected);

            context.emit("updateLocation", {});
        };

        const unselect = () => {
            // console.log(value);
        };

        return { value, options, locations, handleSubmit, unselect };
    },
};
</script>

<style lang="scss" scoped>
// .multiselect-rm {
//     --ms-tag-bg: #00b49f;
// }
// .selectUsers {
//     width: 100%;
//     text-align: center;
//     margin: 1em;
// }
// .select-container {
//     margin-top: 1em;
// }
</style>



