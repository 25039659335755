<template>
	<div class="select-container">
		<Multiselect
			v-model="value"
			mode="multiple"
			label="name"
			trackBy="name"
			placeholder="Search & select users..."
			:options="options"
			:searchable="true"
			@select="handleSubmit"
			@deselect="unselect"
		>
			<template v-slot:multiplelabel="{ values }">
				<div class="multiselect-multiple-label">
					{{ values.length }} users added
				</div>
			</template>
		</Multiselect>
	</div>
</template>

<script>
import { computed, onBeforeMounted } from "@vue/runtime-core";
import userCollection from "../../../composables/userCollection";
import { ref } from "@vue/reactivity";
import Multiselect from "@vueform/multiselect";
import { useRouter, useRoute } from "vue-router";
import templateService from "../../../composables/templateService";

export default {
	name: "LinkUsers",
	components: {
		Multiselect,
	},
	props: {
		userType: {
			type: Number,
			required: true,
		},
	},
	emits: ["updateSelectedUser"],
	setup(props, context) {
		const { getUser, users, error } = userCollection();
		const value = ref([]);
		const updating = ref(false);
		const updated = ref(false);

		getUser();

		const options = computed(() => {
			if (users.value.value != null) {
				users.value.value.map((user) => {
					var name = user.profile.fullName;
					user.value = user.id;
					user.name = name;
				});
			}
			return users.value.value;
		});

		const unselect = () => {
			// console.log(value);
		};

		const handleSubmit = () => {
			updated.value = false;
			updating.value = true;

			if (props.userType == 1) {
				var users = templateService.getUsers();

				if (users == null) {
					users = [];
				}

				value.value.forEach((id) => {
					if (templateService.getUsers().length > 0) {
						if (
							templateService
								.getUsers()
								.findIndex((x) => x == id) == -1
						) {
							users.push(id);
						}
					} else {
						users.push(id);
					}
				});

				templateService.setUsers(users);
			} else {
				var usersForCompletedChecklist =
					templateService.getUsersForCompletedChecklist();

				if (usersForCompletedChecklist == null) {
					usersForCompletedChecklist = [];
				}

				value.value.forEach((id) => {
					if (
						templateService.getUsersForCompletedChecklist() !=
							undefined &&
						templateService.getUsersForCompletedChecklist().length >
							0
					) {
						if (
							templateService
								.getUsersForCompletedChecklist()
								.findIndex((x) => x == id) == -1
						) {
							usersForCompletedChecklist.push(id);
						}
					} else {
						usersForCompletedChecklist.push(id);
					}
				});

				templateService.setUsersForCompletedChecklist(
					usersForCompletedChecklist
				);
			}

			context.emit("updateSelectedUser", {});

			setTimeout(() => {
				updating.value = false;
				updated.value = true;
			}, 500);
		};

		return {
			value,
			options,
			users,
			handleSubmit,
			updating,
			updated,
			unselect,
		};
	},
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="scss" scoped>
.multiselect-rm {
	--ms-tag-bg: #00b49f;
	/* --ms-tag-color: $rm-blue; */
}
.selectUsers {
	width: 100%;
	text-align: center;
	margin: 1em;
}
.select-container {
	margin-top: 1em;
}
</style>
