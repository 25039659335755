<template>
    <alert :message="saveError" v-if="saveError" />
    <div class="spacer-20"></div>
    <form class="t-form">
        <div class="t-form-question">
            <div class="icon-container">
                <fa class="question-icon" icon="file" />
            </div>
            <div class="t-input">
                <textarea
                    class="t-question"
                    placeholder="Page Title..."
                    v-model="pageTitle"
                    v-on:keydown.enter.prevent="submit"
                />
            </div>
        </div>
        <div class="save-item" style="justify-content:flex-end">
            <div class="t-btn">
                <button class="btn btn-primary" v-on:click.prevent="submit">Save</button>
            </div>
        </div>
    </form>
</template>

<script>
import { ref } from "vue";
import { onMounted } from "@vue/runtime-core";
import Alert from "../../Alert.vue";

export default {
    components: { Alert },
    props: ["selectedPage", "pages"],
    emits: ["newPage"],
    setup(props, context) {
        const pageTitle = ref("");
        const saveError = ref();

        onMounted(() => {
            if (props.selectedPage != undefined) {
                var previewData = props.pages;
                if (previewData != undefined) {
                    previewData.map((item) => {
                        if (item.page.id == props.selectedPage.pageId) {
                            pageTitle.value = item.page.pageTitle;
                        }
                    });
                }
            }
        });

        const submit = () => {
            saveError.value = null;
            if (pageTitle.value == "") {
                saveError.value = "A page title is required";
                return;
            }

            if (props.selectedPage == undefined) {
                context.emit("newPage", pageTitle.value, "", false);
                pageTitle.value = "";
            } else {
                context.emit(
                    "newPage",
                    pageTitle.value,
                    props.selectedPage.pageId,
                    true
                );
                pageTitle.value = "";
            }
        };

        return { pageTitle, submit, saveError };
    },
};
</script>

<style lang="scss" scoped>
// .save-item {
// //     margin-left: 3em !important;
// }
</style>