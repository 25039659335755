<template>
	<Alert message="Please enter a Template Name" v-if="showAlert == true" />
	<Alert :message="error" v-if="error" />
	<div class="t-container">
		<div class="t-left" v-if="!template && !isNewTemplate">
			<div class="centered">
				<page-loading />
			</div>
		</div>
		<div class="t-left" v-else>
			<div class="row">
				<div class="t-nav">
					<span
						id="template"
						:class="visible === 'template' ? 'active' : 'inactive'"
						@click="displayComponent($event)"
						>Template</span
					>
					<span
						id="design"
						:class="visible === 'design' ? 'active' : 'inactive'"
						@click="displayComponent($event)"
						>Design</span
					>

					<!-- <Share @callback="showModalPopup" /> -->
					<span
						id="share"
						:class="visible === 'share' ? 'active' : 'inactive'"
						@click="displayComponent($event)"
						>Share</span
					>

					<span
						id="schedule"
						:class="visible === 'schedule' ? 'active' : 'inactive'"
						@click="displayComponent($event)"
						>Schedule</span
					>

					<span>
						<div class="btn-icon muted">
							<div class="text" @click="saveAndClose()">
								Close
							</div>
						</div>
					</span>
				</div>
			</div>

			<!-- Form components here -->
			<Template
				v-if="visible === 'template'"
				:templateData="template"
				:id="props.id"
				@previewData="getPreviewData"
			></Template>

			<Design
				v-if="visible === 'design'"
				:templateData="template"
				:pageData="preview"
				@previewData="getPreviewData"
				ref="designRef"
			/>

			<Share v-if="visible === 'share' && template" />

			<Schedule
				v-if="visible === 'schedule' && template"
				:templateData="template"
				:id="props.id"
				@scheduleData="updScheduleData"
			/>
		</div>

		<!-- Share with users popup form -->
		<PageList
			v-if="showModal"
			:pages="pages"
			:pageToCopy="pageId"
			:sectionToCopy="sectionId"
			@close="close"
			@copySectionCallback="copySectionCallback"
		/>

		<SectionList
			v-if="showSectionModal"
			:pages="preview"
			:pageToCopy="pageId"
			:sectionToCopy="sectionId"
			:questionToCopy="questionId"
			@close="close"
			@copyQuestionCallback="copyQuestionCallback"
		/>

		<Preview
			:pages="preview"
			:isEdit="false"
			ref="previewChild"
			@callback="callback"
			@deleteData="deleteData"
			@updateData="updateData"
			@addSection="addSection"
			@addQuestion="addQuestion"
			@copySectionToPage="copySectionToPage"
			@copyQuestionToPage="copyQuestionToPage"
			@addNewPage="addNewPage"
		/>

		<router-view />
	</div>
</template>

<script>
import { ref, watchEffect } from "vue";
import Template from "../../components/checklists/TemplateBuilder/Template.vue";
import Schedule from "../../components/checklists/TemplateBuilder/Schedule.vue";
import Design from "../../components/checklists/TemplateBuilder/Design.vue";
import Preview from "../../components/checklists/TemplateBuilder/Preview.vue";
import templateCollection from "../../composables/templateCollection";
import { v4 as uuidv4 } from "uuid";
import { useRouter } from "vue-router";
import { onBeforeMount, onBeforeUnmount, onUnmounted } from "@vue/runtime-core";
import templateService from "../../composables/templateService";
import ModalPopup from "../../components/checklists/TemplateBuilder/modalpopup.vue";
import Share from "../../components/checklists/TemplateBuilder/Share.vue";
import account from "../../composables/account";
import PageList from "../../components/checklists/TemplateBuilder/PageList.vue";
import SectionList from "../../components/checklists/TemplateBuilder/SectionList";
import PageLoading from "../../components/PageLoading.vue";
import Alert from "../../components/Alert.vue";
import { useMeta } from "vue-meta";

export default {
	name: "TemplateBuilder",
	components: {
		Template,
		Design,
		Preview,
		ModalPopup,
		Share,
		PageList,
		SectionList,
		PageLoading,
		Alert,
		Schedule,
		Schedule,
	},
	props: ["id"],
	emits: ["callBack"],
	setup(props, context) {
		useMeta({ title: "Template Builder" });
		const router = useRouter();
		const showAlert = ref(false);
		const { user, companyId, userDoc } = account();
		const {
			document,
			error,
			addPages,
			getTemplate,
			templateData,
			updatePages,
			deletePage,
			deleteSection,
			deleteQuestion,
			updateQuestionOrder,
			updateSectionOrder,
			updatePageOrder,
			getTemplateTags,
			updateTemplateTags,
			updateTemplate,
			addSectionToPage,
			addQuestions,
			updateTime,
			addPage,
			updateScheduleData,
		} = templateCollection();

		const isNewTemplate = ref(false);
		const templateId = ref(null);
		const preview = ref([]);
		const template = ref(null);
		const previewChild = ref(null);
		const designRef = ref(null);
		const showModal = ref(false);
		const showSectionModal = ref(false);
		const modalParams = ref();
		// const templateTags = ref([]);
		const pageId = ref(null);
		const sectionId = ref(null);
		const pages = ref([]);
		const questionId = ref(null);
		const isLoading = ref(false);

		onBeforeMount(async () => {
			templateData.value = null;
			preview.value = [];
			template.value = null;
			templateService.setUsers([]);
			templateService.setUsersForCompletedChecklist([]);
			// templateTags.value = await getTemplateTags();

			var goback = localStorage.getItem("isNewTemplate");

			if (goback == "true") {
				localStorage.setItem("isNewTemplate", "false");
				router.push({ name: "Checklists" });
			} else {
				var id = router.currentRoute.value.params.id;
				if (id != "new") {
					await loadData(id);

					//templateId.value = props.id;
				} else {
					isNewTemplate.value = true;
					templateService.setIsUpdate(false);
					localStorage.setItem("isNewTemplate", "true");
				}
			}
		});

		onBeforeUnmount(async () => {
			try {
				await updateTime(templateService.getTemplateId());
				if (templateId.value != null) {
					await saveTemplate(false);
				}
			} catch (e) {}
		});

		watchEffect(() => {
			if (templateData.value != null) {
				template.value = templateData.value;
			}
		});

		const loadData = async (id) => {
			await getTemplate(id);
			template.value = templateData.value;
			preview.value = templateData.value.pages;
			previewChild.value?.updatedata(preview.value);
			templateService.setTemplate(templateData.value);
			templateService.setPages(templateData.value.pages);
			templateService.setIsUpdate(true);
			templateService.setTemplateId(id);
			templateService.setUsers(template.value.users);
			templateService.setSharedWithPeple(
				template.value.sharedCompletedChecklistWithPeople
			);
			templateService.setLocations(templateData.value.locations);

			var users = [];
			template.value.sharedCompletedChecklistWithPeople.forEach((x) => {
				users.push(x.id);
			});
			templateService.setUsersForCompletedChecklist(users);
		};

		const visible = ref("template");

		const displayComponent = async (event) => {
			var componentId = event.currentTarget.id;

			if (template.value == null || template.value.name == "") {
				visible.value = "template";
				showAlert.value = true;
			} else {
				showAlert.value = false;
				await addNewTemplate();

				switch (componentId) {
					case "template":
						visible.value = "template";
						break;
					case "design":
						visible.value = "design";
						break;
					case "share":
						visible.value = "share";
						break;
					case "schedule":
						visible.value = "schedule";
						break;
					default:
						visible.value = "template";
				}
			}
		};

		const addNewTemplate = async () => {
			if (templateService.getIsUpdate() == false) {
				var id = await saveTemplate(false);
				await loadData(id);
				//templateId.value = id;
			}
		};

		const saveAndClose = async () => {
			setTimeout(() => saveTemplate(true), 500);
		};

		const updScheduleData = async (data) => {
			template.value.IsScheduleChecklist = data.IsScheduleChecklist;
			template.value.scheduleChecklistType = data.scheduleChecklistType;

			template.value.scheduleDays = data.scheduleDays;

			if (data.scheduleDuration == "day/s") {
				template.value.scheduleDuration = 1;
				data.scheduleDuration = 1;
			} else if (data.scheduleDuration == "month/s") {
				template.value.scheduleDuration = 2;
				data.scheduleDuration = 2;
			} else {
				template.value.scheduleDuration = 3;
				data.scheduleDuration = 3;
			}

			await updateScheduleData(templateService.getTemplateId(), data);
		};

		const getPreviewData = (data) => {
			var previewData = null;
			var templateData = null;
			if (data[0] != null) {
				previewData = data[0];
			}
			if (data[1] != null) {
				templateData = data[1];
				template.value = data[1];
			}

			if (previewData != null) {
				previewChild.value?.updatedata(previewData);
				preview.value = previewData;
			}
		};

		// const updateTemplateTagsData = (data) => {
		// 	updateTemplateTags(data);
		// };

		const showModalPopup = (data) => {
			showModal.value = true;

			modalParams.value = {
				modalType: data.type,
				pageId: data.page,
				sectionId: data.section,
				question: data.question,
			};
		};

		const copySectionToPage = async (data) => {
			pages.value = [];

			preview.value.map((page) => {
				pages.value.push({
					id: page.page.id,
					name: page.page.pageTitle,
				});
			});
			pageId.value = data.page;
			sectionId.value = data.section;
			showModal.value = true;
		};

		const copyQuestionToPage = async (data) => {
			pageId.value = data.page;
			sectionId.value = data.section;
			questionId.value = data.question;
			showSectionModal.value = true;
		};

		const addSection = async (pageId, data) => {
			await addSectionToPage(
				templateService.getTemplateId(),
				pageId,
				data
			);
		};

		const addQuestion = async (pageId, sectionId, quest) => {
			await addQuestions(
				templateService.getTemplateId(),
				pageId,
				sectionId,
				quest
			);
		};

		const addNewPage = async (data) => {
			await addPage(templateService.getTemplateId(), data);
		};

		const callback = (data) => {
			showModal.value = false;
			if (visible.value != "design") visible.value = "design";
			modalParams.value = {
				modalType: data.type,
				pageId: data.page,
				sectionId: data.section,
				question: data.question,
			};
			setTimeout(function () {
				designRef.value.navigateToComponent(modalParams);
			}, 10);
			// designRef.value.navigateToComponent(modalParams);
		};

		const updateData = (data) => {
			if (data.type == "question") {
				updateQuestionOrder(
					templateService.getTemplateId(),
					data.page,
					data.section,
					data.question,
					data.order
				);
			}
			if (data.type == "section") {
				updateSectionOrder(
					templateService.getTemplateId(),
					data.page,
					data.section,
					data.order
				);
			}
			if (data.type == "page") {
				updatePageOrder(
					templateService.getTemplateId(),
					data.page,
					data.order
				);
			}
		};

		const deleteData = (data) => {
			if (data.type == "question") {
				deleteQuestion(
					templateService.getTemplateId(),
					data.page,
					data.section,
					data.question
				);
			}
			if (data.type == "section") {
				deleteSection(
					templateService.getTemplateId(),
					data.page,
					data.section
				);
			}
			if (data.type == "page") {
				deletePage(templateService.getTemplateId(), data.page);
			}
		};

		const close = () => {
			showModal.value = false;
			showSectionModal.value = false;
		};

		const copySectionCallback = (data) => {
			showModal.value = false;
			previewChild.value.copySectionToPage(
				data.fromPageId,
				data.fromSectionId,
				data.toPageId
			);
		};

		const copyQuestionCallback = (data) => {
			showSectionModal.value = false;
			previewChild.value.copyQuestionToPage(
				data.fromPageId,
				data.fromSectionId,
				data.fromQuestionId,
				data.toPageId,
				data.toSectionId
			);
		};

		const saveTemplate = async (goBack) => {
			if (template.value == null && goBack) {
				router.push({ name: "Checklists" });
				return;
			} else if (template.value.name == "") {
				error.value = "Please enter add a Template Name";
				return;
			}
			var equipmentsData = templateService.getEquipments().value;
			var usersData = [];
			if (
				templateService.getUsers().length == 0 ||
				templateService.getUsers() == undefined
			) {
				usersData.push(user.value.uid);
			} else {
				usersData = templateService.getUsers(); //
			}

			var locationData = templateService.getLocations();
			var usersForCompletedChecklist =
				templateService.getSharedWithPeple();

			if (template != undefined) {
				const template1 = {
					description:
						template.value.description == undefined
							? ""
							: template.value.description,
					name: template.value.name,

					// instruction:
					// 	template.value.instruction == undefined
					// 		? ""
					// 		: template.value.instruction,
					// isSignatureRequired:
					// 	template.value.signRequired == undefined
					// 		? false
					// 		: template.value.signRequired,
					equipments:
						equipmentsData == undefined ? [] : equipmentsData,
					users: usersData == undefined ? [] : usersData,
					sharedCompletedChecklistWithPeople:
						usersForCompletedChecklist == undefined
							? []
							: usersForCompletedChecklist,
					locations: locationData == undefined ? [] : locationData,
					companyId: companyId,
					tags:
						template.value.tags == undefined
							? []
							: template.value.tags,
					isArchived: false,
					IsScheduleChecklist:
						template.value.IsScheduleChecklist == undefined
							? false
							: template.value.IsScheduleChecklist,
					scheduleDuration:
						template.value.scheduleDuration == undefined
							? 1
							: template.value.scheduleDuration,
					scheduleChecklistType:
						template.value.scheduleChecklistType == undefined
							? { id: 1, name: "Blank" }
							: template.value.scheduleChecklistType,
					scheduleDays:
						template.value.scheduleDays == undefined
							? 1
							: template.value.scheduleDays,
				};

				if (templateService.getIsUpdate()) {
					updateTemplate(template1, templateService.getTemplateId());
					//router.push({ name: "Checklists" });
				} else {
					var id = uuidv4();

					await addPages(template1, preview, id);
					templateService.setTemplateId(id);
				}

				if (goBack) {
					router.push({ name: "Checklists" });
				} else {
					return templateService.getTemplateId();
				}
			}
		};

		return {
			visible,
			displayComponent,
			getPreviewData,
			preview,
			previewChild,
			template,
			templateData,
			saveTemplate,
			designRef,
			showModal,
			callback,
			modalParams,
			close,
			showModalPopup,
			templateId,
			deleteData,
			updateData,
			// templateTags,
			// updateTemplateTagsData,
			showAlert,
			addSection,
			addQuestion,
			copySectionToPage,
			copyQuestionToPage,
			pageId,
			sectionId,
			questionId,
			pages,
			copySectionCallback,
			copyQuestionCallback,
			addNewPage,
			isLoading,
			error,
			showSectionModal,
			props,
			saveAndClose,
			updScheduleData,
			isNewTemplate,
		};
	},
};
</script>

<style lang="scss" scoped>
.t-container {
	display: flex;
	height: 100%;

	flex-direction: column;

	@include respond(medium) {
		flex-direction: row;
	}
}

.centered {
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.t-left {
	width: 100%;
	min-height: 100vh;
	padding: 1.5em;
	background-color: white;

	@include respond(small) {
		padding: 2.5em;
	}
	@include respond(medium) {
		width: 55%;
		padding: 3em;
	}
	@include respond(large) {
		padding: 4.5em;
	}
	@include respond(larger) {
		padding: 5.5em;
	}
}

.t-nav {
	display: flex;
	// gap: 4em;
	gap: 0.5em;
	align-items: center;
	justify-content: space-evenly;
	padding: 1em 0;
	font-weight: bold;
	color: $rm-grey;
	// margin-left: 1.3em;
	@include respond(small) {
		gap: 2em;
	}
	@include respond(medium) {
		gap: 1.5em;
	}
	@include respond(large) {
		gap: 3em;
	}
	@include respond(larger) {
		gap: 4em;
	}
	span {
		cursor: pointer;
		&:hover {
			color: $rm-pink;
		}
	}
}

// .t-preview_cover {
//     position: relative;
//     width: 40%;
//     height: 10%;
//     padding: 2rem;
//     border-radius: 20px;
//     box-shadow: $box-shadow;
//     background-color: grey;
// }

// .preview-frame {
//     width: 100%;
//     height: 100%;
//     background-color: rgb(46, 46, 46);
//     border-radius: 20px;
//     box-shadow: $box-shadow;
//     padding: 1.5rem;
// }

// .preview-screen {
//     width: 100%;
//     height: 100%;
//     border-radius: 8px;
//     background-color: white;
// }

// .preview-screen-cover {
//     width: 100%;
//     height: 5%;
//     border-radius: 8px;
//     background-color: white;
// }

// .alert {
//     position: fixed;
//     top: 1em;
//     left: 10em;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     gap: 1em;
//     background-color: red;
//     border-radius: 8px;
//     box-shadow: $box-shadow;
//     padding: 12px 25px;
//     color: $rm-dark-blue;
//     z-index: 9999;
//     .btn-primary {
//         background-color: $rm-danger;
//     }
// }
</style>
