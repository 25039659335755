<template>
	<div class="spacer-40"></div>
	<div class="share-container">
		<!-- <div class="spacer-20"></div> -->
		<h3>Share template with locations</h3>
		<div class="help-text">
			Grants access to everyone at the locations you select
		</div>
		<LinkLocation
			:isTemplateBuilder="true"
			@updateLocation="updateLocation"
		/>
		<div class="spacer-20"></div>
		<div class="user-grid">
			<div v-for="location in selectedLocation" :key="location.id">
				<div class="pill">
					<div>
						{{ location.locationName }}
						<fa
							class="icon"
							icon="trash"
							@click="deleteSelectedLocation(location.id)"
						/>
					</div>
				</div>
			</div>
		</div>

		<div class="spacer-40"></div>
		<h3>Share template with people</h3>
		<div class="help-text">Grants access to individual users</div>
		<LinkUser :userType="1" @updateSelectedUser="updateData" />
		<div class="spacer-20"></div>
		<div class="user-grid">
			<div v-for="user in selectedUsers" :key="user.id">
				<div>
					<div class="pill">
						{{ user.name }}
						<fa
							class="icon"
							icon="trash"
							@click="deleteSelectedUser(user.id)"
						/>
					</div>
				</div>
			</div>
		</div>

		<div class="spacer-40"></div>
		<h3>Share & notify when completed</h3>
		<div class="help-text">
			Send a notification email every time a checklist is completed.
			Completed checklists are shared with Admins, collaborators, etc, by
			default, but not notified.
		</div>
		<LinkUser :userType="2" @updateSelectedUser="updateData" />
		<div class="spacer-20"></div>
		<div class="user-grid">
			<div
				v-for="user in selectedUsersForCompletedChecklist"
				:key="user.id"
			>
				<div class="pill">
					<div>
						{{ user.name }}
						<fa
							class="icon"
							icon="trash"
							@click="
								deleteSelectedUserForCompletedChecklist(user.id)
							"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { onBeforeMount, onMounted, ref } from "vue";
import templateService from "../../../composables/templateService";
import LinkUser from "../../../components/checklists/TemplateBuilder/LinkUser.vue";
import LinkLocation from "./LinkLocation.vue";
import templateCollection from "../../../composables/templateCollection";
import locationCollection from "../../../composables/locationCollection";
export default {
	props: [],
	components: { LinkUser, LinkLocation },
	emits: ["callBack"],
	setup(props, context) {
		const IsPublicLocation = ref("false");
		const IsPublicUser = ref("false");
		const { getUsers, error } = templateCollection();
		const { getLocation, locations, err } = locationCollection();
		const selectedUsers = ref([]);
		const selectedUsersForCompletedChecklist = ref([]); // used for display
		const companyUsers = ref([]);
		const selectedLocation = ref([]);

		onBeforeMount(async () => {
			companyUsers.value = await getUsers();
			await getLocation();
			setTimeout(() => {
				populateData();
				populateLocationData();
			}, 100);
		});

		const populateData = () => {
			var userSharedWithPeople = [];

			if (companyUsers.value.userData != null) {
				companyUsers.value.userData.forEach((user) => {
					if (templateService.getUsers().length > 0) {
						if (
							templateService
								.getUsers()
								.findIndex((x) => x == user.id) != -1
						) {
							selectedUsers.value.push(user);
						}
					}

					if (
						templateService.getUsersForCompletedChecklist().length >
						0
					) {
						if (
							templateService
								.getUsersForCompletedChecklist()
								.findIndex((x) => x == user.id) != -1
						) {
							selectedUsersForCompletedChecklist.value.push(user);
							userSharedWithPeople.push(user);
						}
					}
				});

				templateService.setSharedWithPeple(userSharedWithPeople);
			}
		};

		const populateLocationData = () => {
			if (locations.value != null) {
				locations.value.value.map((location) => {
					if (templateService.getLocations().length > 0) {
						if (
							templateService
								.getLocations()
								.findIndex((x) => x == location.id) != -1
						) {
							selectedLocation.value.push(location);
						}
					}
				});
			}
		};

		const deleteSelectedLocation = (id) => {
			var index = templateService
				.getLocations()
				.findIndex((x) => x == id);
			templateService.getLocations().splice(index, 1);
			var idx = selectedLocation.value.findIndex((loc) => id == loc.id);
			if (idx != -1) {
				selectedLocation.value.splice(idx, 1);
			}
		};

		const deleteSelectedUser = (id) => {
			var index = templateService.getUsers().findIndex((x) => x == id);
			templateService.getUsers().splice(index, 1);

			var idx = selectedUsers.value.findIndex((user) => id == user.id);
			if (idx != -1) {
				selectedUsers.value.splice(idx, 1);
			}
		};

		const deleteSelectedUserForCompletedChecklist = (id) => {
			//used for Link user code
			var index = templateService
				.getUsersForCompletedChecklist()
				.findIndex((x) => x == id);
			templateService.getUsersForCompletedChecklist().splice(index, 1);

			// Update data in db for shared completed checklist with people
			var index1 = templateService
				.getSharedWithPeple()
				.findIndex((x) => x.id == id);
			templateService.getSharedWithPeple().splice(index1, 1);
			console.log(templateService.getSharedWithPeple());

			//display
			var idx = selectedUsersForCompletedChecklist.value.findIndex(
				(user) => id == user.id
			);
			if (idx != -1) {
				selectedUsersForCompletedChecklist.value.splice(idx, 1);
			}
		};

		const updateData = () => {
			selectedUsers.value = [];
			selectedUsersForCompletedChecklist.value = [];
			populateData();
		};

		const updateLocation = () => {
			selectedLocation.value = [];
			populateLocationData();
		};

		const handleIsPublicLocationSubmit = () => {
			IsPublicLocation.value = !IsPublicLocation.value;
			if (IsPublicLocation.value == true) {
				templateService.setLocations = [];
			}
			templateService.setTemplatePublicLocation(IsPublicLocation.value);
		};

		const handleIsPublicUserSubmit = () => {
			if (IsPublicUser.value == true) {
				templateService.setUsers = [];
			}
			IsPublicUser.value = !IsPublicUser.value;
			templateService.setTemplatePublicUser(IsPublicUser.value);
		};

		const userClick = () => {
			context.emit("callback", {
				type: "linkuser",
				page: "",
				section: "",
				question: "",
			});
		};

		const locationClick = () => {
			context.emit("callback", {
				type: "linklocation",
				page: "",
				section: "",
				question: "",
			});
		};

		return {
			IsPublicLocation,
			handleIsPublicLocationSubmit,
			IsPublicUser,
			handleIsPublicUserSubmit,
			userClick,
			locationClick,
			selectedUsers,
			selectedUsersForCompletedChecklist,
			deleteSelectedUser,
			deleteSelectedUserForCompletedChecklist,
			updateData,
			updateLocation,
			selectedLocation,
			deleteSelectedLocation,
		};
	},
};
</script>

<style lang="scss" scoped>
.share-container {
	// max-height: 70vh;
	// overflow: auto;
	padding-bottom: 5em;
	width: 100%;
}
.help-text {
	color: $rm-grey;
	font-size: 0.9em;
}
.user-grid {
	display: flex;
	gap: 0.5rem;
	flex-wrap: wrap;
}
.pill {
	display: flex;
	flex: 1;
	padding: 0.5em 1em;
	background-color: $rm-blue;
	color: white;
	margin: 1em;
	.icon {
		margin-left: 0.5em;
		cursor: pointer;
	}
}
</style>
