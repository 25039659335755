// Provides openai with the response json structure
const example = {
    title: "<TITLE>",
    description: "<DESCRIPTION>",
    pages: [
        {
            page_title: "<PAGE_TITLE>",
            sections: [
                {
                    section_title: "<SECTION_TITLE>",
                    questions: [
                        {
                            question: "<QUESTION>",
                            input_type: "date",
                        },
                        {
                            question: "<QUESTION>",
                            input_type: "text",
                        },
                        {
                            question: "<QUESTION>",
                            input_type: "radio",
                        }
                    ]
                },
                {
                    section_title: "<SECTION_TITLE>",
                    questions: [
                        {
                            question: "<QUESTION>",
                            input_type: "date",
                        },
                        {
                            question: "<QUESTION>",
                            input_type: "text",
                        },
                        {
                            question: "<QUESTION>",
                            input_type: "radio",
                        }
                    ]
                }
            ]
        },
        {
            page_title: "<PAGE_TITLE>",
            sections: [
                {
                    section_title: "<SECTION_TITLE>",
                    questions: [
                        {
                            question: "<QUESTION>",
                            input_type: "date",
                        },
                        {
                            question: "<QUESTION>",
                            input_type: "text",
                        },
                        {
                            question: "<QUESTION>",
                            input_type: "radio",
                        }
                    ]
                },
                {
                    section_title: "<SECTION_TITLE>",
                    questions: [
                        {
                            question: "<QUESTION>",
                            input_type: "date",
                        },
                        {
                            question: "<QUESTION>",
                            input_type: "text",
                        },
                        {
                            question: "<QUESTION>",
                            input_type: "radio",
                        }
                    ]
                }
            ]
        }
    ]
};

const templateString = JSON.stringify(example);

// Generate a checklist from a user file
let filePrompt1 = "List all of the questions in the Example File in one page and one section. "
let filePrompt2 = "Create an appropriate Checklist Title, Checklist Description, Page Title and Section Title. "

// Generate a checklist from user title and instructions
let instructionsPrompt1 =
    "Create a Checklist derived from the Checklist Title and Instructions. Do not copy the Instructions verbatim for the Checklist Description. ";
let instructionsPrompt2 =
    "Create 5 questions per section derived from the Section Title and parent Page Title";
let instructionsPrompt3 =
    "In the Instructions, Page Titles are enclosed in % signs and sub-section Section Titles are enclosed in $ signs. Do not include the % or $ signs in the Checklist. ";
let instructionsPrompt4 = "Always create one page object for each Page Title and one section child object for each Section Title in the order they are written in the Instructions. "

// Generic prompts
let type = "With each question, provide the HTML input type or select tag that's appropriate for the question. "
let json = "The response must be written in JSON. "
let template =
    "This is a template for the response: " + templateString;

// combine this with the example
let instructionsPrompt = instructionsPrompt1 + instructionsPrompt2 + instructionsPrompt3 + instructionsPrompt4 + type + json + template

// Combine this with the user example file and example
let filePrompt = filePrompt1 + filePrompt2 + type + json + template;


// To test without calling the API
const testData = {
    title: "Climbing Wall Daily Check",
    description:
        "This checklist is to be used by a climbing instructor to inspect an indoor climbing wall each morning before use.",
    pages: [
        {
            page_title: "Climbing Wall Inspection",
            sections: [
                {
                    section_title:
                        "Instructor & Inspection Details",
                    questions: [
                        {
                            question: "Name of Climbing Instructor",
                            input_type: "text",
                        },
                        {
                            question: "Date of Inspection",
                            input_type: "date",
                        },
                        {
                            question: "Time of Inspection",
                            input_type: "time",
                        },
                        {
                            question: "Inspection Type",
                            input_type: "select",
                            options: ["Full", "Partial"],
                        },
                        {
                            question: "Photo of the Climbing Wall",
                            input_type: "image",
                        }
                    ]
                },
                {
                    section_title: "Climbing Wall & Anchors",
                    questions: [
                        {
                            question:
                                "Wall anchors visible and secure?",
                            input_type: "checkbox",
                        },
                        {
                            question:
                                "Wall free of damage and wear?",
                            input_type: "checkbox",
                        },
                        {
                            question:
                                "Holding and handholds secure?",
                            input_type: "checkbox",
                        },
                        {
                            question: "T-Nuts secure?",
                            input_type: "checkbox",
                        }
                    ]
                },
                {
                    section_title:
                        "Ropes, Harnesses and Belay Devices",
                    questions: [
                        {
                            question: "Ropes free of damage?",
                            input_type: "checkbox",
                        },
                        {
                            question:
                                "Harnesses in good condition?",
                            input_type: "checkbox",
                        },
                        {
                            question:
                                "Belay devices in working order?",
                            input_type: "checkbox",
                        },
                        {
                            question:
                                "Climbing shoes in good condition?",
                            input_type: "checkbox",
                        }
                    ]
                }
            ]
        }
    ]
};

export { filePrompt, example, testData, instructionsPrompt, }