<template>
	<div class="t-preview">
		<!-- Displayed whilst preview loads -->
		<div v-if="!preview || !preview.length" class="empty-preview">
			<div v-for="quote in quotes" :key="quote.id">
				<div v-if="quote.id == randomQuote" class="quote-content">
					<div class="quote">
						<fa class="quote-icon" icon="quote-left" />
					</div>
					<div class="quote-text">{{ quote.quote }}</div>
					<br />
					<div class="quote-author">
						<i>{{ quote.author }}</i>
					</div>
				</div>
			</div>
		</div>

		<!-- Preview -->
		<div class="preview-container">
			<div v-for="item in preview" :key="item.page.id" class="drop-zone">
				<div
					:id="item.page.id"
					class="preview-row page"
					@drop="onPagedrop($event, item.page.id)"
					@dragenter.prevent
					@dragover.prevent
				>
					<div
						@dragstart="startPageDrag($event, item)"
						:draggable="true"
						class="preview-row_content"
					>
						<div class="preview-row_left-icon grip">
							<fa icon="grip-vertical" />
						</div>

						<div
							class="preview-row_left-icon"
							v-on:click="hideSections"
						>
							<a :href="'#' + item.page.id">
								<fa v-if="hidden == false" icon="caret-down" />
								<fa v-else icon="caret-right" />
							</a>
						</div>

						<div class="preview-row_text">
							<span v-if="!item.page.pageTitle"
								>Page Title...</span
							>
							<span v-else> {{ item.page.pageTitle }}</span>
						</div>

						<div class="preview-row_right-icons">
							<label
								title="Edit"
								id="page"
								@click="selectPage($event, item.page.id)"
							>
								<fa icon="edit" />
							</label>

							<label
								title="Duplicate"
								id="page"
								@click="copyPage($event, item.page.id)"
							>
								<fa icon="copy" class="icon" id="section" />
							</label>

							<label
								title="Delete"
								id="page"
								@click="deletePg($event, item.page.id)"
							>
								<fa class="icon" id="page" icon="trash" />
							</label>
						</div>
					</div>
				</div>
				<transition name="bounce">
					<div v-if="hidden == false">
						<div
							v-for="section in item.page.sections"
							:key="section.section.id"
							class="drop-zone"
						>
							<div
								class="preview-row section"
								:id="section.section.id"
							>
								<div
									:draggable="true"
									@dragstart="startDrag($event, section)"
									class="preview-row_content"
									@drop="onDrop($event, section.section.id)"
									@dragenter.prevent
									@dragover.prevent
								>
									<div class="preview-row_left-icon grip">
										<fa icon="grip-vertical" />
									</div>

									<div
										class="preview-row_left-icon"
										v-on:click="hideQuestions"
									>
										<a
											class="section-anchor-link"
											:href="'#' + section.section.id"
										>
											<fa
												v-if="hiddenQues == false"
												id="section"
												class="section-icon clickable"
												icon="caret-down"
											/>
											<fa
												v-else
												id="section"
												class="section-icon clickable"
												icon="caret-right"
											/>
										</a>
									</div>

									<div class="preview-row_text">
										{{ section.section.sectionTitle }}
									</div>

									<div class="preview-row_right-icons">
										<fa
											v-if="
												section.section.repeat == true
											"
											id="section"
											title="Repeating Section"
											@click="
												selectSection(
													$event,
													item.page.id,
													section.section.id
												)
											"
											icon="infinity"
										/>
										<fa
											id="section"
											title="Edit"
											@click="
												selectSection(
													$event,
													item.page.id,
													section.section.id
												)
											"
											icon="edit"
										/>

										<fa
											id="section"
											title="Duplicate"
											@click="
												copySection(
													$event,
													item.page.id,
													section.section.id
												)
											"
											icon="clone"
										/>

										<fa
											id="section"
											title="Copy to another page"
											@click="
												copySectionToPageShowDropDown(
													$event,
													item.page.id,
													section.section.id
												)
											"
											icon="copy"
										/>

										<fa
											id="section"
											title="Delete"
											@click="
												deleteSect(
													$event,
													item.page.id,
													section.section.id
												)
											"
											icon="trash"
										/>
									</div>
								</div>
							</div>

							<transition name="bounce2">
								<div
									v-if="hiddenQues == false"
									class="questions-container"
								>
									<div
										class="preview-row ques"
										@drop="
											onQuestionDrop(
												$event,
												ques.questId,
												section,
												item.page.id
											)
										"
										@dragenter.prevent
										@dragover.prevent
										v-for="ques in section.section
											.questions"
										:key="ques.questId"
									>
										<div
											class="preview-row_content"
											draggable="true"
											@dragstart="
												startQuestionDrag(
													$event,
													section,
													ques,
													item.page.id
												)
											"
										>
											<div class="preview-row_left-icon">
												<fa
													v-if="ques.type == 'Text'"
													v-bind:class="{
														required:
															ques.isRequired,
													}"
													icon="align-left"
												/>
												<fa
													v-else-if="
														ques.type == 'Equipment'
													"
													v-bind:class="{
														required:
															ques.isRequired,
													}"
													icon="tools"
												/>
												<fa
													v-else-if="
														ques.type == 'People'
													"
													v-bind:class="{
														required:
															ques.isRequired,
													}"
													icon="users"
												/>
												<fa
													v-else-if="
														ques.type == 'Image'
													"
													v-bind:class="{
														required:
															ques.isRequired,
													}"
													icon="image"
												/>
												<fa
													v-else-if="
														ques.type == 'Location'
													"
													v-bind:class="{
														required:
															ques.isRequired,
													}"
													icon="map-marked-alt"
												/>
												<fa
													v-if="
														ques.type ==
														'MultiChoice'
													"
													v-bind:class="{
														required:
															ques.isRequired,
													}"
													icon="tasks"
												/>
												<fa
													v-else-if="
														ques.type == 'Checkbox'
													"
													v-bind:class="{
														required:
															ques.isRequired,
													}"
													icon="check-circle"
												/>
												<fa
													v-if="
														ques.type ==
														'Instruction'
													"
													v-bind:class="{
														required:
															ques.isRequired,
													}"
													icon="book-reader"
												/>
												<fa
													v-else-if="
														ques.type == 'DateTime'
													"
													v-bind:class="{
														required:
															ques.isRequired,
													}"
													icon="calendar-alt"
												/>
												<fa
													v-if="
														ques.type == 'Signature'
													"
													v-bind:class="{
														required:
															ques.isRequired,
													}"
													icon="file-signature"
												/>
											</div>

											<div class="preview-row_text">
												{{ stripTags(ques.question) }}
											</div>

											<div
												class="preview-row_right-icons"
											>
												<fa
													title="Edit"
													id="question"
													@click="
														selectQuestion(
															$event,
															item.page.id,
															section.section.id,
															ques
														)
													"
													icon="edit"
												/>

												<fa
													title="Duplicate"
													id="question"
													@click="
														copyQuestion(
															$event,
															item.page.id,
															section.section.id,
															ques
														)
													"
													icon="clone"
												/>
												<fa
													title="Copy to another page"
													id="question"
													@click="
														copyQuestionToPageShowDropDown(
															$event,
															item.page.id,
															section.section.id,
															ques.questId
														)
													"
													icon="copy"
												/>

												<fa
													title="Delete"
													id="question"
													@click="
														deleteQuest(
															$event,
															item.page.id,
															section.section.id,
															ques.questId
														)
													"
													icon="trash"
												/>
											</div>
										</div>
									</div>
								</div>
							</transition>
						</div>
					</div>
				</transition>
			</div>
		</div>
		<div class="alert" v-if="deleteAlert" @click="clearError">
			<span v-if="!errMessage">Are you sure you want to delete?</span>
			<span v-else>{{ errMessage }}</span>
			<button class="btn btn-success" @click="clearError">Cancel</button>
			<button class="btn btn-danger" @click="confirmDelete()">
				Delete
			</button>
		</div>
	</div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import templateService from "../../../composables/templateService";
import { v4 as uuidv4 } from "uuid";
export default {
	props: ["isEdit"],
	emits: [
		"updateData",
		"deleteData",
		"addQuestion",
		"callback",
		"copySectionToPage",
		"copyQuestionToPage",
		"addNewPage",
		"addSection",
	],
	setup(props, context) {
		const router = useRouter();
		const preview = ref();
		const isEdit = ref(false);
		const hidden = ref(false);
		const hiddenQues = ref(false);
		isEdit.value = props.isEdit;

		preview.value = props.pages;

		const updatedata = (data) => {
			preview.value = data;
		};

		const startPageDrag = (event, page) => {
			hidden.value = true;
			event.dataTransfer.setData("itemPageID", page.page.id);
		};

		const onPagedrop = (event, list) => {
			const itemID = event.dataTransfer.getData("itemPageID");

			var pageDrag = preview.value.find(
				(page) => page.page.id === itemID
			);
			if (pageDrag != undefined) {
				var dragorder = pageDrag.page.order;

				var pageDrop = preview.value.find(
					(page) => page.page.id === list
				);

				if (pageDrop != undefined) {
					var droporder = pageDrop.page.order;

					if (dragorder < droporder) {
						preview.value.map((page) => {
							if (page.page.order == dragorder) {
								page.page.order = droporder;
							} else if (
								page.page.order > dragorder &&
								page.page.order <= droporder
							) {
								page.page.order = page.page.order - 1;
							}
						});
					}

					if (dragorder > droporder) {
						preview.value.map((page) => {
							if (page.page.order == droporder) {
								page.page.order = page.page.order + 1;
							} else if (
								page.page.order < dragorder &&
								page.page.order > droporder
							) {
								page.page.order = page.page.order + 1;
							} else if (page.page.order == dragorder) {
								page.page.order = droporder;
							}
						});
					}

					if (templateService.getIsUpdate()) {
						preview.value.map((page) => {
							context.emit("updateData", {
								type: "page",
								page: page.page.id,
								order: page.page.order,
							});
						});
					}

					preview.value.sort(function (a, b) {
						var order1 = a.page.order;
						var order2 = b.page.order;
						return order1 < order2 ? -1 : order1 > order2 ? 1 : 0;
					});
				}
			}
		};

		const startDrag = (event, section) => {
			hiddenQues.value = true;

			// event.dataTransfer.dropEffect = "move";
			// event.dataTransfer.effectAllowed = "move";
			event.dataTransfer.setData("itemID", section.section.id);
		};

		const startQuestionDrag = (event, section, question, pageId) => {
			event.dataTransfer.dropEffect = "move";
			event.dataTransfer.effectAllowed = "move";
			event.dataTransfer.setData("itemQuestionID", question.questId);
			event.dataTransfer.setData(
				"itemQuestionSectionID",
				section.section.id
			);
			event.dataTransfer.setData("itemPageID", pageId);
		};

		// const onQuestionDrop1 = (event, list, section, pageId) => {
		//     const itemQuestionID = event.dataTransfer.getData("itemQuestionID");
		//     const itemSectionId = event.dataTransfer.getData(
		//         "itemQuestionSectionID"
		//     );
		//     const itemPageId = event.dataTransfer.getData("itemPageId");

		//     preview.value.map((page) => {
		//         if (page.page.sections != undefined) {
		//             var sectionDrag = page.page.sections.find(
		//                 (section) => section.section.id === itemSectionId
		//             );
		//             if (sectionDrag != undefined) {
		//                 var questionDrag = sectionDrag.section.questions.find(
		//                     (question) => question.questId === itemQuestionID
		//                 );
		//                 var dragorder = questionDrag.order;

		//                 var questionDrop = sectionDrag.section.questions.find(
		//                     (question) => question.questId === list
		//                 );

		//                 if (questionDrop != undefined) {
		//                     var droporder = questionDrop.order;

		//                     if (dragorder < droporder) {
		//                         sectionDrag.section.questions.map((ques) => {
		//                             if (ques.order == dragorder) {
		//                                 ques.order = droporder;
		//                             } else if (
		//                                 ques.order > dragorder &&
		//                                 ques.order <= droporder
		//                             ) {
		//                                 ques.order = ques.order - 1;
		//                             }
		//                         });
		//                     }

		//                     if (dragorder > droporder) {
		//                         sectionDrag.section.questions.map((ques) => {
		//                             if (ques.order == droporder) {
		//                                 ques.order = ques.order + 1;
		//                             } else if (
		//                                 ques.order < dragorder &&
		//                                 ques.order > droporder
		//                             ) {
		//                                 ques.order = ques.order + 1;
		//                             } else if (ques.order == dragorder) {
		//                                 ques.order = droporder;
		//                             }
		//                         });
		//                     }

		//                     if (templateService.getIsUpdate()) {
		//                         sectionDrag.section.questions.map((ques) => {
		//                             context.emit("updateData", {
		//                                 type: "question",
		//                                 page: page.page.id,
		//                                 section: sectionDrag.section.id,
		//                                 question: ques.questId,
		//                                 order: ques.order,
		//                             });
		//                         });
		//                     }

		//                     sectionDrag.section.questions.sort(function (a, b) {
		//                         var order1 = a.order;
		//                         var order2 = b.order;
		//                         return order1 < order2
		//                             ? -1
		//                             : order1 > order2
		//                             ? 1
		//                             : 0;
		//                     });
		//                 }
		//             }
		//         }
		//     });
		// };

		const onQuestionDrop = (event, list, section, pageId) => {
			const itemQuestionID = event.dataTransfer.getData("itemQuestionID");
			const itemSectionId = event.dataTransfer.getData(
				"itemQuestionSectionID"
			);
			const itemPageId = event.dataTransfer.getData("itemPageId");

			if (pageId == itemPageId && section.section.id == itemSectionId) {
				preview.value.map((page) => {
					if (page.page.sections != undefined) {
						var sectionDrag = page.page.sections.find(
							(section) => section.section.id === itemSectionId
						);
						if (sectionDrag != undefined) {
							var questionDrag =
								sectionDrag.section.questions.find(
									(question) =>
										question.questId === itemQuestionID
								);
							var dragorder = questionDrag.order;

							var questionDrop =
								sectionDrag.section.questions.find(
									(question) => question.questId === list
								);

							if (questionDrop != undefined) {
								var droporder = questionDrop.order;

								if (dragorder < droporder) {
									sectionDrag.section.questions.map(
										(ques) => {
											if (ques.order == dragorder) {
												ques.order = droporder;
											} else if (
												ques.order > dragorder &&
												ques.order <= droporder
											) {
												ques.order = ques.order - 1;
											}
										}
									);
								}

								if (dragorder > droporder) {
									sectionDrag.section.questions.map(
										(ques) => {
											if (ques.order == droporder) {
												ques.order = ques.order + 1;
											} else if (
												ques.order < dragorder &&
												ques.order > droporder
											) {
												ques.order = ques.order + 1;
											} else if (
												ques.order == dragorder
											) {
												ques.order = droporder;
											}
										}
									);
								}

								if (templateService.getIsUpdate()) {
									sectionDrag.section.questions.map(
										(ques) => {
											context.emit("updateData", {
												type: "question",
												page: page.page.id,
												section: sectionDrag.section.id,
												question: ques.questId,
												order: ques.order,
											});
										}
									);
								}

								sectionDrag.section.questions.sort(function (
									a,
									b
								) {
									var order1 = a.order;
									var order2 = b.order;
									return order1 < order2
										? -1
										: order1 > order2
										? 1
										: 0;
								});
							}
						}
					}
				});
			}

			if (pageId == itemPageId && section.section.id != itemSectionId) {
				preview.value.map((page) => {
					if (pageId == page.page.id) {
						if (page.page.sections != undefined) {
							var sectionDrag = page.page.sections.find(
								(section) =>
									section.section.id === itemSectionId
							);

							var sectionDrop = section;

							if (sectionDrag != undefined) {
								var questionDrag =
									sectionDrag.section.questions.find(
										(question) =>
											question.questId === itemQuestionID
									);

								var questionDragIndex =
									sectionDrag.section.questions.findIndex(
										(question) =>
											question.questId === itemQuestionID
									);

								var dragorder = questionDrag.order;

								var questionDrop =
									sectionDrop.section.questions.find(
										(question) => question.questId === list
									);

								if (questionDrop != undefined) {
									var droporder = questionDrop.order;

									//Drag logic delete question

									if (questionDragIndex != -1) {
										sectionDrag.section.questions.splice(
											questionDragIndex,
											1
										);
									}

									if (templateService.getIsUpdate()) {
										context.emit("deleteData", {
											type: "question",
											page: pageId,
											section: sectionDrag.section.id,
											question: questionDrag.questId,
										});
									}

									sectionDrop.section.questions.sort(
										function (a, b) {
											var order1 = a.order;
											var order2 = b.order;
											return order1 < order2
												? -1
												: order1 > order2
												? 1
												: 0;
										}
									);

									var order =
										sectionDrop.section.questions[
											sectionDrop.section.questions
												.length - 1
										].order;
									questionDrag.order = order + 1;
									questionDrag.choices =
										questionDrag.choices == undefined
											? []
											: questionDrag.choices;
									sectionDrop.section.questions.push(
										questionDrag
									);

									if (templateService.getIsUpdate()) {
										context.emit(
											"addQuestion",
											pageId,
											sectionDrop.section.id,
											questionDrag
										);
									}

									sectionDrag.section.questions.sort(
										function (a, b) {
											var order1 = a.order;
											var order2 = b.order;
											return order1 < order2
												? -1
												: order1 > order2
												? 1
												: 0;
										}
									);

									sectionDrop.section.questions.sort(
										function (a, b) {
											var order1 = a.order;
											var order2 = b.order;
											return order1 < order2
												? -1
												: order1 > order2
												? 1
												: 0;
										}
									);
								}
							}
						}
					}
				});
			}

			if (pageId != itemPageId) {
				var questionDrag = undefined;
				var isQuestionMoved = false;
				preview.value.map((page) => {
					if (pageId == itemPageId) {
						if (page.page.sections != undefined) {
							var sectionDrag = page.page.sections.find(
								(section) =>
									section.section.id === itemSectionId
							);
							if (sectionDrag != undefined) {
								questionDrag =
									sectionDrag.section.questions.find(
										(question) =>
											question.questId === itemQuestionID
									);

								var questionDragIndex =
									sectionDrag.section.questions.findIndex(
										(question) =>
											question.questId === itemQuestionID
									);

								if (questionDragIndex != -1) {
									sectionDrag.section.questions.splice(
										questionDragIndex,
										1
									);
								}

								if (templateService.getIsUpdate()) {
									context.emit("deleteData", {
										type: "question",
										page: itemPageId,
										section: sectionDrag.section.id,
										question: questionDrag.questId,
									});
								}
								isQuestionMoved = true;
							}
						}
					}
				});
				if (isQuestionMoved) {
					preview.value.map((page) => {
						if (page.page.id == pageId) {
							if (page.page.sections != undefined) {
								var sectionDrop = section;

								sectionDrop.section.questions.sort(function (
									a,
									b
								) {
									var order1 = a.order;
									var order2 = b.order;
									return order1 < order2
										? -1
										: order1 > order2
										? 1
										: 0;
								});

								var order =
									sectionDrop.section.questions[
										sectionDrop.section.questions.length - 1
									].order;
								questionDrag.order = order + 1;
								questionDrag.choices =
									questionDrag.choices == undefined
										? []
										: questionDrag.choices;
								sectionDrop.section.questions.push(
									questionDrag
								);

								if (templateService.getIsUpdate()) {
									context.emit(
										"addQuestion",
										pageId,
										sectionDrop.section.id,
										questionDrag
									);
								}

								sectionDrag.section.questions.sort(function (
									a,
									b
								) {
									var order1 = a.order;
									var order2 = b.order;
									return order1 < order2
										? -1
										: order1 > order2
										? 1
										: 0;
								});

								sectionDrop.section.questions.sort(function (
									a,
									b
								) {
									var order1 = a.order;
									var order2 = b.order;
									return order1 < order2
										? -1
										: order1 > order2
										? 1
										: 0;
								});
							}
						}
					});
				}
			}
		};

		const onDrop = (event, list) => {
			const itemID = event.dataTransfer.getData("itemID");

			preview.value.map((page) => {
				if (page.page.sections != undefined) {
					var sectionDrag = page.page.sections.find(
						(section) => section.section.id === itemID
					);
					if (sectionDrag != undefined) {
						var dragorder = sectionDrag.section.order;

						var sectionDrop = page.page.sections.find(
							(section) => section.section.id === list
						);

						if (sectionDrop != undefined) {
							var droporder = sectionDrop.section.order;

							if (dragorder < droporder) {
								page.page.sections.map((section) => {
									if (section.section.order == dragorder) {
										section.section.order = droporder;
									} else if (
										section.section.order > dragorder &&
										section.section.order <= droporder
									) {
										section.section.order =
											section.section.order - 100;
									}
								});
							}

							if (dragorder > droporder) {
								page.page.sections.map((section) => {
									if (section.section.order == droporder) {
										section.section.order =
											section.section.order + 100;
									} else if (
										section.section.order < dragorder &&
										section.section.order > droporder
									) {
										section.section.order =
											section.section.order + 100;
									} else if (
										section.section.order == dragorder
									) {
										section.section.order = droporder;
									}
								});
							}

							if (templateService.getIsUpdate()) {
								page.page.sections.map((section) => {
									context.emit("updateData", {
										type: "section",
										page: page.page.id,
										section: section.section.id,
										order: section.section.order,
									});
								});
							}

							page.page.sections.sort(function (a, b) {
								var order1 = a.section.order;
								var order2 = b.section.order;
								return order1 < order2
									? -1
									: order1 > order2
									? 1
									: 0;
							});
						}
					}
				}
			});
		};

		const selectPage = (event, pageId) => {
			var targetId = event.currentTarget.id;

			context.emit("callback", { type: targetId, page: pageId });
		};

		const selectSection = (event, pageId, sectionId) => {
			var targetId = event.currentTarget.id;

			context.emit("callback", {
				type: targetId,
				page: pageId,
				section: sectionId,
			});
		};

		const copySectionToPageShowDropDown = (event, pageId, sectionId) => {
			context.emit("copySectionToPage", {
				page: pageId,
				section: sectionId,
			});
		};

		const copyQuestionToPageShowDropDown = (
			event,
			pageId,
			sectionId,
			questionId
		) => {
			context.emit("copyQuestionToPage", {
				page: pageId,
				section: sectionId,
				question: questionId,
			});
		};

		const copyPage = (event, pageId) => {
			//Get last order of the page
			preview.value.sort(function (a, b) {
				var order1 = a.page.order;
				var order2 = b.page.order;
				return order1 < order2 ? -1 : order1 > order2 ? 1 : 0;
			});

			var order = preview.value[preview.value.length - 1].page.order + 1;

			var pageToAdd = {};

			preview.value.map((page) => {
				if (page.page.id == pageId) {
					pageToAdd = {
						page: {
							id: uuidv4(),
							order: order,
							pageTitle: page.page.pageTitle,
							sections: [],
						},
					};
					var newSection = {};

					for (const section of page.page.sections) {
						newSection = {
							section: {
								id: uuidv4(),
								sectionTitle: section.section.sectionTitle,
								order: section.section.order,
								repeat: section.section.repeat,
								questions: [],
							},
						};

						var questions = [];

						for (const question of section.section.questions) {
							var choices = [];
							if (question.choices != undefined) {
								question.choices.forEach((choice) => {
									choice = {
										id: uuidv4(),
										text: choice.text,
										color: choice.color,
									};
									choices.push(choice);
								});
							}
							var quest = {
								questId: uuidv4(),
								question: question.question,
								type: question.type,
								order: question.order,
								isSignatureRequired:
									question.isSignatureRequired == undefined
										? false
										: question.isSignatureRequired,
								isRequired:
									question.isRequired == undefined
										? false
										: question.isRequired,
								isTagRequired:
									question.isTagRequired == undefined
										? false
										: question.isTagRequired,
								isMultipleChoice:
									question.isMultipleChoice == undefined
										? false
										: question.isMultipleChoice,
								isLocationRequired:
									question.isLocationRequired == undefined
										? false
										: question.isLocationRequired,
								isImageRequired:
									question.isImageRequired == undefined
										? false
										: question.isImageRequired,
								choices: choices,
							};
							newSection.section.questions.push(quest);
						}

						pageToAdd.page.sections.push(newSection);
					}
				}
			});
			preview.value.push(pageToAdd);

			context.emit("addNewPage", pageToAdd);
		};

		const copySectionToPage = (pageId, sectionId, toPageId) => {
			var sortOrder = 100;
			// Create a new Section for a to Page
			var newSection = {};
			preview.value.map((page) => {
				if (page.page.id == toPageId) {
					if (page.page.sections != undefined) {
						page.page.sections.sort(function (a, b) {
							var order1 = a.section.order;
							var order2 = b.section.order;
							return order1 < order2
								? -1
								: order1 > order2
								? 1
								: 0;
						});

						if (page.page.sections.length > 0) {
							var order =
								page.page.sections[
									page.page.sections.length - 1
								].section.order;
							sortOrder = order + 100;
						}
					}
				}
			});

			// Copy questions of From page to a new section
			preview.value.map((page) => {
				if (page.page.id == pageId) {
					if (page.page.sections != undefined) {
						var section = page.page.sections.find(
							(section) => section.section.id === sectionId
						);
						if (section != undefined) {
							newSection = {
								section: {
									id: uuidv4(),
									sectionTitle: section.section.sectionTitle,
									order: sortOrder,
									repeat: section.section.repeat,
									questions: [],
								},
							};

							var questions = [];

							for (const question of section.section.questions) {
								var choices = [];
								if (question.choices != undefined) {
									question.choices.forEach((choice) => {
										choice = {
											id: uuidv4(),
											text: choice.text,
											color: choice.color,
										};
										choices.push(choice);
									});
								}
								var quest = {
									questId: uuidv4(),
									question: question.question,
									type: question.type,
									order: question.order,
									isSignatureRequired:
										question.isSignatureRequired ==
										undefined
											? false
											: question.isSignatureRequired,
									isRequired:
										question.isRequired == undefined
											? false
											: question.isRequired,
									isTagRequired:
										question.isTagRequired == undefined
											? false
											: question.isTagRequired,
									isMultipleChoice:
										question.isMultipleChoice == undefined
											? false
											: question.isMultipleChoice,
									isLocationRequired:
										question.isLocationRequired == undefined
											? false
											: question.isLocationRequired,
									isImageRequired:
										question.isImageRequired == undefined
											? false
											: question.isImageRequired,
									choices: choices,
								};
								newSection.section.questions.push(quest);
							}
						}
					}
				}
			});

			// Add new section to a toPage
			preview.value.map((page) => {
				if (page.page.id == toPageId) {
					if (!page.page.sections) {
						page.page.sections = [];
					}
					page.page.sections.push(newSection);
					context.emit("addSection", page.page.id, newSection);
				}
			});
		};

		const copyQuestionToPage = (
			pageId,
			sectionId,
			questionId,
			toPageId,
			toSectionId
		) => {
			var sortOrder = 1;

			preview.value.map((page) => {
				if (page.page.id == toPageId) {
					if (page.page.sections != undefined) {
						var section = page.page.sections.find(
							(section) => section.section.id === toSectionId
						);

						if (section != undefined) {
							if (section.section.questions != undefined) {
								section.section.questions.sort(function (a, b) {
									var order1 = a.question.order;
									var order2 = b.question.order;
									return order1 < order2
										? -1
										: order1 > order2
										? 1
										: 0;
								});

								if (section.section.questions.length > 0) {
									var order =
										section.section.questions[
											section.section.questions.length - 1
										].order;
									sortOrder = order + 1;
								}
							}
						}
					}
				}
			});

			// Copy questions of From page to a new section
			var question = {};
			preview.value.map((page) => {
				if (page.page.id == pageId) {
					if (page.page.sections != undefined) {
						var section = page.page.sections.find(
							(section) => section.section.id === sectionId
						);
						if (section != undefined) {
							question = section.section.questions.find(
								(question) => question.questId === questionId
							);
						}
					}
				}
			});

			preview.value.map((page) => {
				if (page.page.id == toPageId) {
					if (page.page.sections != undefined) {
						var section = page.page.sections.find(
							(section) => section.section.id === toSectionId
						);
						if (section != undefined) {
							if (section.section.questions == undefined) {
								section.section.questions = [];
							}

							var choices = [];
							if (question.choices != undefined) {
								question.choices.forEach((choice) => {
									choice = {
										id: uuidv4(),
										text: choice.text,
										color: choice.color,
									};
									choices.push(choice);
								});
							}
							var quest = {
								questId: uuidv4(),
								question: question.question,
								type: question.type,
								order: sortOrder,
								isSignatureRequired:
									question.isSignatureRequired == undefined
										? false
										: question.isSignatureRequired,
								isRequired:
									question.isRequired == undefined
										? false
										: question.isRequired,
								isTagRequired:
									question.isTagRequired == undefined
										? false
										: question.isTagRequired,
								isMultipleChoice:
									question.isMultipleChoice == undefined
										? false
										: question.isMultipleChoice,
								isLocationRequired:
									question.isLocationRequired == undefined
										? false
										: question.isLocationRequired,
								isImageRequired:
									question.isImageRequired == undefined
										? false
										: question.isImageRequired,
								choices: choices,
							};
						}

						section.section.questions.push(quest);

						context.emit(
							"addQuestion",
							toPageId,
							toSectionId,
							quest
						);
					}
				}
			});
		};

		const copySection = (event, pageId, sectionId) => {
			preview.value.map((page) => {
				if (page.page.id == pageId) {
					if (page.page.sections != undefined) {
						var section = page.page.sections.find(
							(section) => section.section.id === sectionId
						);
						if (section != undefined) {
							var sortOrder = 100;
							page.page.sections.sort(function (a, b) {
								var order1 = a.section.order;
								var order2 = b.section.order;
								return order1 < order2
									? -1
									: order1 > order2
									? 1
									: 0;
							});

							if (page.page.sections.length > 0) {
								var order =
									page.page.sections[
										page.page.sections.length - 1
									].section.order;
								sortOrder = order + 100;
							}

							var newSection = {
								section: {
									id: uuidv4(),
									sectionTitle: section.section.sectionTitle,
									order: sortOrder,
									repeat: section.section.repeat,
									questions: [],
								},
							};
							var questions = [];

							for (const question of section.section.questions) {
								var choices = [];
								if (question.choices != undefined) {
									question.choices.forEach((choice) => {
										choice = {
											id: uuidv4(),
											text: choice.text,
											color: choice.color,
										};
										choices.push(choice);
									});
								}
								var quest = {
									questId: uuidv4(),
									question: question.question,
									type: question.type,
									order: question.order,
									isSignatureRequired:
										question.isSignatureRequired ==
										undefined
											? false
											: question.isSignatureRequired,
									isRequired:
										question.isRequired == undefined
											? false
											: question.isRequired,
									isTagRequired:
										question.isTagRequired == undefined
											? false
											: question.isTagRequired,
									isMultipleChoice:
										question.isMultipleChoice == undefined
											? false
											: question.isMultipleChoice,
									isLocationRequired:
										question.isLocationRequired == undefined
											? false
											: question.isLocationRequired,
									isImageRequired:
										question.isImageRequired == undefined
											? false
											: question.isImageRequired,
									choices: choices,
								};
								newSection.section.questions.push(quest);
							}
							page.page.sections.push(newSection);
							context.emit(
								"addSection",
								page.page.id,
								newSection
							);
						}
					}
				}
			});
		};

		const copyQuestion = (event, pageId, sectionId, question) => {
			preview.value.map((page) => {
				if (page.page.sections != undefined) {
					var section = page.page.sections.find(
						(section) => section.section.id === sectionId
					);
					if (section != undefined) {
						//get Order
						section.section.questions.sort(function (a, b) {
							var order1 = a.question.order;
							var order2 = b.question.order;
							return order1 < order2
								? -1
								: order1 > order2
								? 1
								: 0;
						});
						var sortOrder = 1;

						if (section.section.questions.length > 0) {
							var order =
								section.section.questions[
									section.section.questions.length - 1
								].order;
							sortOrder = order + 1;
						}
						var choices = [];
						if (question.choices != undefined) {
							question.choices.forEach((choice) => {
								choice = {
									id: uuidv4(),
									text: choice.text,
									color: choice.color,
								};
								choices.push(choice);
							});
						}

						var quest = {
							questId: uuidv4(),
							question: question.question,
							type: question.type,
							order: sortOrder,
							isSignatureRequired:
								question.isSignatureRequired == undefined
									? false
									: question.isSignatureRequired,
							isRequired:
								question.isRequired == undefined
									? false
									: question.isRequired,
							isMultipleChoice:
								question.isMultipleChoice == undefined
									? false
									: question.isMultipleChoice,
							isTagRequired:
								question.isTagRequired == undefined
									? false
									: question.isTagRequired,
							isLocationRequired:
								question.isLocationRequired == undefined
									? false
									: question.isLocationRequired,
							isImageRequired:
								question.isImageRequired == undefined
									? false
									: question.isImageRequired,
							choices:
								question.choices == undefined ? [] : choices,
						};
						if (templateService.getIsUpdate()) {
							quest.isUpdate = true;
						}
						section.section.questions.push(quest);
						context.emit(
							"addQuestion",
							page.page.id,
							sectionId,
							quest
						);
					}
				}
			});
		};

		const selectQuestion = (event, pageId, sectionId, question) => {
			console.log("selected");
			var targetId = event.currentTarget.id;

			console.log("targetId", targetId);

			context.emit("callback", {
				type: targetId,
				page: pageId,
				section: sectionId,
				question: question,
			});
		};

		const deleteQuestion = (event, pageId, sectionId, questionId) => {
			var targetId = event;
			preview.value.map((page) => {
				if (page.page.id == pageId) {
					page.page.sections.map((section) => {
						if (section.section.id == sectionId) {
							var questions = section.section.questions;
							var index = questions.findIndex(
								(item) => item.questId == questionId
							);
							if (index != -1) {
								questions.splice(index, 1);
							}
						}
					});
				}
			});

			if (templateService.getIsUpdate()) {
				context.emit("deleteData", {
					type: targetId,
					page: pageId,
					section: sectionId,
					question: questionId,
				});
			}
		};

		const deleteSection = (event, pageId, sectionId) => {
			var targetId = event;
			if (templateService.getIsUpdate()) {
				context.emit("deleteData", {
					type: targetId,
					page: pageId,
					section: sectionId,
				});
			}

			preview.value.map((page) => {
				if (page.page.id == pageId) {
					var sections = page.page.sections;
					var index = sections.findIndex(
						(item) => item.section.id == sectionId
					);
					if (index != -1) {
						sections.splice(index, 1);
					}
				}
			});
		};

		const deletePage = (event, pageId) => {
			var targetId = event;
			if (templateService.getIsUpdate()) {
				context.emit("deleteData", {
					type: targetId,
					page: pageId,
				});
			}

			var index = preview.value.findIndex(
				(item) => item.page.id == pageId
			);
			if (index != -1) {
				preview.value.splice(index, 1);
			}
		};

		const hideSections = () => {
			if (hidden.value == true) {
				hidden.value = false;
			} else {
				hidden.value = true;
			}
		};

		const hideQuestions = () => {
			if (hiddenQues.value == true) {
				hiddenQues.value = false;
			} else {
				hiddenQues.value = true;
			}
		};

		const deleteAlert = ref(false);
		const errMessage = ref(null);
		var pageIdToDelete = "",
			sectionIdToDelete = "",
			questIdToDelete = "";
		var event = "";

		const deleteQuest = (e, pageId, sectionId, questId) => {
			deleteAlert.value = true;
			pageIdToDelete = pageId;
			(sectionIdToDelete = sectionId), (questIdToDelete = questId);
			event = e.currentTarget.id;
		};

		const deleteSect = (e, pageId, sectionId) => {
			deleteAlert.value = true;
			pageIdToDelete = pageId;
			(sectionIdToDelete = sectionId), (event = e.currentTarget.id);
		};

		const deletePg = (e, pageId) => {
			deleteAlert.value = true;
			pageIdToDelete = pageId;
			event = e.currentTarget.id;
		};

		const confirmDelete = async () => {
			try {
				if (event == "question") {
					deleteQuestion(
						event,
						pageIdToDelete,
						sectionIdToDelete,
						questIdToDelete
					);
				} else if (event == "section") {
					deleteSection(event, pageIdToDelete, sectionIdToDelete);
				} else if (event == "page") {
					deletePage(event, pageIdToDelete);
				}
			} catch (e) {
				errMessage.value = e.message;
			}

			if (!errMessage.value) {
				deleteAlert.value = false;
			}
		};

		const stripTags = (text) => {
			text = text.replace(/<img[^>]*>/gm, ""); // Remove image tags
			return text.replace(/<[^>]*>?/gm, ""); // Remove other HTML tags
		};

		// click to clear error
		const clearError = () => {
			errMessage.value = null;
			deleteAlert.value = false;
		};

		// Show random quotes
		const quotes = [
			{
				id: 0,
				quote: "The most effective way to do it, is to do it",
				author: "Amelia Earhart",
			},
			{
				id: 1,
				quote: "An ounce of prevention is worth a pound of cure",
				author: "Benjamin Franklin",
			},
			{
				id: 2,
				quote: "It takes leadership to improve safety",
				author: "Jackie Stuart",
			},
			{
				id: 3,
				quote: "Luck has nothing to do with it",
				author: "Serena Williams",
			},
			{
				id: 4,
				quote: "Efficiency is doing things right. Effectiveness is doing the right things.",
				author: "Peter Drucker",
			},
			{
				id: 5,
				quote: "Continuous improvement is better than delayed perfection",
				author: "Mark Twain",
			},
			{
				id: 6,
				quote: "Don't find fault, find a remedy.",
				author: "Henry Ford",
			},
			{
				id: 7,
				quote: "The best safety device is a safe attitude.",
				author: "W. Edwards Deming",
			},
			{
				id: 8,
				quote: "Safety is not a cost, it's an investment.",
				author: "Mario Andretti",
			},
			{
				id: 9,
				quote: "There is no such thing as an accident. Every accident is a preventable event.",
				author: "Sam Walton",
			},
			{
				id: 20,
				quote: "Empower your team. Trust them. Let them go.",
				author: "Simon Sinek",
			},
		];

		const randomQuote = Math.floor(quotes.length * Math.random());

		return {
			deleteAlert,
			errMessage,
			deleteQuest,
			confirmDelete,
			clearError,
			updatedata,
			preview,
			startDrag,
			onDrop,
			selectPage,
			selectSection,
			selectQuestion,
			isEdit,
			deleteQuestion,
			deleteSection,
			deletePage,
			startQuestionDrag,
			onQuestionDrop,
			copyQuestion,
			startPageDrag,
			onPagedrop,
			hidden,
			hideSections,
			hiddenQues,
			hideQuestions,
			copySection,
			deleteSect,
			deletePg,
			copySectionToPageShowDropDown,
			copySectionToPage,
			copyPage,
			quotes,
			randomQuote,
			copyQuestionToPageShowDropDown,
			copyQuestionToPage,
			stripTags,
		};
	},
};
</script>

<style lang="scss" scoped>
.t-preview {
	top: 0;
	right: 0;
	bottom: 0px;
	overflow: auto;

	@include respond(large) {
		width: 45%;
		top: 0;
		position: fixed;
	}

	::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 7px;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: rgba(0, 0, 0, 0.5);
		box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
	}

	.preview-container {
		min-height: 100%;
		padding: 2em 1em 45vh;
		width: 100%;
	}

	.page {
		background-color: $rm-grey;
		margin-top: 20px;
		font-weight: bold;
	}

	.section {
		background-color: $rm-blue;
		color: white;
		font-weight: bold;
		margin-top: 5px;
	}

	.section-anchor-link {
		color: white;
	}

	.questions-container {
		display: flex;
		flex-direction: column;
		gap: 0.5em;
		margin-bottom: 10px;
	}

	.ques {
		background-color: white;
		cursor: move; /* fallback if grab cursor is unsupported */
		cursor: grab;
		cursor: -moz-grab;
		cursor: -webkit-grab;
		&:hover {
			border: 3px solid $rm-pink;
		}
	}

	.ques:active {
		background-color: $rm-grey;
		border: none;
		.preview-row_content {
			.preview-row_right-icons {
				color: $rm-grey;
			}
		}
	}

	.preview-row {
		border-radius: $border-radius;

		.preview-row_content {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			padding: 1em 0.8em;
			transition: padding 0.2s;
			&:hover {
				padding: 1.2em 0.8em;
			}

			.preview-row_left-icon {
				width: 40px;
				text-align: center;
				* {
					font-size: 1em;
				}
			}

			.required {
				color: $rm-danger;
			}

			.preview-row_text {
				width: inherit;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				margin: 0 1.2em 0 0.8em;
			}

			.preview-row_right-icons {
				display: flex;
				align-items: center;
				gap: 1em;
				* {
					cursor: pointer;
				}
				*:hover {
					color: $rm-pink;
				}
			}
		}
	}

	.drop-zone {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 0.5em;
	}

	.grip {
		cursor: move; /* fallback if grab cursor is unsupported */
		cursor: grab;
		cursor: -moz-grab;
		cursor: -webkit-grab;
		&:hover {
			color: $rm-pink;
		}
	}
	.grip:active {
		background-color: transparent;
		cursor: grabbing;
		cursor: -moz-grabbing;
		cursor: -webkit-grabbing;
	}
	.grip:target {
		background-color: transparent;
		cursor: grabbing;
		cursor: -moz-grabbing;
		cursor: -webkit-grabbing;
	}

	.drag-el:nth-last-of-type(1) {
		margin-bottom: 0;
	}

	// Empty preview styles
	.empty-preview {
		//  position: relative;
		position: absolute;
		display: flex;
		flex-direction: column;
		//  height: 100%;
		height: 50vh;
		width: 100%;
		justify-content: center;
		align-items: center;
		// padding: 0 4em 0 8em;
		padding: 0 1em 0 4em;

		@include respond(small) {
			padding: 0 3em 0 6em;
		}
		@include respond(medium) {
			height: 100vh;
		}
		@include respond(large) {
			padding: 1em 4em 0 8em;
		}
	}
	.quote-text {
		font-weight: 600;
		// line-height: 1.35em;
		line-height: 1.15em;
		//   font-size: 2.7em;
		font-size: 2.3em;

		@include respond(large) {
			line-height: 1.35em;
			font-size: 2.7em;
		}
	}
	.quote-author {
		font-size: 1.5em;
		color: $rm-pink;
	}
	.quote-content {
		// max-width: 330px;
		width: 100%;
	}
	.quote {
		position: absolute;
		margin-top: -1.2em;
		//  margin-left: -3.8em;
		margin-left: -2.8em;
		z-index: 100;
		@include respond(medium) {
			margin-left: -3.2em;
		}
		@include respond(medium) {
			margin-top: -1.2em;
			margin-left: -3.8em;
			z-index: 100;
		}
		.quote-icon {
			// font-size: 3em;
			font-size: 2em;
			color: $rm-pink;

			@include respond(medium) {
				font-size: 2.5em;
			}
			@include respond(large) {
				font-size: 3em;
			}
		}
	}
	.preview-graphic {
		width: 100%;
	}
	.bounce-enter-active {
		animation: bounce-in 0.5s;
	}
	.bounce-leave-active {
		animation: bounce-in 0.5s reverse;
	}
	@keyframes bounce-in {
		0% {
			transform: scale(0);
		}
		50% {
			transform: scale(1.02);
		}
		100% {
			transform: scale(1);
		}
	}
	.bounce2-enter-active {
		animation: bounce-in 0.5s;
	}
	.bounce2-leave-active {
		animation: bounce-in 0.5s reverse;
	}
	@keyframes bounce2-in {
		0% {
			transform: scale(0);
		}
		50% {
			transform: scale(1.02);
		}
		100% {
			transform: scale(1);
		}
	}
}
</style>
