class templateService {
  constructor() {
    if (!templateService.instance) {
      this._data = {
        selectedEquipments: [],
        selectedUser: [],
        templateData: [],
        pageData: [],
        isUpdate: false,
        selectedLocations: [],
        isTemplatePublicUser: false,
        isTemplatePublicLocation: false,
        selectedTemplateId: "",
        usersForCompletedChecklist: [],
        sharedCompletedChecklistWithPeople: [],
      };
      console.log("got new instance");
    } else {
      console.log("got old instance");
    }
  }

  setEquipments(data) {
    this._data.selectedEquipments = data;
  }

  getEquipments() {
    return this._data.selectedEquipments;
  }

  setUsers(data) {
    this._data.selectedUser = data;
  }

  getUsers() {
    return this._data.selectedUser;
  }

  //Used  for Link User Component
  setUsersForCompletedChecklist(data) {
    this._data.usersForCompletedChecklist = data;
  }

  //Used  for Link User Component
  getUsersForCompletedChecklist() {
    return this._data.usersForCompletedChecklist;
  }

  setSharedWithPeple(data) {
    this._data.sharedCompletedChecklistWithPeople = data;
  }

  getSharedWithPeple() {
    return this._data.sharedCompletedChecklistWithPeople;
  }

  setTemplate(template) {
    this._data.template = template;
  }

  getTemplate() {
    return this._data.template;
  }

  setTemplateId(templateId) {
    this._data.selectedTemplateId = templateId;
  }

  getTemplateId() {
    return this._data.selectedTemplateId;
  }

  setPages(template) {
    this._data.template = template;
  }

  getPages() {
    return this._data.template;
  }

  setIsUpdate(update) {
    this._data.isUpdate = update;
  }

  getIsUpdate() {
    return this._data.isUpdate;
  }

  setLocations(locations) {
    this._data.selectedLocations = locations;
  }

  getLocations() {
    return this._data.selectedLocations;
  }

  setTemplatePublicLocation(value) {
    this._data.isTemplatePublicLocation = value;
  }

  getTemplatePublicLocation() {
    return this._data.isTemplatePublicLocation;
  }

  setTemplatePublicUser(value) {
    this._data.isTemplatePublicUser = value;
  }

  getTemplatePublicUser() {
    return this._data.isTemplatePublicUser;
  }
}

export default new templateService();
