<template>
    <Alert :message="saveError" v-if="saveError" />
    <div class="spacer-20"></div>
    <div class="left-form-container">
        <form class="t-form">
            <div class="t-form-question">
                <div class="icon-container">
                    <fa class="question-icon" icon="file-alt" />
                </div>
                <div class="t-input">
                    <textarea
                        class="t-question"
                        placeholder="Section Title..."
                        v-model="pageSection"
                    />
                </div>
            </div>

            <div class="t-settings">
                <div
                    class="settings-btn"
                    v-bind:class="{ selected: IsRepeat }"
                    @click="handleIsRepeatSubmit"
                >
                    Repeat Section
                    <fa class="nav-icon" v-if="IsRepeat" icon="check-circle" />
                </div>
            </div>

            <div class="save-item">
                <vue-select
                    v-if="showDropdown == true"
                    v-model="pagename"
                    :options="options"
                    label-by="name"
                    clear-on-select
                    clear-on-close
                    close-on-select
                    placeholder="Select a page"
                ></vue-select>
                <div class="t-btn">
                    <button class="btn btn-primary" v-on:click.prevent="submit">Save</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { onMounted } from "@vue/runtime-core";
import { ref } from "vue";
import Alert from "../../Alert.vue";

export default {
    components: { Alert },
    props: ["pages", "selectedSection"],
    emits: ["pageSection"],
    setup(props, context) {
        const IsRepeat = ref(false);
        const preview = ref();
        preview.value = props.pages;

        const pagename = ref({});
        const saveError = ref();

        const pageSection = ref("");
        const options = ref([]);

        const showDropdown = ref(true);

        const getPagesOption = (previewData) => {
            previewData.value.map((item) => {
                options.value.push({
                    id: item.page.id,
                    name: item.page.pageTitle,
                });
            });
        };

        const handleIsRepeatSubmit = () => {
            IsRepeat.value = !IsRepeat.value;
        };

        onMounted(() => {
            getPagesOption(preview);
            if (props.selectedSection == undefined) {
                showDropdown.value = true;
            } else {
                showDropdown.value = false;
                preview.value.map((item) => {
                    if (item.page.id == props.selectedSection.pageId) {
                        pagename.value = {
                            id: item.page.id,
                            title: item.page.pageTitle,
                        };
                    }

                    item.page.sections.map((section) => {
                        if (
                            section.section.id ==
                            props.selectedSection.sectionId
                        ) {
                            pageSection.value = section.section.sectionTitle;
                            IsRepeat.value = section.section.repeat;
                        }
                    });
                });
            }
        });

        const submit = async () => {
            saveError.value = null;
            if (pageSection.value == "") {
                saveError.value = "A section title is required";
                return;
            }

            if (props.selectedSection == undefined) {
                if (pagename.value.id == undefined) {
                    saveError.value = "Select a page";
                    return;
                }
                context.emit("pageSection", {
                    isSubmit: true,
                    sectionTitle: pageSection.value,
                    page: pagename.value.id,
                    sectionId: "",
                    repeat: IsRepeat.value,
                });
            } else {
                context.emit("pageSection", {
                    isSubmit: false,
                    sectionTitle: pageSection.value,
                    page: pagename.value.id,
                    sectionId: props.selectedSection.sectionId,
                    repeat: IsRepeat.value,
                });
            }
        };

        return {
            pageSection,
            submit,
            pagename,
            options,
            showDropdown,
            IsRepeat,
            handleIsRepeatSubmit,
            saveError,
        };
    },
};
</script>

<style lang="scss" scoped>
</style>