<template>
<div>
        <Multiselect
            v-model="value"
            mode="tags"
            label="name"
            trackBy="name"
            placeholder="Select your characters"
            :options="options"
            :searchable="false"/>
          <button @click="handleSubmit">Select</button>
        </div>
      
</template>

<script>
import { computed, onMounted } from "@vue/runtime-core";
import equipmentCollection from "../../../composables/equipmentsCollection";
import { ref } from "@vue/reactivity";
import Multiselect from "@vueform/multiselect";
import { useRouter } from "vue-router";
import templateService from "../../../composables/templateService"

export default {
  name: "LinkEquipment",
  components: {
    Multiselect,
  },

  setup(props, context) {
    const { getEquipment, equipments, error } = equipmentCollection();
    const router = useRouter()
    const value = ref([]);

    getEquipment();

    const options = computed(() => {
      console.log(equipments.value.value, equipments.value.value);
      if (equipments.value.value != null) {
        equipments.value.value.map((equipment) => {
          equipment.value = equipment.id;
        });
      }

      return equipments.value.value;
    });


    const handleSubmit = ()=>{
      console.log(value,"selected value");
      templateService.setEquipments(value);
    
    }

    return { value, options, equipments,handleSubmit };
  },
};
</script>

<style   scoped>

</style>



