<template>
    <Alert :message="saveError" v-if="saveError" />
    <div class="spacer-20"></div>
    <form class="t-form">
        <div class="t-form-question">
            <div class="icon-container">
                <fa v-if="buildertype == '5'" class="question-icon" icon="calendar-alt" />
                <fa v-else-if="buildertype == '6'" class="question-icon" icon="image" />
                <fa v-else-if="buildertype == '7'" class="question-icon" icon="map-marked-alt" />
                <fa v-else-if="buildertype == '8'" class="question-icon" icon="file-signature" />
                <fa v-else-if="buildertype == '9'" class="question-icon" icon="check" />
                <fa v-else-if="buildertype == '12'" class="question-icon" icon="tools" />
                 <fa v-else-if="buildertype == '13'" class="question-icon" icon="users" />
                <fa v-else class="question-icon" icon="align-left" />
            </div>
            <div class="t-input">
                <textarea
                    rows="2"
                    type="text"
                    class="t-question"
                    placeholder="Question text..."
                    v-model="templateQuestion"
                />
            </div>
        </div>

        <div class="t-settings">
            <div
                class="settings-btn"
                v-bind:class="{ selected: IsRequired }"
                @click="handleIsRequiredSubmit"
            >
                Required field
                <fa class="nav-icon" v-if="IsRequired" icon="check-circle" />
            </div>

            <div
                class="settings-btn"
                v-bind:class="{ selected: isTagRequired }"
                @click="handleIsTagRequiredSubmit"
                v-if="buildertype == '2'"
            >
                Tag Response
                <fa class="nav-icon" v-if="isTagRequired" icon="check-circle" />
            </div>
        </div>

        <div class="save-item">
            <vue-select
                v-if="showDropdown"
                v-model="pagename"
                :options="options"
                label-by="name"
                close-on-select
                @selected="getSectionsForPage"
                placeholder="Select page"
            ></vue-select>
            <vue-select
                v-if="showDropdown==true"
                v-model="sectionName"
                :options="sectionOptions"
                label-by="name"
                close-on-select
                @selected="selectedOption"
                placeholder="Select section"
            ></vue-select>

            <div class="t-btn">
                <button class="btn btn-primary" v-on:click.prevent="handleSubmit">Save</button>
            </div>
        </div>
    </form>
</template>

<script>
import { ref } from "vue";
import { onMounted, onUnmounted, computed } from "@vue/runtime-core";
import { v4 as uuidv4 } from "uuid";
import PageLoading from "../../PageLoading.vue";
import Alert from "../../Alert.vue";

export default {
    components: { PageLoading, Alert },
    props: ["pages", "buildertype", "selectedQuestion"],
    emits: ["textQuetion"],
    setup(props, context) {
        const preview = ref();
        const buildertype = ref("");
        buildertype.value = props.buildertype;
        preview.value = props.pages;
        const IsRequired = ref(false);
        const isTagRequired = ref(false);
        const templateQuestion = ref("");
        const sectionName = ref();
        const pagename = ref({});

        const options = ref([]);
        const sectionOptions = ref([]);
        const showDropdown = ref(true);
        const saveError = ref();

        const handleIsRequiredSubmit = () => {
            IsRequired.value = !IsRequired.value;
        };

        const handleIsTagRequiredSubmit = () => {
            isTagRequired.value = !isTagRequired.value;
        };

        const handleSubmit = async () => {
            saveError.value = null;
            if (templateQuestion.value == "") {
                saveError.value = "Question text is required";
                return;
            }
            if (!pagename.value || !sectionName.value) {
                saveError.value = "Select a page & section";
                return;
            }

            var questionType = "Text";
            var isImageRequired = false;
            var isLocationRequired = false;
            var isSignatureRequired = false;

            if (buildertype.value == "5") {
                questionType = "DateTime";
            }

            if (buildertype.value == "6") {
                isImageRequired = true;
                questionType = "Image";
            }

            if (buildertype.value == "7") {
                isLocationRequired = true;
                questionType = "Location";
            }

            if (buildertype.value == "8") {
                isSignatureRequired = true;
                questionType = "Signature";
            }

            if (buildertype.value == "9") {
                questionType = "Checkbox";
            }

            if (buildertype.value == "12") {
                questionType = "Equipment";
            }

             if (buildertype.value == "13") {
                questionType = "People";
            }

            var id = uuidv4();
            var isNew = true;
            if (props.selectedQuestion != undefined) {
                id = props.selectedQuestion.questionId;
                isNew = false;
            }

            var value = "";

            var questionobj = {
                pageid: pagename.value.id,
                sectionId: sectionName.value.id,
                question: {
                    questId: id,
                    type: questionType,
                    question: templateQuestion.value,
                    isRequired: IsRequired.value,
                    isTagRequired: isTagRequired.value,
                    isMultipleChoice: false,
                    isImageRequired: isImageRequired,
                    isLocationRequired: isLocationRequired,
                    isSignatureRequired: isSignatureRequired,
                    value: value,
                },
            };
            // if (templateService.getIsUpdate()) {
            //      questionobj.question.isUpdate = true;
            // }
            questionobj.isNew = isNew;
            if (props.selectedQuestion != undefined) {
                questionobj.prevSectionId = props.selectedQuestion.sectionId;
            }

            if (
                props.selectedQuestion == undefined ||
                (props.selectedQuestion != undefined &&
                    props.selectedQuestion.isUpdate == false)
            ) {
                context.emit("textQuetion", questionobj);
            } else {
                // await updateQuestion(
                //      props.selectedQuestion.pageId,
                //      props.selectedQuestion.sectionId,
                //      props.selectedQuestion.questionId,
                //      {
                //           question: templateQuestion.value,
                //           isRequired: IsRequired.value,
                //      },
                //      sectionName.value.id,
                //      templateService.getTemplateId()
                // );
                context.emit("textQuetion", questionobj);
            }
        };

        const getSectionsForPage = computed(() => {
            if (pagename.value) {
                getSections();
            }
        });

        const getSections = () => {
            sectionOptions.value = [];
            preview.value.map((item) => {
                if (item.page.id == pagename.value.id) {
                    if (item.page.sections != undefined) {
                        item.page.sections.map((section) => {
                            sectionOptions.value.push({
                                id: section.section.id,
                                name: section.section.sectionTitle,
                            });
                        });
                    }
                }
            });
        };

        const selectedOption = computed(() => {
            if (sectionName.value) {
                // console.log(sectionName.value);
            }
        });

        const getPagesOption = (previewData) => {
            previewData.value.map((item) => {
                options.value.push({
                    id: item.page.id,
                    name: item.page.pageTitle,
                });
            });
        };

        onMounted((async) => {
            console.log(buildertype.value);
            getPagesOption(preview);
            if (props.selectedQuestion != undefined) {
                prepopulateData();
                showDropdown.value = false;
            }
        });

        const prepopulateData = () => {
            var filteredPage = options.value.filter((item) => {
                return item.id == props.selectedQuestion.pageId;
            });

            pagename.value = filteredPage[0];
            getSections();
            var filteredSection = sectionOptions.value.filter((item) => {
                return (item.id = props.selectedQuestion.sectionId);
            });
            sectionName.value = filteredSection[0];

            var page = preview.value.filter((item) => {
                return item.page.id == props.selectedQuestion.pageId;
            });
            var pagedata = page[0];

            var section = pagedata.page.sections.filter((item) => {
                return item.section.id == props.selectedQuestion.sectionId;
            });
            var sectionData = section[0];
            var question = sectionData.section.questions.filter((item) => {
                return item.questId == props.selectedQuestion.questionId;
            });

            var quesData = question[0];

            templateQuestion.value = quesData.question;
            IsRequired.value = quesData.isRequired;
            isTagRequired.value =
                quesData.isTagRequired == undefined
                    ? false
                    : quesData.isTagRequired;
        };

        onUnmounted((async) => {});

        return {
            handleSubmit,
            IsRequired,
            isTagRequired,
            templateQuestion,
            sectionName,
            options,
            pagename,
            getSectionsForPage,
            sectionOptions,
            handleIsRequiredSubmit,
            handleIsTagRequiredSubmit,
            selectedOption,
            showDropdown,
            buildertype,
            saveError,
        };
    },
};
</script>