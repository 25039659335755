<template>
    <div class="modal">
        <div class="modal-container">
            <div class="rm-form modal-content tile">
                <div class="modal-header">
                    <div class="left">
                        <div class="modal-title">
                            <h3 v-if="modalType == 'linkuser'">Share template</h3>
                            <h3 v-else>Update</h3>
                        </div>
                    </div>
                    <div class="right">
                        <div class="btn-icon muted" @click="close">
                            <div class="text">Close</div>
                            <fa icon="times" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <!-- <NewPage @newPage="addPage"  v-if="modalType =='page'" :selectedPage="pageData" :pages="pages"/>
          <Section  @pageSection="addSection"  :pages="pages"  v-if="modalType =='section'" :selectedSection="pageData" />
                    <Question   @textQuetion="addQuestion" :pages="pages" v-if="modalType =='question'"  :selectedQuestion="pageData" />-->
                    <LinkUser v-if="modalType == 'linkuser'" />
                    <LinkLocation v-if="modalType =='linklocation'" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NewPage from "./NewPage.vue";
import Section from "./Section.vue";
import Question from "./TextQuestion.vue";
import LinkUser from "./LinkUser.vue";
import LinkLocation from "./LinkLocation.vue";
import { onMounted, onUnmounted, onBeforeMount } from "@vue/runtime-core";
import templateService from "../../../composables/templateService";
import { ref } from "vue";
export default {
    components: { NewPage, Section, Question, LinkUser, LinkLocation },
    props: ["pages", "params"],
    emits: ["previewData", "close"],
    setup(props, context) {
        const modalType = ref("");
        const pageId = ref("");
        const sectionId = ref("");
        const questionId = ref("");
        const pages = ref();
        const pageData = ref();

        pages.value = props.pages;

        const addPage = (pageTitle) => {
            console.log("xx", pageTitle);

            console.log(props.pages);
            props.pages.map((page) => {
                console.log(page, pageId.value, page.page.id);
                if (page.page.id == pageId.value) {
                    page.page.pageTitle = pageTitle;
                }
            });

            context.emit("previewData", [props.pages, null]);
        };

        const addQuestion = (data) => {
            console.log(data.question, "dataobj");
            props.pages.map((page) => {
                console.log(page, pageId.value, page.page.id);
                if (page.page.id == pageId.value) {
                    page.page.sections.map((section) => {
                        if (section.section.id == sectionId.value) {
                            section.section.questions.map((question) => {
                                console.log("question", question);
                                if (question.questId == questionId.value)
                                    question.question = data.question.question;
                            });
                        }
                    });
                }
            });
            console.log(props.pages, "updated questions");
            context.emit("previewData", [props.pages, null]);
        };

        const addSection = (data) => {
            props.pages.map((page) => {
                console.log(page, pageId.value, page.page.id);
                if (page.page.id == pageId.value) {
                    page.page.sections.map((section) => {
                        if (section.section.id == sectionId.value) {
                            section.section.sectionTitle = data.sectionTitle;
                        }
                    });
                }
            });
            context.emit("previewData", [props.pages, null]);
        };

        onBeforeMount(async () => {
            console.log("params", props.params);
            var params = props.params;
            var isUpdate = templateService.getIsUpdate();

            modalType.value = params.modalType;

            if (modalType.value == "page") {
                pageId.value = params.pageId;
                pageData.value = {
                    isUpdate: isUpdate ? true : false,
                    pageId: pageId.value,
                };
            } else if (modalType.value == "section") {
                pageId.value = params.pageId;
                sectionId.value = params.sectionId;
                pageData.value = {
                    isUpdate: isUpdate ? true : false,
                    pageId: pageId.value,
                    sectionId: sectionId.value,
                };
            } else if (modalType.value == "question") {
                pageId.value = params.pageId;
                sectionId.value = params.sectionId;
                questionId.value = params.questionId;
                pageData.value = {
                    isUpdate: isUpdate ? true : false,
                    pageId: pageId.value,
                    sectionId: sectionId.value,
                    questionId: questionId.value,
                };
            }
        });

        const close = () => {
            context.emit("close");
        };

        return {
            addPage,
            modalType,
            close,
            addSection,
            pages,
            addQuestion,
            pageData,
        };
    },
};
</script>

<style scoped>
/* .modal .modal-container .modal-content .modal-header {
     box-shadow: none;
     margin-left: -40px;
     margin-right: -40px;
     padding: 0px 40px 1rem;
}

.modal-title {
     display: flex;
     justify-content: flex-start;
     align-items: center;
     width: 35ch;
     overflow: hidden;
     padding: 0 1rem;
} */
</style>