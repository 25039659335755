<template>
    <div class="dropdown-container">
        <div class="dropdown">
            <div class="dropdown-options">
                <div
                    class="select-option"
                    :class="{selected: selectedIcon == 'select'}"
                    @click="handleSelection('select')"
                >
                    <fa icon="tasks" />
                    <p>Multi-Choice</p>
                </div>

                <div
                    class="select-option"
                    :class="{selected: selectedIcon == 'checkbox'}"
                    @click="handleSelection('checkbox')"
                >
                    <fa icon="check-circle" />
                    <p>Checkbox</p>
                </div>

                <div
                    class="select-option"
                    :class="{selected: selectedIcon == 'text'}"
                    @click="handleSelection('text')"
                >
                    <fa icon="align-left" />
                    <p>Text</p>
                </div>

                <div
                    class="select-option"
                    :class="{selected: selectedIcon == 'date'}"
                    @click="handleSelection('date')"
                >
                    <fa icon="calendar-alt" />
                    <p>Date &amp; Time</p>
                </div>

                <div
                    class="select-option"
                    :class="{selected: selectedIcon == 'image'}"
                    @click="handleSelection('image')"
                >
                    <fa icon="image" />
                    <p>Image</p>
                </div>

                <div
                    class="select-option"
                    :class="{selected: selectedIcon == 'location'}"
                    @click="handleSelection('location')"
                >
                    <fa icon="map-marked-alt" />
                    <p>Location</p>
                </div>

                <div
                    class="select-option"
                    :class="{selected: selectedIcon == 'signature'}"
                    @click="handleSelection('signature')"
                >
                    <fa icon="file-signature" />
                    <p>Signature</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";

export default {
    name: "ChecklistIconDropdown",
    props: ["selectedIcon"],
    emits: ["selection"],
    setup(props, context) {
        const selectedIcon = ref(null);

        onMounted(() => {
            selectedIcon.value = props.selectedIcon;
            console.log(selectedIcon.value);
        });

        const handleSelection = (value) => {
            selectedIcon.value = value;
            context.emit("selection", selectedIcon.value, props.id);
        };

        return { selectedIcon, handleSelection };
    },
};
</script>

<style lang="scss" scoped>
.dropdown-container {
    position: absolute;
    // z-index: 9999;
    .dropdown {
        position: absolute;
        // width: fit-content;
        // border: 3px solid red;
        // background-color: white;
        .dropdown-options {
            display: flex;
            flex-direction: column;
            position: absolute !important;
            top: -150px;
            left: -190px;
            width: max-content;
            background-color: white;
            // z-index: 9999;
            box-shadow: $box-shadow;
            border: 2px solid $rm-pink;
            border-radius: 7px;
            .select-option {
                display: flex;
                align-items: center;
                gap: 1em;
                width: 100%;
                padding: 0.8em 1em;
                // border-bottom: 1px solid $rm-light-grey;
                &:hover {
                    cursor: pointer;
                    background-color: $rm-pink;
                    color: white !important;
                }
            }

            .selected {
                background-color: $rm-pink;
                color: white !important;
            }
        }
    }
}
</style>