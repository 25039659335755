import axios from "axios";
import axiosRateLimit from "axios-rate-limit";

// Limit requests to 50 calls every 10 seconds (i.e. around 5 calls per second).
const axiosLimited = axiosRateLimit(axios, {
  maxRequests: 50,
  perMilliseconds: 10000,
});

export class CancellablePromise {
  constructor(promise, cancelSource) {
    this.promise = promise;
    this.cancelSource = cancelSource;
  }

  cancel(message) {
    this.cancelSource.cancel(message || "Request cancelled");
  }

  catch() {
    return new CancellablePromise(
      this.promise.catch.apply(this.promise, arguments),
      this.cancelSource
    );
  }

  finally() {
    return new CancellablePromise(
      this.promise.finally.apply(this.promise, arguments),
      this.cancelSource
    );
  }

  then() {
    return new CancellablePromise(
      this.promise.then.apply(this.promise, arguments),
      this.cancelSource
    );
  }
}

export function basicGet(url, queryParams, header) {
  const cancelSource = axios.CancelToken.source();

  const config = {
    cancelToken: cancelSource.token,
    ...header,
  };

  if (queryParams) {
    config.params = queryParams;
  }

  const promise = axiosLimited
    .get(url, config)
    .then((response) => response.data);

  return new CancellablePromise(promise, cancelSource);
}

export default { basicGet };
