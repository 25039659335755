<template>
    <div class="row">
        <div class="spacer-20"></div>
        <page-loading v-if="isLoading" />
        <div class="template-container" v-else>
            <form class="rm-form" @submit.prevent>
                <div class="form-info">
                    <fa class="icon" icon="question-circle" />
                    <p>Automatically schedule and carry forward data from a completed checklist.</p>
                </div>

                <div class="form-checkbox">
                    <label class="checkbox-container">
                        <input type="checkbox" v-model="scheduled" />
                        <span class="check"></span>
                        <span>Checklist schedule</span>
                    </label>
                </div>

                <div class="form-block" v-if="scheduled==true">
                    <div class="form-input">
                        <div class="input-flex">
                            <p>
                                <div class="input-header">
                                    <b>Schedule:</b>
                                </div>
                            </p>
                            <input type="number" class="number" v-model="scheduleDays" />
                            <vue-select
                                class="timeframe"
                                v-if="scheduled==true"
                                v-model="duration"
                                :options="durationOption"
                                close-on-select
                                @selected="setDurationOption"
                                placeholder="Time frame..."
                            ></vue-select>

                            <p>after a checklist is marked complete.</p>
                            <div class="input-header">
                                    <b>Data:</b>
                                </div>
                            <p>
                                
                                {{ checklistType.name }}
                            </p>
                        </div>
                    </div>
                    <div class="spacer-40"></div>
                    <div class="form-input">
                        <div class="input-flex">
                            Data to be carried forward:
                            <vue-select
                                class="checklist-type"
                                v-if="scheduled==true && checklistType"
                                v-model="checklistType"
                                :options="checklistTypeValues"
                                label-by="name"
                                close-on-select
                                @selected="setChecklistOption"
                                placeholder="Change selection..."
                            ></vue-select>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import {
    onMounted,
    onUnmounted,
    watchEffect,
    onBeforeMount,
} from "@vue/runtime-core";
import PageLoading from "../../PageLoading.vue";

export default {
    components: { PageLoading },
    name: "Schedule",
    props: ["templateData", "id"],
    emits: ["scheduleData"],
    setup(props, context) {
        const templateData = ref({});

        const isLoading = ref(false);
        const scheduled = ref(false);
        const duration = ref(null);
        const checklistType = ref({});
        const durationOption = ref(["day/s", "month/s", "year/s"]);
        const checklistTypeValues = ref([
            { id: 1, name: "None (blank checklist)" },
            { id: 2, name: "People & Equipment only" },
            { id: 3, name: "All checklist data" },
        ]);
        const checklistTypeValue = ref();
        const scheduleDays = ref();

        const setDurationOption = () => {
            console.log(duration.value);
        };

        const setChecklistOption = () => {
            console.log("type", checklistType.value);
        };

        onBeforeMount(async () => {
            console.log("Duration: ", props.templateData.scheduleDuration);
            console.log(
                "Schedule Type:",
                props.templateData.scheduleChecklistType
            );

            scheduleDays.value = props.templateData.scheduleDays;
            scheduled.value = props.templateData.IsScheduleChecklist;
            // duration.value = props.templateData.scheduleDuration == undefined? "day/s":props.templateData.scheduleDuration;
            checklistType.value =
                props.templateData.scheduleChecklistType == undefined
                    ? { id: 1, name: "None (blank checklist)" }
                    : props.templateData.scheduleChecklistType;

            if (checklistType.value.id == 3) {
                checklistTypeValue.value = "All Checklist Data.";
            } else if (checklistType.value.id == 2) {
                checklistTypeValue.value = "People & Equipment only.";
            } else {
                checklistTypeValue.value = "None (blank checklist).";
            }

            setDurationvalue();
        });

        const setDurationvalue = () => {
            if (props.templateData.scheduleDuration == 1) {
                duration.value = "day/s";
            } else if (props.templateData.scheduleDuration == 2) {
                duration.value = "month/s";
            } else {
                duration.value = "year/s";
            }
        };

        onMounted((async) => {});

        const saveData = () => {
            console.log("Schedule Save data", checklistType.value);

            context.emit("scheduleData", {
                IsScheduleChecklist: scheduled.value,
                scheduleDuration: duration.value,
                scheduleChecklistType: checklistType.value,
                scheduleDays: scheduleDays.value,
            });
        };

        onUnmounted((async) => {
            saveData();
        });

        const addLoader = () => {
            isLoading.value = true;
        };

        return {
            isLoading,
            addLoader,
            props,
            scheduled,
            duration,
            checklistType,
            durationOption,
            checklistTypeValues,
            setDurationOption,
            setChecklistOption,
            scheduleDays,
            setDurationvalue,
            checklistTypeValue,
        };
    },
};
</script>

<style lang="scss" scoped>
.template-container {
    margin-left: 1.3em;
}
.input-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    align-items: center;
    input[type="number"] {
        width: 3em;
        height: 42px;
        text-align: center !important;
        padding: 0px !important;
    }
    .timeframe {
        max-width: 135px !important;
    }
    .checklist-type {
        max-width: 280px !important;
    }
}

.input-header {
    width: 90px;
}

.form-checkbox {
    width: 100%;
    margin-bottom: 1em;
    border-bottom: 1px solid $rm-light-grey;
}
.form-info {
    display: flex;
    gap: 1em;
    color: $rm-grey;
    margin-left: 0.8em;
    padding: 1em 0 2em 0;
    font-style: italic;
    .icon {
        margin-top: 1px;
        font-size: 1.5em;
        color: $rm-grey;
    }
}
</style>
