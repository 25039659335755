<template>
    <alert :message="error" v-if="error" />
    <div class="right-content">
        <div class="page-content">
            <div class="page-detail">
                <!-- <div class="right-sub-nav">
                    <div class="nav-row"></div>
                </div>-->
                <div class="right-sub-nav fixed special-nav">
                    <div class="nav-row">
                        <div class="sub-nav-left pad-left">
                            <h3>AI Template Generator (beta)</h3>
                        </div>
                        <div class="sub-nav-right">
                            <div class="btn btn-icon white" @click="$router.go(-1)">
                                <div class="text">Close</div>
                                <fa icon="times" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="page-container">
                    <div class="spacer-50"></div>
                    <div class="split">
                        <div class="row left">
                            <div class="block">
                                <div v-if="!aiTemplate && !fromFile">
                                    <h3>Instructions</h3>
                                    <p>Enter the Checklist Title, add some Instructions and click "Generate".</p>
                                    <br />
                                    <p>
                                        <b>You must provide the Page and Sections Titles in your Instructions.</b>
                                    </p>
                                    <br />
                                    <p>
                                        Enclose Page Titles with percent signs (%Page Title%) and Section Titles with dollar signs ($Section Title$).
                                        The more detailed the instructions, the better the response.
                                    </p>
                                    <br />
                                </div>
                                <div v-if="!aiTemplate && fromFile">
                                    <h3>Instructions</h3>
                                    <p>Copy and paste a checklist from a form or list of questions.</p>
                                    <br />
                                    <p>There is a limit to the amount of text we can process of approximately 2 pages. If it's a large file, edit out any text that isn't needed.</p>
                                    <br />
                                    <p>Copy your form with Ctrl + a and then Ctrl + c. Paste it here with Ctrl + v.</p>
                                </div>
                                <div v-if="aiTemplate">
                                    <h3>Help us train this AI</h3>
                                    <p>We're gathering data to feed back to the AI model to improve the results.</p>
                                    <br />
                                    <p>
                                        If it generated a reasonable checklist that's good enough to be a useful starting point,
                                        help us out by editing any mistakes and then clicking "Good Job".
                                    </p>
                                    <br />
                                    <p>Whilst in beta, it might take a few attempts to generate a checklist.</p>
                                    <br />
                                    <br />
                                </div>

                                <div class="spacer-20"></div>
                                <h3>Disclaimer</h3>
                                <div v-if="!fromFile">
                                    <p>
                                        Only our future AI overlords know what questions will be produced (or omitted) and from where
                                        they're sourced (or not sourced).
                                    </p>
                                    <br />
                                    <p>This tool does not replace an experienced, competent Human.</p>
                                </div>
                                <div v-else>
                                    <p>This uses AI to try and figure out what is a question and what isn't. It may get it wrong, make edits, make stuff up or miss a question entirely.</p>
                                    <br />
                                    <p>Once a checklist is created, it must be checked by a competent Human.</p>
                                </div>
                            </div>
                        </div>

                        <div class="row right">
                            <div class="block" v-if="isLoading">
                                <div class="vertical-center">
                                    <page-loading />
                                </div>
                            </div>

                            <div v-if="!isLoading" class="block">
                                <div class="form-check-header">
                                    <h3 v-if="fromFile">Generate from a form</h3>
                                    <h3 v-if="!fromFile">Generate from instructions</h3>
                                    <label class="switch">
                                        <input type="checkbox" v-model="fromFile" />
                                        <span class="slider round"></span>
                                    </label>
                                </div>

                                <!-- CReate from title & instructions -->
                                <div class="openai-input" v-if="!aiTemplate && !fromFile">
                                    <form class="rm-form" @submit.prevent="getResponse">
                                        <div class="form-input">
                                            <label>Checklist Title:</label>
                                            <input
                                                type="text"
                                                v-model="title"
                                                placeholder="Checklist Title..."
                                            />
                                        </div>
                                        <div class="form-input">
                                            <label>Instructions:</label>
                                            <textarea
                                                rows="5"
                                                maxlength="350"
                                                v-model="context"
                                                placeholder="A weekly checklist to ensure that all the safety features of a vehicle are present and functioning correctly, with one page for %Inspection Details% that includes $Driver Details$ and $Vehicle Details$, and a second page for the %Inspection% that includes $Interior Checks$, $Exterior Checks$ and $Engine Checks$..."
                                            />
                                        </div>
                                        <div class="form-submit">
                                            <button class="btn btn-primary">Generate</button>
                                        </div>
                                    </form>
                                </div>

                                <!-- Create from pasting a file -->
                                <div class="openai-input" v-if="!aiTemplate && fromFile">
                                    <form class="rm-form" @submit.prevent="getResponse">
                                        <div class="form-input">
                                            <!-- <label>Instructions &amp; 'Section Titles':</label> -->
                                            <textarea
                                                rows="13"
                                                v-model="file"
                                                placeholder="Paste the contents of your form here..."
                                            />
                                        </div>
                                        <div class="form-submit">
                                            <button class="btn btn-primary">Generate</button>
                                        </div>
                                    </form>
                                </div>

                                <div class="openai-response" v-if="aiTemplate">
                                    <div class="flex-between ai-title">
                                        <h2>{{ aiTemplate.title }}</h2>
                                        <div>
                                            <div
                                                class="btn btn-icon muted"
                                                @click="clearRefs"
                                                v-if="!saving"
                                            >
                                                <div class="text">Start again</div>
                                                <fa icon="times" />
                                            </div>
                                            <div
                                                class="btn btn-icon muted"
                                                @click="saveTrainingData"
                                                v-if="!dataSaved && !saving"
                                            >
                                                <div class="text">Good Job</div>
                                                <fa icon="thumbs-up" />
                                            </div>

                                            <button
                                                class="btn btn-success"
                                                @click="generateTemplate"
                                                v-if="!saving"
                                            >Save &amp; Edit</button>
                                            <page-loading v-else />
                                        </div>
                                    </div>

                                    <p>{{ aiTemplate.description }}</p>
                                    <br />
                                    <div
                                        class="template"
                                        v-for="page, pIndex in aiTemplate.pages"
                                        :key="page"
                                        v-if="aiTemplate.pages"
                                    >
                                        <div class="page">
                                            <div
                                                class="title-edit"
                                                v-if="currentPIndex == pIndex && currentSIndex == null && isEditing"
                                            >
                                                <input class="page-input" v-model="input" />
                                                <div class="icons">
                                                    <fa
                                                        class="btn btn-icon"
                                                        icon="save"
                                                        @click="editTitle(pIndex, null)"
                                                    />
                                                </div>
                                            </div>
                                            <div class="title-edit" v-else>
                                                <h3>{{ page.page_title }}</h3>
                                                <fa
                                                    class="btn btn-icon"
                                                    icon="pen"
                                                    v-if="!isEditing"
                                                    @click="selectTitle(pIndex, page.page_title, null, null)"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            v-for="section, sIndex in page.sections"
                                            :key="section"
                                            v-if="page.sections"
                                        >
                                            <div class="section">
                                                <div
                                                    class="title-edit"
                                                    v-if="currentPIndex == pIndex && currentSIndex == sIndex && isEditing"
                                                >
                                                    <input class="section-input" v-model="input" />
                                                    <div class="icons">
                                                        <fa
                                                            class="btn btn-icon white"
                                                            icon="save"
                                                            @click="editTitle(pIndex, sIndex)"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="title-edit" v-else>
                                                    <h4>{{section.section_title}}</h4>
                                                    <fa
                                                        class="btn btn-icon white"
                                                        v-if="!isEditing"
                                                        icon="pen"
                                                        @click="selectTitle(pIndex, page.page_title, sIndex, section.section_title)"
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                v-for="question, qIndex in section.questions"
                                                :key="qIndex"
                                                v-if="section.questions"
                                            >
                                                <div
                                                    class="question"
                                                    :class="{'question-selected': isEditing && currentQuestion == `${pIndex}${sIndex}${qIndex}`}"
                                                >
                                                    <checklist-icon-dropdown
                                                        v-if="isEditing && currentQuestion == `${pIndex}${sIndex}${qIndex}`"
                                                        v-on:selection="handleSelection"
                                                        :selectedIcon="question.input_type"
                                                    />
                                                    <div class="question_text">
                                                        <div
                                                            v-if="currentQuestion != `${pIndex}${sIndex}${qIndex}`"
                                                            class="question_icon"
                                                        >
                                                            <fa
                                                                v-if="question.input_type == 'select'"
                                                                icon="tasks"
                                                            />
                                                            <fa
                                                                v-if="question.input_type == 'image'"
                                                                icon="image"
                                                            />
                                                            <fa
                                                                v-if="question.input_type == 'date' || question.input_type == 'time'"
                                                                icon="calendar-alt"
                                                            />
                                                            <fa
                                                                v-if="question.input_type == 'text' || question.input_type == 'textarea' || question.input_type == 'number' || question.input_type == 'email'"
                                                                icon="align-left"
                                                            />
                                                            <fa
                                                                v-if="question.input_type == 'radio'"
                                                                icon="check-circle"
                                                            />
                                                            <fa
                                                                v-if="question.input_type == 'checkbox'"
                                                                icon="check-circle"
                                                            />
                                                            <fa
                                                                v-if="question.input_type == 'location'"
                                                                icon="map-marked-alt"
                                                            />
                                                            <fa
                                                                v-if="question.input_type == 'signature'"
                                                                icon="file-signature"
                                                            />
                                                        </div>
                                                        <div
                                                            class="question_edit"
                                                            v-if="`${pIndex}${sIndex}${qIndex}` == currentQuestion"
                                                        >
                                                            <input
                                                                class="question-input"
                                                                v-model="input"
                                                            />
                                                            <div class="icons">
                                                                <fa
                                                                    class="btn btn-icon"
                                                                    icon="save"
                                                                    @click="editQuestion(pIndex, sIndex, qIndex)"
                                                                />
                                                            </div>
                                                            <div class="icons">
                                                                <fa
                                                                    class="btn btn-icon"
                                                                    icon="trash"
                                                                    @click="deleteQuestion(pIndex, sIndex, qIndex)"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div v-else class="space-between">
                                                            <p>{{question.question}}</p>
                                                            <fa
                                                                v-if="!isEditing"
                                                                class="btn btn-icon"
                                                                icon="pen"
                                                                @click="selectQuestion(pIndex, sIndex, qIndex, question.question)"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { projectFirestore, projectFunctions } from "../../firebase/config";
import PageLoading from "../../components/PageLoading.vue";
import templateCollection from "../../composables/templateCollection";
import Alert from "../../components/Alert.vue";
import { useRouter } from "vue-router";
import useCollection from "../../composables/useCollection";
import ChecklistIconDropdown from "../../components/checklists/ChecklistIconDropdown.vue";
import {
    testData,
    instructionsPrompt,
    filePrompt,
} from "../../components/openai/prompts.js";

export default {
    name: "GenerateTemplate",
    components: { PageLoading, Alert, ChecklistIconDropdown },
    setup() {
        const title = ref(null);
        const context = ref(null);
        const aiTemplate = ref(null);
        const isLoading = ref(false);
        const isEditing = ref(false);
        const router = useRouter();
        const dataSaved = ref(false);
        const saving = ref(false);
        const input = ref();
        const currentQuestion = ref();
        const currentPIndex = ref(null);
        const currentSIndex = ref(null);
        const fromFile = ref(true);
        const file = ref(null);
        const selectedIconValue = ref(null);

        const { createAITemplate, error } = templateCollection();

        let prompt;

        const getResponse = async () => {
            error.value = null;

            // Form validation
            if (!fromFile.value) {
                if (!title.value || !context.value) {
                    error.value =
                        "Please provide a Checklist Title and Instructions";
                    return;
                }
                const percentCharacters = context.value.match(/%/g) || [];
                const dollarCharacters = context.value.match(/\$/g) || [];

                console.log(percentCharacters, dollarCharacters);

                if (
                    percentCharacters.length < 2 ||
                    dollarCharacters.length < 2
                ) {
                    error.value =
                        "Please provide at least one %Page Title% and one $Section Title%";
                    return;
                }
                console.log("passed validation");
            } else {
                if (
                    file.value == "" ||
                    file.value == null ||
                    file.value == undefined
                ) {
                    error.value = "Please paste a pdf or word file in the box";
                    return;
                }
            }

            isLoading.value = true;

            // Defines how much creativity the AI will use
            let temperature;

            // Text to be appended to the instructionsPrompt
            let checklistTitle = "Checklist Title: " + title.value + ". ";
            let instructions = "Instructions: " + context.value + ". ";

            if (fromFile.value) {
                // As little as possible to stop AI messing with users file input
                temperature = 0;

                // Reduce the users file as much as possible (saves tokens)
                file.value = file.value.replace(
                    /[^a-zA-Z\?\:\;\(\)\ \d\r?\n\']/g,
                    " "
                );

                // See prompts.js for prompt text
                prompt =
                    `${filePrompt} Example File: ${file.value}.` +
                    "\n\n###\n\n";
            } else {
                // Increased to make up for short instructions
                temperature = 0;

                // Final prompt to send to API
                prompt =
                    instructionsPrompt +
                    checklistTitle +
                    instructions +
                    "\n\n###\n\n";
            }

            if (prompt.length > 4200) {
                error.value =
                    "Sorry, this file is too big to process. Try editing out as much as possible.";
                isLoading.value = false;
                return;
            }

            try {
                const response = await getResponseFromOpenAI({
                    prompt,
                    temperature,
                });

                console.log(response.data.choices[0].text);

                // Remove non-JSON caracters
                response.data.choices[0].text.replace(/[^\x20-\x7E]/g, "");

                let length = response.data.choices[0].text.length;

                let lastChar = response.data.choices[0].text.charAt(length - 1);

                if (lastChar != "}") {
                    response.data.choices[0].text =
                        response.data.choices[0].text.substr(0, length - 1);
                }

                const isValidResponse = () => {
                    console.log("Check if valid");
                    try {
                        JSON.parse(response.data.choices[0].text);
                        return true;
                    } catch (err) {
                        console.log("Invalid JSON");
                        return false;
                    }
                };

                if (isValidResponse) {
                    aiTemplate.value = JSON.parse(
                        response.data.choices[0].text
                    );
                } else {
                    isLoading.value = false;
                    error.value =
                        "Sorry, something went wrong, please try again.";
                    return;
                }

                // Convert input types
                aiTemplate.value.pages.map((page) => {
                    page.sections.map((section) => {
                        section.questions.map((question) => {
                            if (question.input_type == "time") {
                                question.input_type == "date";
                            }
                            if (question.input_type == "radio") {
                                question.input_type == "checkbox";
                            }
                            if (
                                question.input_type == "textarea" ||
                                question.input_type == "number" ||
                                question.input_type == "email" ||
                                question.input_type == "input"
                            ) {
                                question.input_type == "text";
                            }
                        });
                    });
                });

                isLoading.value = false;
                error.value = null;
            } catch (err) {
                console.log(err);
                error.value = "Could not generate a response, please try again";
                isLoading.value = false;
            }
        };

        // Comment out this to use test data
        const getResponseFromOpenAI = projectFunctions.httpsCallable(
            "getResponseFromOpenAI"
        );

        // Uncomment this to use test data
        // const getResponseFromOpenAI = () => {
        //     aiTemplate.value = testData;
        //     aiTemplate.value.pages.map((page) => {
        //         page.id = uuidv4();
        //         page.sections.map((section) => {
        //             section.id = uuidv4();
        //             section.questions.map((question) => {
        //                 question.id = uuidv4();
        //                 if (question.input_type == "time") {
        //                     question.input_type == "date";
        //                 }
        //                 if (question.input_type == "radio") {
        //                     question.input_type == "checkbox";
        //                 }
        //                 if (
        //                     question.input_type == "textarea" ||
        //                     question.input_type == "number" ||
        //                     question.input_type == "email"
        //                 ) {
        //                     question.input_type == "text";
        //                 }
        //             });
        //         });
        //     });
        // };

        const clearRefs = () => {
            aiTemplate.value = null;
            dataSaved.value = false;
        };

        const saveTrainingData = async () => {
            saving.value = true;
            const templateTrainingRef =
                projectFirestore.collection("TemplateTraining");
            const { addDoc } = useCollection(templateTrainingRef);

            let data = {
                prompt: prompt,
                completion: `${JSON.stringify(aiTemplate.value)}\n\n###\n\n`,
            };

            await addDoc(data);

            saving.value = false;
            dataSaved.value = true;
        };

        const generateTemplate = async () => {
            await createAITemplate(aiTemplate.value)
                .then((res) => {
                    if (!error.value) {
                        saving.value = false;
                        router.push({
                            name: "TemplateBuilder",
                            params: { id: res },
                        });
                    }
                })
                .catch((err) => {
                    console.error(err);
                    saving.value = false;
                    error.value = "Failed to create Template";
                });
        };

        const selectQuestion = (pIndex, sIndex, qIndex, question) => {
            currentQuestion.value = `${pIndex}${sIndex}${qIndex}`;
            input.value = question;
            isEditing.value = true;
        };

        const selectTitle = (pIndex, pTitle, sIndex, sTitle) => {
            console.log(pIndex, pTitle, sIndex, sTitle);
            if (sIndex == null) {
                currentPIndex.value = pIndex;
                input.value = pTitle;
            } else {
                console.log("xxx");
                currentPIndex.value = pIndex;
                currentSIndex.value = sIndex;
                input.value = sTitle;
            }

            isEditing.value = true;
        };

        const editTitle = (pIndex, sIndex) => {
            let page = aiTemplate.value.pages[pIndex];
            if (sIndex == null) {
                page.page_title = input.value;
            } else {
                page.sections[sIndex].section_title = input.value;
            }

            input.value = null;
            currentQuestion.value = null;
            currentPIndex.value = null;
            currentSIndex.value = null;
            selectedIconValue.value = null;
            isEditing.value = false;
        };

        const editQuestion = (pIndex, sIndex, qIndex) => {
            let page = aiTemplate.value.pages[pIndex];
            let section = page.sections[sIndex];
            let question = section.questions[qIndex];

            question.question = input.value;

            if (selectedIconValue.value) {
                question.input_type = selectedIconValue.value;
            }

            if (question.input_type == "select") {
                question.options = ["Yes", "No", "N/A"];
            }

            input.value = null;
            currentQuestion.value = null;
            currentPIndex.value = null;
            currentSIndex.value = null;
            selectedIconValue.value = null;
            isEditing.value = false;
        };

        const deleteQuestion = (pIndex, sIndex, qIndex) => {
            let page = aiTemplate.value.pages[pIndex];
            let section = page.sections[sIndex];
            let questions = section.questions;

            questions.splice(qIndex, 1);

            input.value = null;
            currentQuestion.value = null;
            isEditing.value = false;
        };

        const handleSelection = (selection) => {
            selectedIconValue.value = selection;
        };

        return {
            title,
            context,
            getResponse,
            aiTemplate,
            clearRefs,
            isLoading,
            error,
            generateTemplate,
            saveTrainingData,
            dataSaved,
            saving,
            input,
            currentQuestion,
            currentPIndex,
            currentSIndex,
            selectQuestion,
            selectTitle,
            editQuestion,
            editTitle,
            isEditing,
            fromFile,
            file,
            handleSelection,
            deleteQuestion,
        };
    },
};
</script>

<style lang="scss" scoped>
.special-nav {
    // margin-left: $nav-width;
    color: white;
    border-radius: 1em 0 0 1em;
    margin-left: 1em;
    background: linear-gradient(
        -45deg,
        $rm-warning,
        $rm-pink,
        $rm-blue,
        $rm-success
    );
    animation: gradient 35s ease infinite;
    background-size: 400% 400%;
}
@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
.white {
    color: white !important;
}
.block {
    background-color: white;
    padding: 2em;
    box-shadow: $box-shadow;
    border-radius: 8px;
}
.fixed {
    position: fixed !important;
}
.left {
    width: 400px;
    position: fixed;
}
.pad-left {
    padding-left: 1.5em;
}
.right {
    margin-left: 416px;
}
.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ai-title {
    padding-bottom: 5px;
    border-bottom: 1px solid $rm-light-grey;
    margin-bottom: 1em;
    h2 {
        max-width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
.openai-input {
    margin: 0 10% 0 10%;
}
input {
    &::placeholder {
        color: $rm-dark-grey !important;
    }
}
textarea {
    width: 100%;
    padding: 0.8em 1.1em !important;
    font-family: "Poppins";
    color: $rm-dark-blue;
    font-size: 1em;
    background-color: $rm-light-grey;
    border: none;
    border-radius: 4px;
    &::placeholder {
        color: $rm-dark-grey;
    }
}
.page {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    gap: 0.5em;
    background-color: $rm-grey;
    padding: 1em;
    border-radius: 0.5em;
}
.title-edit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    input {
        font-family: "Poppins";
        font-size: 1.2em;
        font-weight: 600;
        border: none;
        border-bottom: 1px solid;
        width: 100%;
        padding: 0;
        margin: 0;
        &:focus {
            outline: none;
        }
    }
    .page-input {
        background-color: $rm-grey;
        font-size: 1.2em;
        font-weight: 600;
        color: $rm-dark-blue;
    }
    .section-input {
        background-color: $rm-blue;
        font-size: 1em;
        font-weight: 600;
        color: white;
    }
}
.section {
    margin-bottom: 4px;
    background-color: $rm-blue;
    color: white;
    padding: 1em;
    border-radius: 0.5em;
}
.question-selected {
    border: 2px solid $rm-pink !important;
}
.question {
    margin-bottom: 4px;
    width: 100%;
    padding: 0.5em 1em;
    border-radius: 0.5em;
    border: 1px solid $rm-grey;
    box-shadow: $box-shadow;

    .question_text {
        display: flex;
        gap: 1em;
        .space-between {
            display: flex;
            flex: 1;
            justify-content: space-between;
            align-items: center;
        }
        .question_edit {
            width: 100%;
            display: flex;
            justify-content: space-between;
            .question-input {
                font-family: "Poppins";
                border: none;
                font-size: 1em;
                margin: 0;
                border-bottom: 1px solid;
                width: 100%;
                &:focus {
                    outline: none;
                }
            }
            .icons {
                display: flex;
            }
        }
    }
    .question_options {
        margin-top: 5px;
        margin-left: 30px;
        font-size: 0.8em;
        color: $rm-dark-grey;
    }
    .question_icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
.form-check-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    width: 100%;
    padding-bottom: 0.5em;
    border-bottom: 1px solid $rm-light-grey;
    margin-bottom: 2em;
    .switch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 24px;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    input:checked + .slider {
        background-color: $rm-success;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px $rm-success;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 16px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
}
</style>