<template>
	<div class="right-content">
		<div class="page-content">
			<div class="page-detail">
				<ChecklistList :status="status" :companyId="companyId" />
			</div>
		</div>
	</div>
</template>

<script>
import account from "../../composables/account";
import ChecklistList from "../../components/checklists/ChecklistList.vue";
import { useMeta } from "vue-meta";
import { useRoute } from "vue-router";

export default {
	components: { ChecklistList },
	name: "CompletedDash",
	setup() {
		useMeta({ title: "Completed Checklists" });
		const route = useRoute();
		const { companyId, role } = account();
		const status = route.query.status;

		return { companyId, status, role };
	},
};
</script>

<style lang="scss" scoped></style>
