<template>
     <div class="spacer-20"></div>
     <form class="t-form">
          <div class="t-form-question">
               <div class="icon-container">
                    <fa class="question-icon" icon="align-left" />
               </div>
               <div class="t-input">
                    <input
                         type="text"
                         class="t-question"
                         placeholder="Question text..."
                         v-model="templateQuestion"
                    />
               </div>
          </div>

          <div class="t-settings">
               <div
                    class="settings-btn"
                    v-bind:class="{ selected: IsRequired }"
                    @click="handleIsRequiredSubmit"
               >
                    Required field
                    <fa class="nav-icon" v-if="IsRequired" icon="check-circle" />
               </div>
          </div>


     <div class="save-item">
          <vue-select
               v-model="pagename"
               :options="options"
               label-by="name"
               close-on-select
               @selected="getSectionsForPage"
          ></vue-select>
          <vue-select
               v-model="sectionName"
               :options="sectionOptions"
               label-by="name"
               close-on-select
               @selected="selectedOption"
          ></vue-select>

              <div class="t-btn">
                         <button class="btn btn-primary" v-on:click.prevent="handleSubmit">Save</button>
                    </div>
     </div>
     </form>
</template>


<script>
import { ref } from "vue";
import { onMounted, onUnmounted, computed } from "@vue/runtime-core";
import { v4 as uuidv4 } from "uuid";
import templateCollection from "../../../composables/templateCollection";
import templateService from "../../../composables/templateService";

export default {
     props: ["pages", "buildertype", "selectedQuestion"],
     setup(props, context) {
          console.log(props.pages, "Question props", props.buildertype);
          const preview = ref();
          const buildertype = ref("");
          buildertype.value = props.buildertype;
          console.log("setup", buildertype.value);
          preview.value = props.pages;
          const { error, updateQuestion } = templateCollection();
          const IsRequired = ref(false);
          const templateQuestion = ref("");
          const sectionName = ref();
          const pagename = ref({});

          const options = ref([]);
          const sectionOptions = ref([]);

          const handleIsRequiredSubmit = () => {
               console.log(IsRequired.value);
               IsRequired.value = !IsRequired.value;
          };

          const handleSubmit = async () => {
               var questionType = "Signature";

                 var id = uuidv4();
               var isNew = true;
               if(props.selectedQuestion != undefined){
                    id = props.selectedQuestion.questionId
                    isNew = false;

               }

              
               var questionobj = {
                    pageid: pagename.value.id,
                    sectionId: sectionName.value.id,
                    question: {
                         questId: id,
                         type: "Signature",
                         question: templateQuestion.value,
                         isRequired: IsRequired.value,
                         isMultipleChoice: false,
                         isImageRequired: false,
                         isLocationRequired: false,
                         isSignatureRequired:true,
                         isTagRequired:false,
                    },
               };
               // if (templateService.getIsUpdate()) {
               //      questionobj.question.isUpdate = true;
               // }
               questionobj.isNew = isNew;
                  if(props.selectedQuestion != undefined){
                     questionobj.prevSectionId =  props.selectedQuestion.sectionId;

                }
     
               if (
                    props.selectedQuestion == undefined ||
                    (props.selectedQuestion != undefined &&
                         props.selectedQuestion.isUpdate == false)
               ) {
                    console.log("save", buildertype.value);

                    context.emit("textQuetion", questionobj);
               } else {
                    // await updateQuestion(
                    //      props.selectedQuestion.pageId,
                    //      props.selectedQuestion.sectionId,
                    //      props.selectedQuestion.questionId,
                    //      {
                    //           question: templateQuestion.value,
                    //           isRequired: IsRequired.value,
                    //      },
                    //      sectionName.value.id,
                    //      templateService.getTemplateId()
                    // );
                    context.emit("textQuetion", questionobj);
               }
          };

          const getSectionsForPage = computed(() => {
               if (pagename.value) {
                    getSections();
               }
          });

          const getSections = () => {
               console.log(preview.value, "sectionsdata");
               sectionOptions.value = [];
               console.log(pagename.value.id, preview.value);
               preview.value.map((item) => {
                    if (item.page.id == pagename.value.id) {
                         console.log(item.page.id);
                         if (item.page.sections != undefined) {
                              item.page.sections.map((section) => {
                                   console.log(section);
                                   sectionOptions.value.push({
                                        id: section.section.id,
                                        name: section.section.sectionTitle,
                                   });
                              });
                         }
                    }
               });
          };

          const selectedOption = computed(() => {
               if (sectionName.value) {
                    console.log(sectionName.value);
               }
          });

          const getPagesOption = (previewData) => {
               previewData.value.map((item) => {
                    console.log(item, item.page.id, item.page.pageTitle, "xx");

                    options.value.push({
                         id: item.page.id,
                         name: item.page.pageTitle,
                    });
               });
               console.log(options, "optons");
          };

          onMounted((async) => {
               getPagesOption(preview);
               console.log(props.selectedQuestion, "propsselected");
               if (props.selectedQuestion != undefined) {
                    prepopulateData();
               }
          });

          const prepopulateData = () => {
               var filteredPage = options.value.filter((item) => {
                    return item.id == props.selectedQuestion.pageId;
               });

               pagename.value = filteredPage[0];
               getSections();
               var filteredSection = sectionOptions.value.filter((item) => {
                    return (item.id = props.selectedQuestion.sectionId);
               });
               console.log(
                    filteredSection,
                    "FilteredSection",
                    sectionOptions,
                    filteredSection[0]
               );
               sectionName.value = filteredSection[0];

               var page = preview.value.filter((item) => {
                    return item.page.id == props.selectedQuestion.pageId;
               });
               var pagedata = page[0];
               console.log(page[0], "pageval");

               var section = pagedata.page.sections.filter((item) => {
                    return item.section.id == props.selectedQuestion.sectionId;
               });
               var sectionData = section[0];
               console.log(section[0], "sectionval");
               var question = sectionData.section.questions.filter((item) => {
                    console.log(
                         item,
                         "quest",
                         props.selectedQuestion.questionId
                    );
                    return item.questId == props.selectedQuestion.questionId;
               });

               var quesData = question[0];

               templateQuestion.value = quesData.question;
               IsRequired.value = quesData.isRequired;
          };

          onUnmounted((async) => {});

          return {
               handleSubmit,
               IsRequired,
               templateQuestion,
               sectionName,
               options,
               pagename,
               getSectionsForPage,
               sectionOptions,
               handleIsRequiredSubmit,
               selectedOption,
          };
     },
};
</script>


<style>
</style>