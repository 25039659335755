<template>
	<div class="modal">
		<div class="modal-container">
			<div class="close" @click="close">
				<fa icon="times" />
			</div>
			<div class="modal-image">
				<img crossorigin="*" v-bind:src="photo" />
			</div>
		</div>
	</div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";

export default {
	name: "ChecklistImageView",
	setup() {
		const router = useRouter();
		const route = useRoute();
		const photo = route.query.photo;

		const close = () => {
			router.back();
		};

		return { photo, close };
	},
};
</script>

<style lang="scss" scoped>
.close {
	position: absolute;
	top: 30px;
	right: 50px;
	color: white !important;
	font-size: 1.5em;
	cursor: pointer;
}
.modal-image {
	display: flex;
	height: 100vh;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	img {
		max-height: 90vh;
		max-width: 90vw;
		box-shadow: $box-shadow;
	}
}
</style>
