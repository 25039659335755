<template>
    <div class="modal">
        <div class="modal-container">
            <div class="modal-content tile">
                <form class="rm-form">
                    <div class="modal-header">
                        <div class="left">
                            <div class="modal-title">
                                <p>Copy question to another page:</p>
                            </div>
                        </div>
                        <div class="right">
                            <div class="btn-icon muted" @click="close">
                                <div class="text">Cancel</div>
                                <fa icon="times" />
                            </div>
                        </div>
                    </div>

                    <div class="modal-body">
                        <div class="form-input">
                            <vue-select
                                v-model="pageSelected"
                                :options="options"
                                label-by="name"
                                placeholder="Select Page"
                                close-on-select
                                @selected="getSectionsForPage"
                            />

                            <vue-select
                                v-model="sectionName"
                                :options="sectionOptions"
                                label-by="name"
                                close-on-select
                                @selected="selectedOption"
                                placeholder="Select section"
                            ></vue-select>
                        </div>

                        <div class="form-submit">
                            <button
                                class="btn btn-primary"
                                :disabled="!pageSelected || !sectionName"
                                @click="selectPage"
                            >Copy to page</button>
                            <!-- <div class="btn-icon muted" >
                                <div class="text"></div>
                            </div>-->
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { onBeforeMount, onMounted, computed } from "@vue/runtime-core";
// import VueSelect from "vue-next-select";

import { ref } from "vue";
export default {
    props: ["pages", "pageToCopy", "sectionToCopy", "questionToCopy"],

    setup(props, context) {
        const pageId = ref("");
        const sectionId = ref("");
        const questionId = ref("");
        const pages = ref();
        const sectionOptions = ref([]);
        pages.value = props.pages;
        pageId.value = props.pageToCopy;
        questionId.value = props.questionToCopy;
        sectionId.value = props.sectionToCopy;
        const pageSelected = ref(null);
        const sectionName = ref(null);
        const previewData = ref();
        previewData.value = props.pages;
        const options = ref([]);

        onMounted(async () => {
            console.log(previewData.value, "ON Mount");
            getPagesOption(previewData);
        });

        const close = () => {
            context.emit("close");
        };

        const selectPage = () => {
            var data = {
                fromPageId: pageId.value,
                fromSectionId: sectionId.value,
                fromQuestionId: questionId.value,
                toPageId: pageSelected.value.id,
                toSectionId: sectionName.value.id,
            };

            context.emit("copyQuestionCallback", data);
        };

        const getSectionsForPage = computed(() => {
            if (pageSelected.value) {
                getSections();
            }
        });

        const getSections = () => {
            sectionOptions.value = [];
            previewData.value.map((item) => {
                if (item.page.id == pageSelected.value.id) {
                    if (item.page.sections != undefined) {
                        item.page.sections.map((section) => {
                            sectionOptions.value.push({
                                id: section.section.id,
                                name: section.section.sectionTitle,
                            });
                        });
                    }
                }
            });
        };

        const selectedOption = computed(() => {
            if (sectionName.value) {
                // console.log(sectionName.value);
            }
        });

        const getPagesOption = (previewData) => {
            previewData.value.map((item) => {
                options.value.push({
                    id: item.page.id,
                    name: item.page.pageTitle,
                });
            });
        };

        return {
            close,
            pageSelected,
            options,
            selectPage,
            sectionName,
            sectionOptions,
            getSectionsForPage,
            selectedOption,
        };
    },
};
</script>

<style lang="scss" scoped>
.modal-content {
    width: 40%;
    margin: 0 auto;
}
</style>