<template>
	<div class="right-content">
		<transition name="fade" mode="out-in">
			<Popup v-if="showTemplatePopup">
				<template #modal-header-content>
					<div class="modal-title"><h4>Template Information</h4></div>
					<div
						class="btn-icon muted"
						@click="showHideTemplatePopup(selectedTemplate.id)"
					>
						close
						<fa icon="times" />
					</div>
				</template>

				<!-- Add template detail content here -->
				<template #modal-body-content>
					<div class="popup-container">
						<div class="popup-logo-container">
							<img
								class="company-logo"
								:src="selectedCompany.companyLogo"
								alt=""
							/>
						</div>
						<h1>
							{{ selectedTemplate.name }}
						</h1>
						<p>{{ selectedTemplate.description }}</p>
						<br />
						<div class="split">
							<div class="detail-column" style="width: 20%">
								<p>
									<b>Created by: </b>
								</p>
								<p>
									<b>Last updated: </b>
								</p>
								<p>
									<b>tags: </b>
								</p>
							</div>
							<div class="detail-column">
								<p>
									{{ selectedCompany.companyName }}
								</p>
								<p>
									{{
										format(
											selectedTemplate.updatedAt.toDate()
										)
									}}
								</p>
								<div class="tags-container">
									<div
										v-for="tag in selectedTemplate.tags"
										class="pill bg-blue"
									>
										{{ tag }}
									</div>
								</div>
							</div>
						</div>
						<div class="spacer-40"></div>

						<h2>About {{ selectedCompany.companyName }}</h2>

						<p>{{ selectedCompany.companyDescription }}</p>
						<br />

						<div class="split">
							<div style="width: 20%">
								<p>
									<b>Website: </b>
								</p>
								<p>
									<b>Contact: </b>
								</p>
							</div>
							<div>
								<p>
									<a
										:href="`https://${selectedCompany.websiteUrl}`"
										target="_blank"
										>{{ selectedCompany.websiteUrl }}</a
									>
								</p>
								<p>
									<a :href="`mailto:${selectedCompany.email}`"
										>{{ selectedCompany.email }}
									</a>
								</p>
							</div>
						</div>
						<div class="spacer-40"></div>
						<i
							>Use this template at your own risk.
							{{ selectedCompany.companyName }} accepts no
							liability. See Risk Memo's
							<a
								href="https://www.riskmemo.com/terms/terms-of-use"
								style="text-decoration: underline"
								>Terms of Use</a
							>
							under "Liability & Disclaimers" for more
							information</i
						>
					</div>
				</template>
			</Popup>
		</transition>
		<Alert :message="error" v-if="error" />
		<div class="success-message" v-if="success">Template added!</div>

		<div class="page-content">
			<div class="page-detail">
				<div class="right-sub-nav fixed">
					<div class="nav-row">
						<div class="sub-nav-left">
							<router-link
								class="back-arrow"
								:to="{ name: 'TemplatesDash' }"
							>
								<fa icon="arrow-left" />
								<p>Back</p>
							</router-link>
							<div class="rm-form template-search">
								<div class="form-input">
									<vue-select
										v-model="searchTag"
										v-if="!fetchingData"
										:options="tags"
										searchable
										search-placeholder="Type to search and select tags..."
										placeholder="Filter list by tags..."
										:clear-on-select="true"
										:clear-on-close="true"
										:close-on-select="true"
										@blur="addSearchTag(searchTag)"
									></vue-select>
								</div>
							</div>
						</div>
						<div class="sub-nav-right">
							<div class="add-id">
								<p v-if="!isLoading">
									Copy from a template id:
								</p>
								<div class="search" v-if="!isLoading">
									<fa
										class="search-icon btn btn-icon"
										icon="copy"
										@click="handleClick(idInput)"
									/>
									<input
										type="search"
										placeholder="Template id..."
										v-model="idInput"
									/>
								</div>
								<div
									class="loading"
									v-if="isLoading && idInput != ''"
								>
									<page-loading />
								</div>
								<!-- <router-link
                                    class="generator-link"
                                    :to="{ name: 'GenerateTemplate' }"
                                >
                                    <p>AI Template Generator</p>
                                </router-link> -->
							</div>
						</div>
					</div>
					<div class="nav-row" v-if="searchTags && searchTags.length">
						<div class="sub-nav-left">
							<div class="pill-container fade-row">
								<div
									v-for="searchTag in searchTags"
									:key="searchTag"
									class="pill bg-blue"
								>
									<fa class="fa-tag" icon="tag" />
									{{ searchTag }}
									<fa
										class="fa-times"
										icon="times"
										@click="deleteSearchTag(searchTag)"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- Loading -->
				<div
					class="list-container"
					v-if="!templates"
					v-bind:style="{
						'margin-top':
							searchTags && searchTags.length > 0
								? '50px'
								: '0px',
					}"
				>
					<div class="table-row-container">
						<div class="table-row-shimmer shine"></div>
						<div class="table-row-shimmer shine"></div>
						<div class="table-row-shimmer shine"></div>
					</div>
				</div>

				<div
					v-else
					class="list-container fixed-sub-nav"
					v-bind:style="{
						'margin-top':
							searchTags && searchTags.length > 0
								? '50px'
								: '0px',
					}"
				>
					<div v-if="templates && templates.length == 0">
						<div class="spacer-20"></div>
						<div class="no-list">
							<div class="icon">
								<fa icon="tasks" />
							</div>
						</div>
					</div>

					<div class="table-row-container" v-if="templates">
						<div v-for="template in templates" :key="template.id">
							<div class="table-row">
								<div class="row-left">
									<div class="title no-img-title">
										<span
											class="btn btn-link"
											@click="
												showHideTemplatePopup(
													template.id
												)
											"
										>
											{{ template.name }}
										</span>
									</div>
									<div class="description">
										{{ template.description }}
									</div>
								</div>

								<div class="row-right">
									<div class="help-text">
										by
										<span>{{
											getCompanyNameByCompanyId(
												template.companyId
											)
										}}</span>
									</div>

									<div class="actions">
										<div
											class="actions-icon-btn"
											@click="
												showHideTemplatePopup(
													template.id
												)
											"
										>
											<fa icon="question-circle" />
										</div>
										<div
											class="actions-icon-btn"
											v-if="isLoadingId != template.id"
											@click="handleClick(template.id)"
										>
											<fa class="copy-icon" icon="copy" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { watchEffect } from "vue";
import getCollection from "../../composables/getCollection";
import templateCollection from "../../composables/templateCollection";
import { projectFirestore } from "../../firebase/config";
import PageLoading from "../../components/PageLoading.vue";
import Alert from "../../components/Alert.vue";
import ListLoading from "../../components/ListLoading.vue";
import Popup from "../../components/Popup.vue";
import moment from "moment";

export default {
	components: { PageLoading, Alert, ListLoading, Popup },
	name: "TemplateLibrary",
	setup() {
		const { copyTemplate, error } = templateCollection();
		const idInput = ref("");
		const isLoading = ref(false);
		const isLoadingId = ref(null);
		const library = projectFirestore
			.collection("Template")
			.where("addedToLibrary", "==", true);
		const { documents } = getCollection(library);
		const templates = ref(null);
		const errorMessage = "Failed to copy template";
		const success = ref(false);

		const tags = ref([]);
		const searchTag = ref(null);
		const searchTags = ref([]);
		const fetchingData = ref(true);

		const companiesRef = projectFirestore.collection("companies");

		const uniqueCompanyIds = ref([]);
		const authorCompanyDetails = ref([]);

		let companyDetailsFetched = false;

		// Format the dates to display
		const format = (value) => {
			return moment(value).format("MMMM Do, YYYY");
		};

		function getCompanyNameByCompanyId(companyId) {
			const companyObj = authorCompanyDetails.value.find(
				(company) => company.companyId === companyId
			);
			return companyObj ? companyObj.companyName : null; // Return null or a default URL if not found
		}

		async function fetchCompanies() {
			console.log("fetchCompanies");
			authorCompanyDetails.value = []; // Reset the array to ensure it's empty before populating

			const companyPromises = uniqueCompanyIds.value.map(
				async (companyId) => {
					const docRef = companiesRef.doc(companyId);
					const docSnap = await docRef.get();
					if (docSnap.exists) {
						return {
							companyId: companyId,
							...docSnap.data(),
						};
					} else {
						console.log("No such document!");
						return null; // Handle the case where the document does not exist
					}
				}
			);

			const companies = await Promise.all(companyPromises);

			authorCompanyDetails.value = companies.filter(
				(company) => company !== null
			); // Filter out any null values
			companyDetailsFetched = true;
		}

		watchEffect(() => {
			if (documents.value) {
				const companyIdsSet = new Set();
				documents.value.forEach((doc) => {
					if (doc.companyId) {
						companyIdsSet.add(doc.companyId);
					}
				});
				uniqueCompanyIds.value = Array.from(companyIdsSet);

				if (!companyDetailsFetched) {
					fetchCompanies();
				}

				fetchingData.value = false;
				let localTemplates = documents.value;
				let localTags = new Set();

				documents.value.forEach((template) => {
					template.tags.forEach((tag) => {
						if (tag != "") {
							localTags.add(tag);
						}
					});
				});

				tags.value = Array.from(localTags);

				if (searchTags.value && searchTags.value.length > 0) {
					const lowerSearchTags = searchTags.value.map((t) =>
						t.toLowerCase()
					);

					localTemplates = localTemplates.filter((item) => {
						const lowerItemTags = new Set(
							item.tags.map((i) => i.toLowerCase())
						);
						return lowerSearchTags.every((t) =>
							lowerItemTags.has(t)
						);
					});
				}

				templates.value = localTemplates;
			} else {
				fetchingData.value = true;
			}
		});

		const addSearchTag = () => {
			let tag;
			if (searchTag.value != null && searchTag.value != "") {
				tag = searchTag.value.trim();
			} else {
				return;
			}

			if (!searchTags.value.includes(tag)) {
				searchTags.value.push(tag);
			}
			// searchTag.value = "";
			// if (searchTags.value.length) {
			// }

			searchTag.value = null;
		};

		// Delete tag from search
		const deleteSearchTag = (searchTag) => {
			searchTags.value = searchTags.value.filter((item) => {
				return searchTag !== item;
			});
		};

		const showTemplatePopup = ref(false);
		const selectedTemplate = ref(null);
		const selectedCompany = ref(null);

		const showHideTemplatePopup = (templateId) => {
			if (showTemplatePopup.value) {
				showTemplatePopup.value = false;
				selectedTemplate.value = null;
			} else {
				// Filter documents by templateId and assign the first match to selectedTemplate
				const matchingDocument = documents.value.find(
					(doc) => doc.id === templateId
				);
				selectedTemplate.value = matchingDocument;

				const matchingCompany = authorCompanyDetails.value.find(
					(company) =>
						company.companyId === matchingDocument.companyId
				);

				selectedCompany.value = matchingCompany;

				showTemplatePopup.value = true;
			}
		};

		const handleClick = async (templateId) => {
			error.value = null;
			success.value = false;
			isLoadingId.value = templateId;
			isLoading.value = true;
			await copyTemplate(templateId.trim(), true, false);

			if (!error.value) {
				success.value = true;
				setTimeout(() => {
					success.value = false;
				}, 3000);
			} else {
				console.error(error.value);
			}
			isLoading.value = false;
			isLoadingId.value = null;
			idInput.value = "";
		};

		return {
			templates,
			handleClick,
			isLoading,
			isLoadingId,
			idInput,
			error,
			errorMessage,
			success,
			tags,
			searchTag,
			searchTags,
			fetchingData,
			addSearchTag,
			deleteSearchTag,
			showTemplatePopup,
			showHideTemplatePopup,
			selectedTemplate,
			getCompanyNameByCompanyId,
			selectedCompany,
			format,
		};
	},
};
</script>

<style lang="scss" scoped>
.popup-container {
	width: 100%;
	max-height: 60vh;
	overflow: auto;
}
.add-id {
	display: flex;
	// gap: 1em;
	gap: 0.5em;
	//align-items: center;
	align-items: flex-start;
	flex-direction: column;

	@include respond(small) {
		flex-direction: row;
		align-items: center;
	}
	@include respond(large) {
		gap: 1em;
	}

	.search {
		/* clears the ‘X’ from Internet Explorer */
		input[type="search"]::-ms-clear {
			display: none;
			width: 0;
			height: 0;
		}
		input[type="search"]::-ms-reveal {
			display: none;
			width: 0;
			height: 0;
		}
		/* clears the ‘X’ from Chrome */
		input[type="search"]::-webkit-search-decoration,
		input[type="search"]::-webkit-search-cancel-button,
		input[type="search"]::-webkit-search-results-button,
		input[type="search"]::-webkit-search-results-decoration {
			display: none;
		}
		input {
			width: 300px;
			padding: 9px 30px 9px 1em;
			background-color: white;
			border-radius: $border-radius;
			font-size: 0.8em;
			margin-left: 1em;
		}
		.search-icon {
			position: absolute;
			top: 3px;
			right: 1em;
			color: $rm-grey;
			&:hover {
				color: $rm-pink;
			}
		}
	}
}
.back-arrow {
	display: flex;
	align-items: center;
	gap: 1em;
}
.templates {
	display: grid;
	grid-template-columns: repeat(auto-fit, 280px);
	grid-template-rows: auto;
	column-gap: 25px;
	row-gap: 25px;
	//  margin-bottom: 3em;
	margin-top: 1em;

	@include respond(small) {
		margin-top: 0;
	}
}
.loading {
	// margin-top: -2em;
	margin-right: 1em;
}
.nav-row {
	flex-direction: column;
	height: 115px;
	align-items: flex-start;
	gap: 0.5em;
	margin-bottom: 0.5em;

	@include respond(small) {
		height: 75px;
		gap: 1em;
	}
	@include respond(large) {
		flex-direction: row;
		height: 50px;
		align-items: center;
		margin-bottom: 0;
	}
}
.right-sub-nav.fixed {
	top: 142px;

	@include respond(medium) {
		top: 75px;
	}
	@include respond(large) {
		top: 70px;
	}
}
.list-container {
	top: 260px;
	@include respond(small) {
		top: 223px;
	}
	@include respond(medium) {
		top: 155px;
	}
	@include respond(large) {
		top: 120px;
	}
}

.template-search {
	margin-top: 1.1em;
	max-width: 400px;
}

.popup-logo-container {
	display: flex;
	justify-content: center;
	margin: 10px 0 30px;
	.company-logo {
		max-width: 30%;
		max-height: 120px;
	}
}

.detail-column {
	display: flex;
	flex-direction: column;
	gap: 0.6em;
}

.tags-container {
	display: flex;
	gap: 0.5em;
	width: 100%;
}
</style>
