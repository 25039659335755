import EnvUtil from "@/misc/env";

export const BASE_URL = EnvUtil.FIREBASE_API_URL;
export const GENERATE_PDF = BASE_URL + "generatePdf";
export const CANCEL_SUBSCRIPTION = BASE_URL + "cancelSubscription";
export const UPGRADE_SUBSCRIPTION = BASE_URL + "updateSubscription";
export const RETRIEVE_SUBSCRIPTION = BASE_URL + "retrieveSubscription";

export default {
	BASE_URL,
	GENERATE_PDF,
	CANCEL_SUBSCRIPTION,
	UPGRADE_SUBSCRIPTION,
	RETRIEVE_SUBSCRIPTION,
};
