<template>
	<div>
		<div class="spacer-40"></div>

		<div class="builder-options" v-if="showOptions">
			<div class="grid-container">
				<div
					class="grid-item"
					@click="
						newPageClick();
						handleShowOptions();
					"
				>
					<fa class="nav-icon" icon="file" />
					<p class="p-size">New Page</p>
				</div>
				<div
					class="grid-item"
					@click="
						newSectionClick();
						handleShowOptions();
					"
				>
					<fa class="nav-icon" icon="file-alt" />
					<p class="p-size">Section</p>
				</div>
				<div
					class="grid-item"
					@click="
						newCheckboxClick();
						handleShowOptions();
					"
				>
					<fa class="nav-icon" icon="check-circle" />
					<p class="p-size">Check</p>
				</div>
				<div
					class="grid-item"
					@click="
						multipleCheckboxClick();
						handleShowOptions();
					"
				>
					<fa class="nav-icon" icon="tasks" />
					<p class="p-size">Select</p>
				</div>
				<div
					class="grid-item"
					@click="
						textClick();
						handleShowOptions();
					"
				>
					<fa class="nav-icon" icon="align-left" />
					<p class="p-size">Text</p>
				</div>
				<div
					class="grid-item"
					@click="
						instructionClick();
						handleShowOptions();
					"
				>
					<fa class="nav-icon" icon="book-reader" />
					<span class="tooltip">Instructions</span>
				</div>
				<div
					class="grid-item"
					@click="
						dateTimeClick();
						handleShowOptions();
					"
				>
					<fa class="nav-icon" icon="calendar-alt" />
					<span class="tooltip">Date &amp; time</span>
				</div>
				<div
					class="grid-item"
					@click="
						imageClick();
						handleShowOptions();
					"
				>
					<fa class="nav-icon" icon="image" />
					<span class="tooltip">Images</span>
				</div>
				<div
					class="grid-item"
					@click="
						locationClick();
						handleShowOptions();
					"
				>
					<fa class="nav-icon" icon="map-marked-alt" />
					<span class="tooltip">Location</span>
				</div>
				<div
					class="grid-item"
					@click="
						signClick();
						handleShowOptions();
					"
				>
					<fa class="nav-icon" icon="file-signature" />
					<span class="tooltip">Signature</span>
				</div>
				<!-- To add back later -->

				<!-- <div class="grid-item">
          <fa
            class="nav-icon"
            icon="user"
            @click="
              linkUserClick();
              handleShowOptions();
            "
          />
          <span class="tooltip">Link to a user</span>
                </div>-->
				<!-- <div class="grid-item">
          <fa
            class="nav-icon"
            icon="building"
            @click="
              linkLocationClick();
              handleShowOptions();
            "
          />
          <span class="tooltip">Link to a location</span>
                </div>-->
				<div
					class="grid-item"
					@click="
						linkEquipmentClick();
						handleShowOptions();
					"
				>
					<fa class="nav-icon" icon="tools" />
					<span class="tooltip">Link equipment</span>
				</div>
				<div
					class="grid-item"
					@click="
						linkPeopleClick();
						handleShowOptions();
					"
				>
					<fa class="nav-icon" icon="users" />
					<span class="tooltip">Link People</span>
				</div>
			</div>
		</div>

		<div v-else>
			<div class="back-btn">
				<div class="btn-arrow-left" @click="handleShowOptions">
					<fa icon="arrow-left" class="icon" />Back
				</div>
			</div>

			<div>
				<NewPage
					v-if="buildertype == '0'"
					@newPage="addPage"
					:pages="previewData"
					:selectedPage="seletedPageData"
				/>

				<Section
					v-if="buildertype == '1'"
					@pageSection="addSection"
					:pages="previewData"
					:selectedSection="seletedPageData"
				/>
				<Question
					@textQuetion="addQuestion"
					v-if="buildertype == '2'"
					:pages="previewData"
					:buildertype="buildertype"
					:selectedQuestion="seletedPageData"
				/>
				<MultipleChoice
					v-if="buildertype == '3'"
					@multiChoice="addChoices"
					:pages="previewData"
					:selectedQuestion="seletedPageData"
				/>
				<Instructions
					v-if="buildertype == '4'"
					@textQuetion="addQuestion"
					:pages="previewData"
					:buildertype="buildertype"
					:selectedQuestion="seletedPageData"
				/>
				<Question
					@textQuetion="addQuestion"
					v-if="buildertype == '5'"
					:pages="previewData"
					:buildertype="buildertype"
					:selectedQuestion="seletedPageData"
				/>
				<Question
					@textQuetion="addQuestion"
					v-if="buildertype == '7'"
					:pages="previewData"
					:buildertype="buildertype"
					:selectedQuestion="seletedPageData"
				/>
				<Question
					@textQuetion="addQuestion"
					v-if="buildertype == '6'"
					:pages="previewData"
					:buildertype="buildertype"
					:selectedQuestion="seletedPageData"
				/>
				<Question
					@textQuetion="addQuestion"
					v-if="buildertype == '8'"
					:pages="previewData"
					:buildertype="buildertype"
					:selectedQuestion="seletedPageData"
				/>
				<Question
					@textQuetion="addQuestion"
					v-if="buildertype == '9'"
					:pages="previewData"
					:buildertype="buildertype"
					:selectedQuestion="seletedPageData"
				/>
				<Question
					@textQuetion="addQuestion"
					v-if="buildertype == '12'"
					:pages="previewData"
					:buildertype="buildertype"
					:selectedQuestion="seletedPageData"
				/>
				<Question
					@textQuetion="addQuestion"
					v-if="buildertype == '13'"
					:pages="previewData"
					:buildertype="buildertype"
					:selectedQuestion="seletedPageData"
				/>
				<LinkUser v-if="buildertype == '10'" />
				<LinkLocation v-if="buildertype == '11'" />
				<!-- <LinkEquipment v-if="buildertype == '12'" /> -->

				<!-- <SignatureRequired @signatureRequired="signRequired" v-if="buildertype == '8'" /> -->
			</div>
		</div>
	</div>
</template>

<script>
import { onMounted, onUnmounted } from "@vue/runtime-core";
import NewPage from "./NewPage.vue";
import Section from "./Section.vue";
import Question from "./TextQuestion.vue";
import MultipleChoice from "./MultipleAnswerCheck.vue";
import Instructions from "./Instructions.vue";
import SignatureRequired from "./SignatureRequired.vue";
import LinkUser from "./LinkUser.vue";
import LinkLocation from "./LinkLocation.vue";
import LinkEquipment from "./LinkEquipment.vue";

import { ref } from "vue";
import { v4 as uuidv4 } from "uuid";
import templateService from "../../../composables/templateService";
import templateCollection from "../../../composables/templateCollection";

export default {
	components: {
		NewPage,
		Section,
		Question,
		MultipleChoice,
		Instructions,
		SignatureRequired,
		LinkUser,
		LinkLocation,
		LinkEquipment,
	},
	props: ["pageData", "templateData"],
	emits: ["previewData"],
	setup(props, context) {
		const seletedPageData = ref({});

		const previewData = ref([]);
		const uuid = ref("");
		const sectionuuid = ref("");
		const templateData = ref({});
		const {
			error,
			updatePage,
			addTemplatePage,
			updateSection,
			addSections,
			updateQuestion,
			addQuestions,
		} = templateCollection();

		const navigateToComponent = (params) => {
			var isUpdateReq = templateService.getIsUpdate();
			if (params.value.modalType == "page") {
				showOptions.value = false;
				seletedPageData.value = {
					isUpdate: isUpdateReq ? true : false,
					pageId: params.value.pageId,
				};

				buildertype.value = BuilerType.NEWPAGE;
			} else if (params.value.modalType == "section") {
				showOptions.value = false;
				buildertype.value = BuilerType.SECTION;
				seletedPageData.value = {
					isUpdate: isUpdateReq ? true : false,
					pageId: params.value.pageId,
					sectionId: params.value.sectionId,
				};
			} else if (params.value.modalType == "question") {
				showOptions.value = false;
				buildertype.value = BuilerType.QUESTION;
				var question = params.value.question;

				if (question.type == "Checkbox") {
					buildertype.value = BuilerType.CHECKBOX;
				}

				if (question.type == "Instruction") {
					buildertype.value = BuilerType.INSTRUCTIONS;
				}

				if (question.type == "MultiChoice") {
					buildertype.value = BuilerType.MULTIPLECHOICE;
				}

				if (question.type == "DateTime") {
					buildertype.value = BuilerType.DATETIME;
				}

				if (question.type == "Signature") {
					buildertype.value = BuilerType.SIGNATURE;
				}

				if (question.type == "Equipment") {
					buildertype.value = BuilerType.LINKEQUIPMENT;
				}

				if (question.type == "People") {
					buildertype.value = BuilerType.PEOPLE;
				}

				if (question.isImageRequired == true) {
					buildertype.value = BuilerType.IMAGE;
				}

				if (question.isLocationRequired == true) {
					buildertype.value = BuilerType.LOCATION;
				}

				seletedPageData.value = {
					isUpdate: isUpdateReq ? true : false,
					pageId: params.value.pageId,
					sectionId: params.value.sectionId,
					questionId: params.value.question.questId,
				};
			}
		};

		const BuilerType = {
			NEWPAGE: "0",
			SECTION: "1",
			QUESTION: "2",
			MULTIPLECHOICE: "3",
			INSTRUCTIONS: "4",
			DATETIME: "5",
			IMAGE: "6",
			LOCATION: "7",
			SIGNATURE: "8",
			CHECKBOX: "9",
			LINKUSER: "10",
			LINKLOCATION: "11",
			LINKEQUIPMENT: "12",
			PEOPLE: "13",
		};

		const buildertype = ref("");

		const showOptions = ref(true);
		const handleShowOptions = () => {
			if (showOptions.value) {
				showOptions.value = false;
			} else {
				showOptions.value = true;
			}
		};

		const newPageClick = () => {
			//create a dummy new page and add in the list

			seletedPageData.value = undefined;
			buildertype.value = BuilerType.NEWPAGE;

			//  if (templateService.getIsUpdate()) {
			//     page.page.isUpdate = true;
			//}

			//  previewData.value.push(page);

			//context.emit("previewData", [previewData.value, null]);
		};

		const newSectionClick = () => {
			seletedPageData.value = undefined;
			buildertype.value = BuilerType.SECTION;
		};

		const addNewSection = (pageId) => {
			sectionuuid.value = uuidv4();

			previewData.value.map((item) => {
				if (item.page.id == pageId) {
					if (item.page.sections == undefined) {
						if (templateService.getIsUpdate()) {
							item.page.sections = [
								{
									section: {
										id: sectionuuid.value,
										sectionTitle: "",
										order: 100,
										isUpdate: true,
										repeat: false,
									},
								},
							];
						} else {
							item.page.sections = [
								{
									section: {
										id: sectionuuid.value,
										sectionTitle: "",
										order: 100,
										repeat: false,
									},
								},
							];
						}
					} else {
						var sortOrder = 100;
						if (item.page.sections != undefined) {
							item.page.sections.sort(function (a, b) {
								var order1 = a.section.order;
								var order2 = b.section.order;
								return order1 < order2
									? -1
									: order1 > order2
									? 1
									: 0;
							});

							if (item.page.sections.length > 0) {
								var order =
									item.page.sections[
										item.page.sections.length - 1
									].section.order;
								var sortOrder = order + 100;
							}
						}

						if (templateService.getIsUpdate()) {
							item.page.sections.push({
								section: {
									id: sectionuuid.value,
									sectionTitle: "",
									order: sortOrder,
									isUpdate: true,
									repeat: false,
								},
							});
						} else {
							item.page.sections.push({
								section: {
									id: sectionuuid.value,
									sectionTitle: "",
									order: sortOrder,
									repeat: false,
								},
							});
						}
					}
				}
			});
			context.emit("previewData", [previewData.value, null]);
		};

		const newCheckboxClick = () => {
			buildertype.value = BuilerType.CHECKBOX;
			seletedPageData.value = undefined;
		};

		const multipleCheckboxClick = () => {
			seletedPageData.value = undefined;
			buildertype.value = BuilerType.MULTIPLECHOICE;
		};

		const textClick = () => {
			buildertype.value = BuilerType.QUESTION;
			seletedPageData.value = undefined;
		};

		const instructionClick = () => {
			seletedPageData.value = undefined;
			buildertype.value = BuilerType.INSTRUCTIONS;
		};

		const getOrderOfQuestion = (textQuestion) => {
			var sortOrder = 1;
			previewData.value.map((item) => {
				if (item.page.id == textQuestion.pageid) {
					item.page.sections.map((section) => {
						if (section.section.id == textQuestion.sectionId) {
							if (section.section.questions != undefined) {
								section.section.questions.sort(function (a, b) {
									var order1 = a.question.order;
									var order2 = b.question.order;
									return order1 < order2
										? -1
										: order1 > order2
										? 1
										: 0;
								});

								if (section.section.questions.length > 0) {
									var order =
										section.section.questions[
											section.section.questions.length - 1
										].order;
									sortOrder = order + 1;
								}
							}
						}
					});
				}
			});
			return sortOrder;
		};

		const addQuestion = async (textQuestion) => {
			var order = getOrderOfQuestion(textQuestion);
			textQuestion.question.order = order;

			if (textQuestion.isNew == true) {
				previewData.value.map((item) => {
					if (item.page.id === textQuestion.pageid) {
						if (item.page.sections != undefined) {
							item.page.sections.map((section) => {
								if (
									section.section.id == textQuestion.sectionId
								) {
									if (
										section.section.questions == undefined
									) {
										section.section.questions = [];
										section.section.questions.push(
											textQuestion.question
										);
									} else {
										section.section.questions.push(
											textQuestion.question
										);
									}
								}
							});
						}
					}
				});
				await addQuestions(
					templateService.getTemplateId(),
					textQuestion.pageid,
					textQuestion.sectionId,
					textQuestion.question
				);
				context.emit("previewData", [previewData.value, null]);
			} else {
				previewData.value.map((item) => {
					if (item.page.id == textQuestion.pageid) {
						if (item.page.sections != undefined) {
							item.page.sections.map((section) => {
								if (
									section.section.id == textQuestion.sectionId
								) {
									section.section.questions.map(
										(question) => {
											if (
												question.questId ==
												textQuestion.question.questId
											) {
												question.question =
													textQuestion.question.question;
												question.isRequired =
													textQuestion.question.isRequired;
												question.isTagRequired =
													textQuestion.question.isTagRequired;
											}
										}
									);
								}
							});
						}
					}
				});
				console.log("text question: ", textQuestion),
					console.log(
						"template id: ",
						templateService.getTemplateId()
					);
				await updateQuestion(
					templateService.getTemplateId(),
					textQuestion.pageid,
					textQuestion.sectionId,
					textQuestion.question.questId,
					{
						question: textQuestion.question.question,
						isRequired: textQuestion.question.isRequired,
						isTagRequired: textQuestion.question.isTagRequired,
						actionsAllowed: textQuestion.question.actionsAllowed,
					}
				);

				context.emit("previewData", [previewData.value, null]);
			}
			handleShowOptions();
		};

		const addSection = async (pageSection) => {
			if (pageSection.isSubmit) {
				addNewSection(pageSection.page);
			}

			var id = sectionuuid.value;
			if (pageSection.sectionId != "") {
				id = pageSection.sectionId;
			}

			var sectionObj = {};

			// if (pageSection.isSubmit) {
			previewData.value.map((item) => {
				if (item.page.id == pageSection.page) {
					if (item.page.sections != undefined) {
						item.page.sections.map((section) => {
							if (section.section.id == id) {
								sectionObj = section;
								section.section.sectionTitle =
									pageSection.sectionTitle;
								section.section.repeat = pageSection.repeat;
							}
						});
					}
				}
			});

			if (pageSection.isSubmit) {
				await addSections(
					templateService.getTemplateId(),
					pageSection.page,
					sectionObj.section.id,
					sectionObj
				);
			} else {
				await updateSection(
					pageSection.page,
					pageSection.sectionId,
					pageSection.sectionTitle,
					templateService.getTemplateId(),
					pageSection.repeat
				);
			}
			context.emit("previewData", [previewData.value, null]);
			handleShowOptions();
		};

		const addPage = async (pageTitle, pageId, isUpdate) => {
			var id = uuidv4();
			if (pageId != "") {
				id = pageId;
				previewData.value.map((item) => {
					if (item.page.id == id) {
						item.page.pageTitle = pageTitle;
					}
				});
			} else {
				var order = 1;
				if (previewData.value.length > 0) {
					previewData.value.sort(function (a, b) {
						var order1 = a.page.order;
						var order2 = b.page.order;
						return order1 < order2 ? -1 : order1 > order2 ? 1 : 0;
					});
					order =
						previewData.value[previewData.value.length - 1].page
							.order + 1;
				}

				var page = {
					page: {
						id: id,
						pageTitle: pageTitle,
						order: order,
					},
				};

				previewData.value.push(page);
			}

			if (isUpdate) {
				await updatePage(
					pageId,
					pageTitle,
					templateService.getTemplateId()
				);
			} else {
				var page = previewData.value.find(
					(page) => page.page.id === id
				);

				await addTemplatePage(
					page,
					id,
					templateService.getTemplateId()
				);
			}

			context.emit("previewData", [previewData.value, null]);
			handleShowOptions();
		};

		const addChoices = async (textQuestion) => {
			var order = getOrderOfQuestion(textQuestion);
			textQuestion.question.order = order;
			if (textQuestion.isNew == true) {
				previewData.value.map((item) => {
					if (item.page.id == textQuestion.pageid) {
						if (item.page.sections != undefined) {
							item.page.sections.map((section) => {
								if (
									section.section.id == textQuestion.sectionId
								) {
									if (
										section.section.questions == undefined
									) {
										section.section.questions = [];
										section.section.questions.push(
											textQuestion.question
										);
									} else {
										section.section.questions.push(
											textQuestion.question
										);
									}
								}
							});
						}
					}
				});
				await addQuestions(
					templateService.getTemplateId(),
					textQuestion.pageid,
					textQuestion.sectionId,
					textQuestion.question
				);

				context.emit("previewData", [previewData.value, null]);
			} else {
				previewData.value.map((item) => {
					if (item.page.id == textQuestion.pageid) {
						if (item.page.sections != undefined) {
							item.page.sections.map((section) => {
								if (
									section.section.id == textQuestion.sectionId
								) {
									section.section.questions.map(
										(question) => {
											if (
												question.questId ==
												textQuestion.question.questId
											) {
												question.question =
													textQuestion.question.question;
												question.isRequired =
													textQuestion.question.isRequired;
												question.isTagRequired =
													textQuestion.question.isTagRequired;
												question.isMultipleChoice =
													textQuestion.question.isMultipleChoice;
												question.choices =
													textQuestion.question.choices;
											}
										}
									);
								}
							});
						}
					}
				});
				await updateQuestion(
					templateService.getTemplateId(),
					textQuestion.pageid,
					textQuestion.sectionId,
					textQuestion.question.questId,
					{
						question: textQuestion.question.question,
						isRequired: textQuestion.question.isRequired,
						isTagRequired: textQuestion.question.isTagRequired,
						isMultipleChoice:
							textQuestion.question.isMultipleChoice,
						choices: textQuestion.question.choices,
					}
				);

				context.emit("previewData", [previewData.value, null]);
			}
			handleShowOptions();
		};

		const addInstruction = (instructionText) => {
			if (templateData.value.instruction == undefined) {
				templateData.value.instruction = instructionText;
			} else {
				templateData.value.instruction = instructionText;
			}
			templateData.value.type = "Instruction";

			context.emit("previewData", [
				previewData.value,
				templateData.value,
			]);
		};

		const signRequired = (isSignRequired) => {
			templateData.value.signRequired = isSignRequired;
			templateData.value.type = "Sign";
			context.emit("previewData", [
				previewData.value,
				templateData.value,
			]);
		};

		const dateTimeClick = () => {
			seletedPageData.value = undefined;
			buildertype.value = BuilerType.DATETIME;
		};

		const imageClick = () => {
			seletedPageData.value = undefined;
			buildertype.value = BuilerType.IMAGE;
		};

		const locationClick = () => {
			seletedPageData.value = undefined;
			buildertype.value = BuilerType.LOCATION;
		};

		const signClick = () => {
			seletedPageData.value = undefined;
			buildertype.value = BuilerType.SIGNATURE;
		};

		onMounted((async) => {
			for (const val of props.pageData) {
				previewData.value.push(val);
			}
			templateData.value = props.templateData;
		});

		const linkUserClick = () => {
			buildertype.value = BuilerType.LINKUSER;
		};

		const linkLocationClick = () => {
			buildertype.value = BuilerType.LINKLOCATION;
		};

		const linkEquipmentClick = () => {
			seletedPageData.value = undefined;
			buildertype.value = BuilerType.LINKEQUIPMENT;
		};

		const linkPeopleClick = () => {
			seletedPageData.value = undefined;
			buildertype.value = BuilerType.PEOPLE;
		};

		return {
			newPageClick,
			newSectionClick,
			newCheckboxClick,
			multipleCheckboxClick,
			textClick,
			instructionClick,
			addSection,
			addInstruction,
			addChoices,
			addQuestion,
			addPage,
			buildertype,
			previewData,
			templateData,
			dateTimeClick,
			imageClick,
			locationClick,
			signClick,
			signRequired,
			showOptions,
			handleShowOptions,
			navigateToComponent,
			seletedPageData,
			linkLocationClick,
			linkUserClick,
			linkEquipmentClick,
			linkPeopleClick,
		};
	},
};
</script>

<style lang="scss" scoped>
.back-btn {
	margin-left: 86px;
	font-size: 0.9em;
}
.nav-icon {
	margin-bottom: 5px;
}
.grid-container {
	display: grid;
	// align-items: center;
	justify-content: flex-start;
	//grid-template-columns: repeat(5, 103px);
	column-gap: 30px;
	row-gap: 3em;
	width: 100%;
	grid-template-columns: repeat(3, auto);
	-moz-column-gap: 30px;
	margin-top: 1em;

	@include respond(medium) {
		grid-template-columns: repeat(4, auto);
	}

	@include respond(larger) {
		grid-template-columns: repeat(5, 103px);
		margin-right: 3em;
	}
}
.grid-item {
	padding: 10px;
	font-size: 24px;
	text-align: center;

	flex: 1 1 80px;

	@include respond(small) {
		flex: 1 1 125px;
	}

	@include respond(medium) {
		flex: 1 1 110px;
	}
	@include respond(large) {
		flex: 1 1 150px;
	}
	.tooltip {
		visibility: hidden;
		background-color: $rm-dark-blue;
		color: #fff;
		text-align: center;
		border: 2px solid white;
		border-radius: 13px;
		padding: 5px 13px;
		margin: -5px 10px 0px 10px;
		font-size: 13px;
		font-weight: 100;

		/* Position the tooltip */
		position: absolute;
		z-index: 1;
	}
}
.grid-item:hover {
	color: $rm-pink;
	font-weight: bold;
	cursor: pointer;
	.tooltip {
		visibility: visible;
	}
}
.p-size {
	font-size: 0.6em;
}
</style>
