<template>
	<div class="row">
		<div class="spacer-20"></div>
		<div class="template-container">
			<form class="t-form" @submit.prevent>
				<div class="t-input">
					<textarea
						:rows="expand"
						maxlength="60"
						class="t-title"
						placeholder="Template Name..."
						v-model="templateName"
						@keyup="addName"
					></textarea>
				</div>

				<div class="t-input">
					<textarea
						name="t-description"
						placeholder="Template description..."
						maxlength="255"
						rows="5"
						v-model="templateDescription"
						@keyup="addDesc"
					></textarea>
				</div>

				<div class="t-input">
					<input
						type="text"
						class="addTags"
						placeholder="Type and press enter to add search tags..."
						v-model="searchTag"
						@keyup.enter="addSearchTag"
					/>

					<div class="row tags-container" v-if="tags && tags.length">
						<div
							v-for="searchTag in tags"
							:key="searchTag"
							class="pill bg-blue"
						>
							<fa class="fa-tag" icon="tag" />
							{{ searchTag }}
							<fa
								class="fa-times"
								icon="times"
								@click="deleteSearchTag(searchTag)"
							/>
						</div>
					</div>
				</div>
				<!-- <div class="spacer-40"></div> -->
				<div class="t-input template-id" v-if="props.id">
					<label for>TEMPLATE ID:</label>
					<input type="text" disabled :value="props.id" />
				</div>
				<div class="admin" v-if="canAddToLibrary">
					<p>ADDED TO LIBRARY:</p>
					<input
						type="checkbox"
						v-model="addedToLibrary"
						@change="addToLibrary"
					/>
				</div>
			</form>
		</div>
		<div class="success-message" v-if="success">
			<p v-if="addedToLibrary">Template added to library!</p>
			<p v-else>Template removed from library!</p>
		</div>
	</div>
</template>

<script>
import { ref, computed } from "@vue/reactivity";
import { onMounted, onUnmounted, watchEffect } from "@vue/runtime-core";
import getUser from "../../../composables/getUser";
import { projectFirestore } from "../../../firebase/config";
import useDocument from "../../../composables/useDocument";

export default {
	name: "Template",
	props: ["templateData", "id"],
	emits: ["previewData"],
	setup(props, context) {
		const { user } = getUser();

		// ref to hold the template data from props
		const templateData = ref({});

		// Form refs
		const templateName = ref("");
		const templateDescription = ref("");
		const searchTag = ref("");
		const addedToLibrary = ref(false);

		const templateId = ref("");
		const tags = ref([]);

		const expand = ref(1);
		const success = ref(false);

		// Expands the textarea if the template name is long
		watchEffect(() => {
			if (templateName.value && templateName.value.length > 28) {
				expand.value = 2;
			} else {
				expand.value = 1;
			}
		});

		// List of users who can add templates to the library
		const libraryAdmins = [
			"admin@riskmemo.com",
			"alistair@riskmemo.com",
			"admin@capstone-inspections.com",
			"paul@capstone-inspections.com",
			"riskmemo.acc+capstone@gmail.com",
		];

		const canAddToLibrary = computed(() => {
			return libraryAdmins.includes(user.value.email);
		});

		// Adds the template to the library
		const addToLibrary = async () => {
			const templateRef = projectFirestore
				.collection("Template")
				.doc(templateId.value);
			const { updateDoc, error: updateErr } = useDocument(templateRef);

			const updates = {
				addedToLibrary: addedToLibrary.value,
			};

			await updateDoc(updates);

			if (updateErr.value) {
				console.log(updateErr.value);
			} else {
				success.value = true;
				setTimeout(() => {
					success.value = false;
				}, 3000);
			}
		};

		const addSearchTag = () => {
			if (searchTag.value) {
				let tag = searchTag.value.trim();

				if (!tags.value.includes(tag)) {
					tags.value.push(tag);
				}
			}
			searchTag.value = "";
			saveData();
		};

		const deleteSearchTag = (searchTag) => {
			tags.value = tags.value.filter((item) => {
				return searchTag !== item;
			});

			saveData();
		};

		const addName = (e) => {
			saveData();
		};

		const addDesc = (e) => {
			saveData();
		};

		// Saves the template data to the templateData ref
		// which is then emitted to the parent component
		const saveData = () => {
			templateData.value.type = "Template";
			templateData.value.name = templateName.value;
			templateData.value.description = templateDescription.value;
			templateData.value.tags = tags.value;
			context.emit("previewData", [null, templateData.value]);
		};

		// Sets the template data from the props
		onMounted(() => {
			if (props.templateData != undefined) {
				templateData.value = props.templateData;
				templateDescription.value = props.templateData.description;
				templateName.value = props.templateData.name;
				templateId.value = props.id;
				addedToLibrary.value = props.templateData.addedToLibrary;

				if (props.templateData.tags != undefined) {
					tags.value = props.templateData.tags;
				}
			}
		});

		// Saves the template data when the component is unmounted
		onUnmounted(() => {
			saveData();
		});

		return {
			user,
			templateName,
			templateDescription,
			tags,
			searchTag,
			addName,
			addDesc,
			addSearchTag,
			deleteSearchTag,
			expand,
			props,
			addedToLibrary,
			addToLibrary,
			success,
			canAddToLibrary,
		};
	},
};
</script>

<style lang="scss" scoped>
.admin {
	display: flex;
	gap: 1em;
	color: $rm-grey;
	margin-top: 5px;
	font-size: 0.9em;
}
.template-container {
	margin-top: 1.3em;
}
.tags-container {
	display: flex;
	gap: 0.5em;
	flex-wrap: wrap;
	margin-bottom: 2em;
	max-height: 95px;
	padding-bottom: 0.5em;
	overflow: auto;
}
.pill {
	margin-left: 0;
	width: fit-content;
}
.template-id {
	label {
		margin-right: 0.5em;
	}
	input {
		color: $rm-grey;
		cursor: text;
	}
	margin: 1em 0;
	color: $rm-grey;
	font-size: 0.9em;
}
.t-form {
	@include respond(small) {
		padding: 2em 2em;
	}
	@include respond(medium) {
		padding: 2em 0;
	}
	@include respond(large) {
		padding: 2em 1em;
	}
	@include respond(larger) {
		padding: 2em 1em;
	}
}
</style>
